import { Component } from "react";
import { NavLink } from "react-router-dom";
import { AuthHelpers } from "../../BackendHelpers/auth";
import { FirebaseProvider } from "../../BackendHelpers/firebaseProvider";
import Icons from "../../Icons/svg-icon";
import { AreYouSure } from "../../stores/areYouSureStore";
import { feedback } from "../../stores/feedbackStore";
import Informations from "../Informations";
import "./Navbar.css";

interface State {
  AuctionMenu: boolean;
  InstitutionalMenu: boolean;
  ResponsiveMenu: boolean;
  isAuthenticated: boolean;
  userOptionsMenu: boolean;
}

export default class Navbar extends Component<object, State> {
  constructor(props: State) {
    super(props);
    this.state = {
      AuctionMenu: false,
      InstitutionalMenu: false,
      ResponsiveMenu: false,
      isAuthenticated: false,
      userOptionsMenu: false,
    };
  }

  componentDidMount(): void {
    FirebaseProvider.auth.onAuthStateChanged((user) => {
      this.setState({
        isAuthenticated: user != null,
      });
    });
  }

  resetOverridded = false;
  componentDidUpdate() {
    if (!this.resetOverridded) {
      this.setState({
        AuctionMenu: false,
        InstitutionalMenu: false,
        userOptionsMenu: false,
      });

      this.resetOverridded = true;

      return;
    }

    this.resetOverridded = false;
  }

  logOut() {
    AreYouSure.open(
      "Hesabınızdan çıkış yapmak istiyor musunuz?",
      () => {
        AuthHelpers.logout()
          .then(() => {
            feedback.push("Çıkış başarılı.", "#38B000", 3);
          })
          .catch((e) => {
            console.error(e);
            feedback.push(
              "Çıkış esnasında bir problem ile karşılaşıldı.",
              "#D02224",
              3,
            );
          });
      },
      null,
    );
  }

  openMenuButtons() {
    this.setState({
      userOptionsMenu: !this.state.userOptionsMenu,
      InstitutionalMenu: this.state.userOptionsMenu
        ? this.state.InstitutionalMenu
        : false,
      AuctionMenu: this.state.userOptionsMenu ? this.state.AuctionMenu : false,
    });

    this.resetOverridded = true;
  }

  getAuthButtons() {
    if (FirebaseProvider.auth.currentUser == null) {
      return (
        <div className="MenuButtons">
          <NavLink className={"NavlinkButton"} id="register" to="/kayit-ol">
            Kayıt Ol
          </NavLink>
          <NavLink className={"NavlinkButton"} id="login" to="/giris-yap">
            Giriş Yap
          </NavLink>
        </div>
      );
    } else {
      return (
        <div className="MenuButtons">
          <button
            className="MenuButtons"
            style={{ gap: "8px" }}
            onClick={() => {
              this.openMenuButtons();
            }}
          >
            <div className="user">
              <Icons.User fill="#ffffff" size={28}></Icons.User>
            </div>
            {FirebaseProvider.auth.currentUser?.displayName ??
              "Kayıt Tamamlanmamış"}
            <Icons.Expand
              className="Icon"
              size={20}
              fill="#17161A"
              style={{
                transform: this.state.userOptionsMenu
                  ? "rotate(180deg)"
                  : "rotate(0deg)",
              }}
            />
          </button>
          <div
            className="Menu"
            style={{
              opacity: this.state.userOptionsMenu ? 1 : 0,
              top: this.state.userOptionsMenu ? 100 : 80,
              pointerEvents: this.state.userOptionsMenu ? "all" : "none",
            }}
          >
            <NavLink
              to="/hesabim/teklif-verilen-acik-arttirmalarim"
              style={{
                gap: "3px",
              }}
            >
              <Icons.Report
                size={20}
                fill={
                  window.location.href.includes(
                    "teklif-verilen-acik-arttirmalarim",
                  )
                    ? "#D02224"
                    : "#17161A"
                }
              ></Icons.Report>
              Teklif Verdiğim Açık Arttırmalar
            </NavLink>
            <hr />
            <NavLink
              to="guvercinlerim"
              style={{
                gap: "3px",
              }}
            >
              <Icons.TicketStar
                size={20}
                fill={
                  window.location.href.includes("guvercinlerim")
                    ? "#D02224"
                    : "#17161A"
                }
              ></Icons.TicketStar>
              Güvercinlerim
            </NavLink>
            <hr />
            <NavLink
              to="/hesabim/bilgilerim"
              style={{
                gap: "3px",
              }}
            >
              <Icons.User
                size={20}
                fill={
                  window.location.href.includes("bilgilerim")
                    ? "#D02224"
                    : "#17161A"
                }
              ></Icons.User>
              Kullanıcı Bilgilerim
            </NavLink>
            <hr />
            <button
              style={{
                gap: "3px",
                paddingLeft: "12.5px",
                paddingRight: "12.5px",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                listStyle: "none",
                textDecoration: "none",
                border: "none",
                outline: "none",
                background: "transparent",
                color: "#D02224",
                fontFamily: "Rubik-Medium",
              }}
              onClick={() => this.logOut()}
            >
              <Icons.LogOut size={20} fill="#D02224"></Icons.LogOut>
              Çıkış Yap
            </button>
          </div>
        </div>
      );
    }
  }

  getAuthButtonsResp() {
    if (FirebaseProvider.auth.currentUser != null) return null;
    return (
      <div className="ResponsiveMenuContentBottom">
        <NavLink className={"NavlinkButton"} id="register" to="/kayit-ol">
          Kayıt Ol
        </NavLink>
        <NavLink className={"NavlinkButton"} id="login" to="/giris-yap">
          Giriş Yap
        </NavLink>
      </div>
    );
  }

  openAuthList() {
    this.setState({
      userOptionsMenu: !this.state.userOptionsMenu,
      InstitutionalMenu: this.state.userOptionsMenu
        ? this.state.InstitutionalMenu
        : false,
      AuctionMenu: this.state.userOptionsMenu ? this.state.AuctionMenu : false,
    });

    this.resetOverridded = true;
  }

  getAuthListResp() {
    if (FirebaseProvider.auth.currentUser == null) return null;
    return (
      <div>
        <li>
          <a
            href="javascript:undefined;"
            onClick={() => {
              this.openAuthList();
            }}
          >
            <Icons.Expand
              className="Icon"
              size={18}
              fill="white"
              style={{
                transform: this.state.userOptionsMenu
                  ? "rotate(0deg)"
                  : "rotate(-90deg)",
              }}
            />
            {FirebaseProvider.auth.currentUser?.displayName ??
              "Kayıt tamamlanmamış."}
          </a>
        </li>
        <li
          style={{
            opacity: this.state.userOptionsMenu ? 1 : 0,
            height: this.state.userOptionsMenu ? "auto" : 0,
            transitionDuration: "1000ms",
            display: this.state.userOptionsMenu ? "block" : "none",
          }}
        >
          <NavLink
            to="/hesabim/teklif-verilen-acik-arttirmalarim"
            className="ResponsiveLowerMenuItems"
          >
            Hesabım
          </NavLink>
          <hr />
          <NavLink to="/guvercinlerim" className="ResponsiveLowerMenuItems">
            Güvercinlerim
          </NavLink>
          <hr />
          <NavLink
            to="/hesabim/bilgilerim"
            className="ResponsiveLowerMenuItems"
          >
            Bilgilerim
          </NavLink>
          <button
            style={{
              gap: "5px",
              marginTop: "15px",
              marginLeft: "35px",
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
              listStyle: "none",
              textDecoration: "none",
              border: "none",
              outline: "none",
              background: "transparent",
              color: "#D02224",
              fontFamily: "Rubik-Medium",
              fontSize: "18px",
            }}
            onClick={() => this.logOut()}
          >
            <Icons.LogOut size={20} fill="#D02224"></Icons.LogOut>
            Çıkış Yap
          </button>
        </li>
      </div>
    );
  }

  openAuctionMenu() {
    this.setState({
      AuctionMenu: !this.state.AuctionMenu,
      InstitutionalMenu: this.state.AuctionMenu
        ? this.state.InstitutionalMenu
        : false,
      userOptionsMenu: this.state.AuctionMenu
        ? this.state.userOptionsMenu
        : false,
    });

    this.resetOverridded = true;
  }

  openInstituteMenu() {
    this.setState({
      InstitutionalMenu: !this.state.InstitutionalMenu,
      AuctionMenu: this.state.InstitutionalMenu
        ? this.state.AuctionMenu
        : false,
      userOptionsMenu: this.state.InstitutionalMenu
        ? this.state.userOptionsMenu
        : false,
    });

    this.resetOverridded = true;
  }

  openResponsiveMenu() {
    this.setState({ ResponsiveMenu: !this.state.ResponsiveMenu });
  }

  openAuctionMenuExclusive() {
    this.setState({
      AuctionMenu: !this.state.AuctionMenu,
      InstitutionalMenu: false,
      userOptionsMenu: false,
    });

    this.resetOverridded = true;
  }

  openInstituteMenuExclusive() {
    this.setState({
      InstitutionalMenu: !this.state.InstitutionalMenu,
      AuctionMenu: false,
      userOptionsMenu: false,
    });

    this.resetOverridded = true;
  }

  closeResponsiveMenu() {
    this.setState({ ResponsiveMenu: false });

    this.resetOverridded = true;
  }

  render() {
    return (
      <nav className="Navbar">
        <div className="HeaderContent">
          <NavLink to="/">
            <img src={Informations.logo} alt="" className="Logo" />
          </NavLink>
          <div className="ResponsiveName">Derby10 Arena</div>
          <div className="MenuItems">
            <li>
              <NavLink to="/">Ana Sayfa</NavLink>
            </li>
            <li>
              <a
                href="javascript:undefined;"
                onClick={() => {
                  this.openAuctionMenu();
                }}
              >
                Açık Arttırmalar
                <Icons.Expand
                  className="Icon"
                  size={20}
                  fill="#17161A"
                  style={{
                    transform: this.state.AuctionMenu
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                  }}
                />
              </a>
              <div
                className="Menu"
                style={{
                  opacity: this.state.AuctionMenu ? 1 : 0,
                  top: this.state.AuctionMenu ? 100 : 80,
                  pointerEvents: this.state.AuctionMenu ? "all" : "none",
                }}
              >
                <NavLink to="/acik-arttirmalar">Açık Arttırmalar</NavLink>
                <hr />
                <NavLink to="/grup-ihaleler">Grup İhaleler</NavLink>
                <hr />
                <NavLink to="/arsiv">Arşiv</NavLink>
              </div>
            </li>
            <li>
              <NavLink to="/katilimcilar">Katılımcılar</NavLink>
            </li>
            <li>
              <NavLink to="/galeri">Galeri</NavLink>
            </li>
            <li>
              <NavLink to="/haberler">Haberler</NavLink>
            </li>
            <li>
              <a
                href="javascript:undefined;"
                onClick={() => {
                  this.openInstituteMenu();
                }}
              >
                Kurumsal
                <Icons.Expand
                  className="Icon"
                  size={20}
                  fill="#17161A"
                  style={{
                    transform: this.state.InstitutionalMenu
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                  }}
                />
              </a>
              <div
                className="Menu"
                style={{
                  opacity: this.state.InstitutionalMenu ? 1 : 0,
                  top: this.state.InstitutionalMenu ? 100 : 80,
                  pointerEvents: this.state.InstitutionalMenu ? "all" : "none",
                }}
              >
                <NavLink to="/kurumsal/hakkimizda">Hakkımızda</NavLink>
                <hr />
                <NavLink to="/kurumsal/kullanim-kosullari">
                  Kullanım Koşulları
                </NavLink>
                <hr />
                <NavLink to="/kurumsal/gizlilik-politikasi">
                  Gizlilik Politikası
                </NavLink>
              </div>
            </li>
            <li>
              <NavLink to="/iletisim">İletişim</NavLink>
            </li>
          </div>
          {this.getAuthButtons()}
          <div className="ResponsiveMenuItems" style={{ opacity: 2 }}>
            <Icons.MoreSquare
              className="Icon"
              id="more"
              size={48}
              fill="#17161A"
              onClick={() => this.openResponsiveMenu()}
            />
            <div
              className="ResponsiveMenu"
              style={{ right: this.state.ResponsiveMenu ? "0" : "-260px" }}
            >
              <div className="Decore" />
              <div
                className="ResponsiveMenuContentTop"
                style={{
                  height:
                    FirebaseProvider.auth.currentUser != null ? "100%" : "60%",
                }}
              >
                <li>
                  <NavLink to="/">Ana Sayfa</NavLink>
                </li>
                <li>
                  <a
                    href="javascript:undefined;"
                    onClick={() => {
                      this.openAuctionMenuExclusive();
                    }}
                  >
                    <Icons.Expand
                      className="Icon"
                      size={18}
                      fill="white"
                      style={{
                        transform: this.state.AuctionMenu
                          ? "rotate(0deg)"
                          : "rotate(-90deg)",
                      }}
                    />
                    Açık Arttırmalar
                  </a>
                </li>
                <li
                  style={{
                    opacity: this.state.AuctionMenu ? 1 : 0,
                    height: this.state.AuctionMenu ? "auto" : 0,
                    transitionDuration: "1000ms",
                    display: this.state.AuctionMenu ? "block" : "none",
                  }}
                >
                  <NavLink
                    to="/acik-arttirmalar"
                    className="ResponsiveLowerMenuItems"
                  >
                    Açık Arttırmalar
                  </NavLink>
                  <hr />
                  <NavLink
                    to="/grup-ihaleler"
                    className="ResponsiveLowerMenuItems"
                  >
                    Grup İhaleler
                  </NavLink>
                  <hr />
                  <NavLink to="/arsiv" className="ResponsiveLowerMenuItems">
                    Arşiv
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/katilimcilar">Katılımcılar</NavLink>
                </li>
                <li>
                  <NavLink to="/galeri">Galeri</NavLink>
                </li>
                <li>
                  <NavLink to="/haberler">Haberler</NavLink>
                </li>
                <li>
                  <a
                    href="javascript:undefined;"
                    onClick={() => {
                      this.openInstituteMenuExclusive();
                    }}
                  >
                    <Icons.Expand
                      className="Icon"
                      size={18}
                      fill="white"
                      style={{
                        transform: this.state.InstitutionalMenu
                          ? "rotate(0deg)"
                          : "rotate(-90deg)",
                      }}
                    />
                    Kurumsal
                  </a>
                </li>
                <li
                  style={{
                    opacity: this.state.InstitutionalMenu ? 1 : 0,
                    height: this.state.InstitutionalMenu ? "auto" : 0,
                    transitionDuration: "1000ms",
                    display: this.state.InstitutionalMenu ? "block" : "none",
                  }}
                >
                  <NavLink
                    to="/kurumsal/hakkimizda"
                    className="ResponsiveLowerMenuItems"
                  >
                    Hakkımızda
                  </NavLink>
                  <hr />
                  <NavLink
                    to="/kurumsal/kullanim-kosullari"
                    className="ResponsiveLowerMenuItems"
                  >
                    Kullanım Koşulları
                  </NavLink>
                  <hr />
                  <NavLink
                    to="/kurumsal/gizlilik-politikasi"
                    className="ResponsiveLowerMenuItems"
                  >
                    Gizlilik Politikası
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/iletisim">İletişim</NavLink>
                </li>
                {this.getAuthListResp()}
              </div>
              {this.getAuthButtonsResp()}
            </div>
            <div
              className="Quit"
              onClick={() => this.closeResponsiveMenu()}
              style={{ display: this.state.ResponsiveMenu ? "block" : "none" }}
            />
          </div>
        </div>
      </nav>
    );
  }
}
