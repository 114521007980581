import { ENDPOINT_BASE } from "../BackendHelpers/pagedResource";

const INFOS_ENDPOINT = `${ENDPOINT_BASE}/infos.php`;

export class Informations {
  // Mainpage/Generic
  static instagram_name = "";
  static youtube_name = "";
  static facebook_name = "";
  static telephone = "";
  static mail_name = "";
  static address_name = "";
  static logo = "";

  static instagram_url = "";
  static youtube_url = "";
  static facebook_url = "";
  static telephone_url = "";
  static mail_url = "";
  static address_url = "";
  static address_embed_url = "";
  static CentaurGames_url = "";
  static footer_description = "";
  static label_titles: { title: string; msg: string }[] = [];

  // auction containers
  static auction_vitrine_id = "";
  static auction_archive_id = "";

  static users_page_start_serial = "";

  // Slider
  static slider_pics: { full: string[]; mobile: string[] } = {
    full: [],
    mobile: [],
  };

  // Institutional
  static institute_image = "";
  static about_us_html_loc = "";
  static terms_html_loc = "";
  static privacy_policy_html_loc = "";
  static cookie_policy_html_loc = "";
  static sponsors: { alt: string; url: string }[] = [];

  static how_to_bid_step_one_title = "";
  static how_to_bid_step_two_title = "";
  static how_to_bid_step_three_title = "";
  static how_to_bid_step_one = "";
  static how_to_bid_step_two = "";
  static how_to_bid_step_three = "";

  static how_to_ask_step_one_title = "";
  static how_to_ask_step_two_title = "";
  static how_to_ask_step_three_title = "";
  static how_to_ask_step_one = "";
  static how_to_ask_step_two = "";
  static how_to_ask_step_three = "";

  static pullInfos() {
    return new Promise<void>((resolve, reject) => {
      fetch(INFOS_ENDPOINT, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      })
        .then((res) => {
          if (res.status !== 200) {
            reject(Error("infos not pulled."));
          }

          res
            .json()
            .then((json: unknown) => {
              if (typeof json !== "object" || json === null) {
                reject(Error("infos returned wrong"));
                return;
              }

              if (
                "instagram_name" in json &&
                typeof json.instagram_name === "string"
              ) {
                this.instagram_name = json.instagram_name;
              }

              if (
                "youtube_name" in json &&
                typeof json.youtube_name === "string"
              ) {
                this.youtube_name = json.youtube_name;
              }

              if (
                "facebook_name" in json &&
                typeof json.facebook_name === "string"
              ) {
                this.facebook_name = json.facebook_name;
              }

              if ("telephone" in json && typeof json.telephone === "string") {
                this.telephone = json.telephone;
              }
              if ("mail_name" in json && typeof json.mail_name === "string") {
                this.mail_name = json.mail_name;
              }
              if (
                "address_name" in json &&
                typeof json.address_name === "string"
              ) {
                this.address_name = json.address_name;
              }

              if (
                "instagram_url" in json &&
                typeof json.instagram_url === "string"
              ) {
                this.instagram_url = json.instagram_url;
              }
              if (
                "youtube_url" in json &&
                typeof json.youtube_url === "string"
              ) {
                this.youtube_url = json.youtube_url;
              }
              if (
                "facebook_url" in json &&
                typeof json.facebook_url === "string"
              ) {
                this.facebook_url = json.facebook_url;
              }
              if (
                "telephone_url" in json &&
                typeof json.telephone_url === "string"
              ) {
                this.telephone_url = json.telephone_url;
              }
              if ("mail_url" in json && typeof json.mail_url === "string") {
                this.mail_url = json.mail_url;
              }
              if (
                "address_url" in json &&
                typeof json.address_url === "string"
              ) {
                this.address_url = json.address_url;
              }
              if (
                "address_embed_url" in json &&
                typeof json.address_embed_url === "string"
              ) {
                this.address_embed_url = json.address_embed_url;
              }
              if (
                "CentaurGames_url" in json &&
                typeof json.CentaurGames_url === "string"
              ) {
                this.CentaurGames_url = json.CentaurGames_url;
              }

              if (
                "footer_description" in json &&
                typeof json.footer_description === "string"
              ) {
                this.footer_description = json.footer_description;
              }

              if (
                "label_titles" in json &&
                typeof json.label_titles === "string"
              ) {
                try {
                  this.label_titles = JSON.parse(json.label_titles);
                } catch {
                  // no op
                }
              }

              if (
                "auction_vitrine_id" in json &&
                typeof json.auction_vitrine_id === "string"
              ) {
                this.auction_vitrine_id = json.auction_vitrine_id;
              }

              if (
                "auction_archive_id" in json &&
                typeof json.auction_archive_id === "string"
              ) {
                this.auction_archive_id = json.auction_archive_id;
              }

              if (
                "users_page_start_serial" in json &&
                typeof json.users_page_start_serial === "string"
              ) {
                this.users_page_start_serial = json.users_page_start_serial;
              }

              if ("logo" in json && typeof json.logo === "string") {
                this.logo = json.logo;
              }

              if (
                "slider_pics" in json &&
                typeof json.slider_pics === "string"
              ) {
                try {
                  this.slider_pics = JSON.parse(json.slider_pics);
                } catch {
                  // no op
                }
              }

              if (
                "institute_image" in json &&
                typeof json.institute_image === "string"
              ) {
                this.institute_image = json.institute_image;
              }

              if (
                "about_us_html_loc" in json &&
                typeof json.about_us_html_loc === "string"
              ) {
                this.about_us_html_loc = json.about_us_html_loc;
              }
              if (
                "terms_html_loc" in json &&
                typeof json.terms_html_loc === "string"
              ) {
                this.terms_html_loc = json.terms_html_loc;
              }
              if (
                "privacy_policy_html_loc" in json &&
                typeof json.privacy_policy_html_loc === "string"
              ) {
                this.privacy_policy_html_loc = json.privacy_policy_html_loc;
              }
              if (
                "cookie_policy_html_loc" in json &&
                typeof json.cookie_policy_html_loc === "string"
              ) {
                this.cookie_policy_html_loc = json.cookie_policy_html_loc;
              }
              if ("sponsors" in json && typeof json.sponsors === "string") {
                try {
                  this.sponsors = JSON.parse(json.sponsors);
                } catch {
                  // no op
                }
              }

              if (
                "how_to_bid_step_one_title" in json &&
                typeof json.how_to_bid_step_one_title === "string"
              ) {
                this.how_to_bid_step_one_title = json.how_to_bid_step_one_title;
              }
              if (
                "how_to_bid_step_two_title" in json &&
                typeof json.how_to_bid_step_two_title === "string"
              ) {
                this.how_to_bid_step_two_title = json.how_to_bid_step_two_title;
              }
              if (
                "how_to_bid_step_three_title" in json &&
                typeof json.how_to_bid_step_three_title === "string"
              ) {
                this.how_to_bid_step_three_title =
                  json.how_to_bid_step_three_title;
              }
              if (
                "how_to_bid_step_one" in json &&
                typeof json.how_to_bid_step_one === "string"
              ) {
                this.how_to_bid_step_one = json.how_to_bid_step_one;
              }
              if (
                "how_to_bid_step_two" in json &&
                typeof json.how_to_bid_step_two === "string"
              ) {
                this.how_to_bid_step_two = json.how_to_bid_step_two;
              }
              if (
                "how_to_bid_step_three" in json &&
                typeof json.how_to_bid_step_three === "string"
              ) {
                this.how_to_bid_step_three = json.how_to_bid_step_three;
              }

              if (
                "how_to_ask_step_one_title" in json &&
                typeof json.how_to_ask_step_one_title === "string"
              ) {
                this.how_to_ask_step_one_title = json.how_to_ask_step_one_title;
              }
              if (
                "how_to_ask_step_two_title" in json &&
                typeof json.how_to_ask_step_two_title === "string"
              ) {
                this.how_to_ask_step_two_title = json.how_to_ask_step_two_title;
              }
              if (
                "how_to_ask_step_three_title" in json &&
                typeof json.how_to_ask_step_three_title === "string"
              ) {
                this.how_to_ask_step_three_title =
                  json.how_to_ask_step_three_title;
              }
              if (
                "how_to_ask_step_one" in json &&
                typeof json.how_to_ask_step_one === "string"
              ) {
                this.how_to_ask_step_one = json.how_to_ask_step_one;
              }
              if (
                "how_to_ask_step_two" in json &&
                typeof json.how_to_ask_step_two === "string"
              ) {
                this.how_to_ask_step_two = json.how_to_ask_step_two;
              }
              if (
                "how_to_ask_step_three" in json &&
                typeof json.how_to_ask_step_three === "string"
              ) {
                this.how_to_ask_step_three = json.how_to_ask_step_three;
              }

              resolve();
            })
            .catch(() => {
              reject(Error("infos returned wrong"));
            });
        })
        .catch(() => {
          reject(Error("infos pull failed"));
        });
    });
  }
}

export default Informations;
