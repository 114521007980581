import { Component } from "react";
import background from "../../Images/DataBaseImages/Background.png";
import "./TitlePhoto.css";

interface Props {
  title: string;
}

export default class TitlePhoto extends Component<Props, unknown> {
  render() {
    return (
      <div
        style={{
          backgroundImage: `url(${background})`,
        }}
        className="title-photo"
      >
        <span>{this.props.title}</span>
      </div>
    );
  }
}
