import {
  Unsubscribe,
  limitToLast,
  onValue,
  orderByKey,
  query,
  ref,
} from "firebase/database";
import { Component, createRef } from "react";
import { useParams } from "react-router-dom";
import { bid } from "../../BackendHelpers/auctionUtils";
import { FirebaseProvider } from "../../BackendHelpers/firebaseProvider";
import {
  ENDPOINT_BASE,
  _PagedResource,
} from "../../BackendHelpers/pagedResource";
import Auction, {
  dateConventerWithoutSeconds,
  dateConverter,
  getStatusColor,
  getStatusText,
} from "../../Components/Auction/Auction";
import { GalleryPhoto } from "../../Components/Photo/Photo";
import SliderLayout from "../../Components/Sliders/SliderLayout";
import Icons from "../../Icons/svg-icon";
import { loadingStore } from "../../stores/LoadingStore";
import { AreYouSure } from "../../stores/areYouSureStore";
import { feedback } from "../../stores/feedbackStore";
import "./AuctionPage.css";
import { AuctionData } from "./AuctionsLayout";

interface Bid {
  numericId: string;
  on: number;
  amount: number;
}

interface State {
  title: string;
  currentOffer: number;
  myLastBidId: string;
  expiry: number;
  description: string;
  bids: { [key: string]: Bid } | null;
  photos: { [key: string]: string } | null;
}

interface Props {
  id: string;
}

export default function AuctionPage() {
  const { id } = useParams();
  return id ? <_AuctionPage id={id} key={id}></_AuctionPage> : null;
}

class _AuctionPage extends Component<Props, State> {
  listenerCancellers: Unsubscribe[] = [];
  interval: NodeJS.Timer | null = null;
  otherAuctionsMngr: _PagedResource<AuctionData>;
  otherAuctions: AuctionData[] = [];

  constructor(props: Props) {
    super(props);
    this.state = {
      bids: {},
      currentOffer: 0,
      myLastBidId: "-",
      expiry: 0,
      photos: {},
      description: "",
      title: "",
    };

    this.otherAuctionsMngr = new _PagedResource<AuctionData>(
      `${ENDPOINT_BASE}/auctionQuery.php`,
      4,
    );

    this.otherAuctionsMngr
      .loadNextPage({
        0: { op: "where" },
        yx0parent: { op: "qparam", v: "`auctions`.`id`" },
        yx0parent1: { op: "ne" },
        yx0parent2: { op: "qmark", t: "s", v: props.id },
        yx0parent3: { op: "and" },
        status: { op: "qparam", v: "`auctions`.`status`" },
        status1: { op: "e" },
        status3: { op: "qmark", t: "i", v: 2 },
        yz1: { op: "orderby" },
        yz2: { op: "qparam", v: "`auctions`.`endingTime`" },
        yz3: { op: "orderbyasc" },
      })
      .then((res) => {
        this.otherAuctions = res;
      });
  }

  componentDidMount(): void {
    this.load();
    if (this.interval) clearInterval(this.interval);
    this.interval = setInterval(() => {
      this.setState({});
    }, 1000);
  }

  componentWillUnmount(): void {
    if (this.interval) clearInterval(this.interval);
    for (const x of Object.values(this.listenerCancellers)) {
      x();
    }
  }

  load() {
    this.listenerCancellers.push(
      onValue(
        ref(
          FirebaseProvider.db,
          `auctions/${this.props.id}/concurrentParams/currentBid/amount`,
        ),
        (snap) => {
          if (snap.val() == null) {
            this.listenerCancellers.push(
              onValue(
                ref(
                  FirebaseProvider.db,
                  `auctions/${this.props.id}/startingBid`,
                ),
                (snap2) => {
                  this.setState({
                    currentOffer: snap2.val(),
                  });
                },
              ),
            );
            return;
          }
          this.setState({
            currentOffer: snap.val(),
          });
        },
      ),
      onValue(
        ref(
          FirebaseProvider.db,
          `auctions/${this.props.id}/concurrentParams/expiry`,
        ),
        (snap) =>
          this.setState({
            expiry: snap.val(),
          }),
      ),
      onValue(
        ref(FirebaseProvider.db, `auctions/${this.props.id}/description`),
        (snap) =>
          this.setState({
            description: snap.val(),
          }),
      ),
      FirebaseProvider.auth.onAuthStateChanged((user) => {
        this.listenerCancellers.push(
          onValue(
            ref(
              FirebaseProvider.db,
              `users/${FirebaseProvider.auth.currentUser?.uid}/biddedAuctions/${this.props.id}/bidId`,
            ),
            (snap) =>
              this.setState({
                myLastBidId: snap.val(),
              }),
          ),
        );
      }),
      onValue(
        ref(FirebaseProvider.db, `auctions/${this.props.id}/name`),
        (snap) =>
          this.setState({
            title: snap.val(),
          }),
      ),
      onValue(
        query(
          ref(FirebaseProvider.db, `auctions/${this.props.id}/bidsSummary`),
          orderByKey(),
          limitToLast(10),
        ),
        (snap) =>
          this.setState({
            bids: snap.val(),
          }),
      ),
      onValue(
        ref(FirebaseProvider.db, `auctions/${this.props.id}/pigeon`),
        (snap) => {
          this.listenerCancellers.push(
            onValue(
              ref(FirebaseProvider.db, `pigeons/${snap.val()}/pictures`),
              (snap2) => {
                this.setState({
                  photos: snap2.val(),
                });
              },
            ),
          );
        },
      ),
    );
  }

  getBid(key: string, numericId: string, time: number, amount: number) {
    return (
      <div
        className="bid"
        key={key}
        style={{
          border: key == this.state.myLastBidId ? "solid #60D394" : "none",
        }}
      >
        <div className="user">
          <Icons.User fill="#ffffff" size={28}></Icons.User>
        </div>
        <div>
          <span className="info-title" style={{ fontSize: "14px" }}>
            {numericId}
          </span>
          <span className="description-text" style={{ fontSize: "14px" }}>
            {dateConventerWithoutSeconds(time)} önce {amount}tl teklifte
            bulundu.
          </span>
        </div>
      </div>
    );
  }

  noOfferFound(isActive: boolean) {
    if (!isActive) return null;

    const content =
      this.state.expiry > Date.now()
        ? "Hiç teklif yok. İlk teklifi sen ver"
        : "Hiç teklif yok.";

    return (
      <span
        className="info-title"
        style={{ fontSize: "18px", marginTop: "20px" }}
      >
        {content}
      </span>
    );
  }

  getlowerLimit(lastAmount: number) {
    if (lastAmount < 1000) return lastAmount + 50;
    else if (lastAmount < 2000) return lastAmount + 100;
    else if (lastAmount < 3000) return lastAmount + 200;
    else if (lastAmount < 4000) return lastAmount + 300;
    else if (lastAmount < 5000) return lastAmount + 400;
    else if (lastAmount < 10000) return lastAmount + 500;
    else return lastAmount + 1000;
  }

  getBidState(): string {
    if (Object.keys(this.state.bids ?? {}).length < 1) {
      return "Hiç Teklif Verilmedi";
    }

    return this.state.myLastBidId ==
      Object.keys(this.state.bids ?? {})[
        Object.keys(this.state.bids ?? {}).length - 1
      ]
      ? "Son teklifi sen verdin!"
      : "Son teklif başkasına ait";
  }

  bidAmountRef = createRef<HTMLInputElement>();

  render() {
    const isMobile = screen.width < 950;
    return (
      <div>
        <div className="auction-page-background">
          <div className="photos">
            <div
              className="photo-content"
              style={{
                width: isMobile
                  ? (
                      Object.keys(this.state.photos ?? {}).length * 60
                    ).toString() + "%"
                  : "auto",
              }}
            >
              {Object.keys(this.state.photos ?? {}).map((val) => {
                if (this.state.photos == null) return null;
                return (
                  <GalleryPhoto
                    src={this.state.photos[val]}
                    key={this.state.photos[val]}
                    className="photo"
                  />
                );
              })}
            </div>
          </div>
          <div className="informations">
            <div className="status">
              <div
                className="status-balloon"
                style={{
                  backgroundColor: getStatusColor(
                    this.state.expiry - new Date().getTime(),
                  ),
                }}
              />{" "}
              {getStatusText(this.state.expiry - new Date().getTime())}
            </div>
            <span className="title">{this.state.title}</span>
            <br />
            <span
              style={{
                fontFamily: "Rubik-Regular",
                color: "#6A696C",
                whiteSpace: "pre-wrap",
              }}
            >
              {this.state.description}
            </span>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "3%",
                marginTop: "25px",
              }}
            >
              <div className="info-box">
                <span className="info-title">Güncel Teklif</span>
                <span className="description-text">
                  {this.state.currentOffer} TL
                </span>
              </div>
              <div className="info-box">
                <span className="info-title">Bitmesine Kalan</span>
                <span className="description-text">
                  {dateConverter(this.state.expiry)}
                </span>
              </div>
            </div>
            <span
              className="sub-title"
              style={{
                display: this.state.expiry < Date.now() ? "block" : "none",
                color:
                  this.state.myLastBidId &&
                  this.state.myLastBidId ==
                    Object.keys(this.state.bids ?? {})[
                      Object.keys(this.state.bids ?? {}).length - 1
                    ]
                    ? "#60D394"
                    : "#EE6055",
                marginTop: "55px",
                fontSize: "24px",
                fontFamily: "Rubik-Medium",
              }}
            >
              {this.getBidState()}
            </span>
            <span
              className="sub-title"
              style={{
                display: this.state.expiry > Date.now() ? "block" : "none",
              }}
            >
              Teklif Ver
            </span>
            <div
              id="offer-input"
              style={{
                display: this.state.expiry > Date.now() ? "block" : "none",
              }}
            >
              <Icons.Wallet
                className="icon"
                fill="#bababa"
                size={24}
              ></Icons.Wallet>
              <input
                ref={this.bidAmountRef}
                type="number"
                placeholder={this.getlowerLimit(
                  this.state.currentOffer,
                ).toString()}
              />{" "}
              <button
                onClick={() => {
                  const parsedInt = parseInt(
                    this.bidAmountRef.current?.value ?? "",
                  );
                  if (isNaN(parsedInt)) {
                    feedback.push(
                      "Teklif verme alanı boş bırakılamaz",
                      "#EE6055",
                      1,
                    );
                    return;
                  }

                  if (FirebaseProvider.auth.currentUser == null) {
                    feedback.push(
                      "Teklif vermek için öncelikle giriş yapmalısınız",
                      "#EE6055",
                      3,
                    );
                    return;
                  }

                  if (parsedInt < this.getlowerLimit(this.state.currentOffer)) {
                    feedback.push("Verdiğiniz teklif çok düşük", "#EE6055", 2);
                    return;
                  }

                  AreYouSure.open(
                    `${parsedInt}TL Değerindeki Teklifinizi Onaylıyor musunuz?`,
                    async () => {
                      loadingStore.dispatch({ type: "open" });
                      try {
                        await bid(this.props.id, parsedInt);
                        feedback.push("Teklif başarıyla verildi", "#60D394", 1);
                        if (this.bidAmountRef.current) {
                          this.bidAmountRef.current.value = "";
                        }
                      } catch (e) {
                        console.log(e);
                        feedback.push("Teklif reddedildi", "#EE6055", 1);
                      } finally {
                        loadingStore.dispatch({ type: "close" });
                      }
                    },
                    null,
                  );
                }}
              >
                Teklif Ver
              </button>
            </div>
            <span
              className="sub-title"
              style={{
                marginTop: "55px",
              }}
            >
              Geçmiş Teklifler
            </span>
            <hr />
            {Object.keys(this.state.bids ?? {})
              .reverse()
              .map((val) => {
                if (this.state.bids == null) {
                  return null;
                }
                const bid = this.state.bids[val];
                const time = new Date().getTime();
                return this.getBid(
                  val,
                  bid.numericId,
                  time + (time - bid.on),
                  bid.amount,
                );
              })}
            {this.noOfferFound(
              this.state.bids != null
                ? Object.keys(this.state.bids).length == 0
                : true,
            )}
          </div>
        </div>
        <hr />
        <SliderLayout
          to="/acik-arttirmalar"
          bottomButton="Tüm Açık Arttırmalar"
          keepButton="Hepsini Gör"
          name="Diğer Devam Eden Açık Arttırmalar"
          items={this.otherAuctions.map((v) => (
            <Auction
              key={(v as AuctionData)["`auctions`.`id`"]}
              auctionId={(v as AuctionData)["`auctions`.`id`"]}
              name={(v as AuctionData)["`auctions`.`name`"]}
              ownerName={(v as AuctionData)["`users`.`fullname`"]}
            />
          ))}
        />
      </div>
    );
  }
}
