import Icons from "../../../Icons/svg-icon";
import "./Error500.css";

export default function Error500(props: React.PropsWithChildren<unknown>) {
  return (
    <div className="Error500Page" id="page">
      <Icons.Error500Icon fill={"#17161A"} className="Icon" />
      <li>Bir Şeyler Yanlış Gitti</li>
      <li className="Small">Lütfen daha sonra tekrar deneyiniz.</li>
      <div
        className="Small"
        style={{
          paddingTop: "100px",
          wordBreak: "break-all",
          fontFamily: "Consolas",
          fontSize: "12px",
          color: "dimgray",
        }}
      >
        Hata: {props.children}
      </div>
      {/* <NavLink className={"MainMenu"} to={"/"}>
        Ana Sayfa
      </NavLink> */}
    </div>
  );
}
