import { configureStore } from "@reduxjs/toolkit";

export const loadingStore = configureStore<{ isOpen: boolean }>({
  reducer: (state, action) => {
    if (action.type == "open") {
      return { isOpen: true };
    } else {
      return { isOpen: false };
    }
  },
});
