import { NavLink } from "react-router-dom";
import Icons from "../../Icons/svg-icon";
import { Informations } from "../Informations";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="Footer-BackGround">
      <div className="Footer-Top">
        <div className="Institutional">
          <img src={Informations.logo} alt="" className="Footer-Logo" />
          <div>{Informations.footer_description}</div>
        </div>
        <hr className="Hr" />
        <div className="QuickLinks">
          <li className="Title">Hızlı Linkler</li>
          <hr />
          <li>
            <NavLink to="/">Ana Sayfa</NavLink>
          </li>
          <li>
            <NavLink to="acik-arttirmalar">Açık Arttırmalar</NavLink>
          </li>
          <li>
            <NavLink to="katilimcilar">Katılımcılar</NavLink>
          </li>
          <li>
            <NavLink to="galeri">Galeri</NavLink>
          </li>
          <li>
            <NavLink to="haberler">Haberler</NavLink>
          </li>
          <li>
            <NavLink to="kurumsal">Kurumsal</NavLink>
          </li>
          <li>
            <NavLink to="iletisim">İletişim</NavLink>
          </li>
        </div>
        <hr className="Hr" />
        <div className="Communication">
          <li className="Title">İletişim</li>
          <hr />
          <li>
            <a target="_blank" rel="noreferrer" href={Informations.mail_url}>
              <div>
                <Icons.Mail className="Icon" size={20} fill="white" />
              </div>
              <span>{Informations.mail_name}</span>
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href={Informations.telephone_url}
            >
              <div>
                <Icons.Calling className="Icon" size={20} fill="white" />
              </div>
              <span>{Informations.telephone}</span>
            </a>
          </li>
          <li>
            <a target="_blank" rel="noreferrer" href={Informations.address_url}>
              <div>
                <Icons.Location className="Icon" size={20} fill="white" />
              </div>
              <span style={{ marginRight: "10%" }}>
                {Informations.address_name}
              </span>
            </a>
          </li>
        </div>
        <hr className="Hr" />
        <div className="SocialMedia">
          <li className="Title">Sosyal Medya</li>
          <hr />
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href={Informations.facebook_url}
            >
              <div>
                <Icons.Facebook className="Icon" size={20} fill="white" />
              </div>
              <span>{Informations.facebook_name}</span>
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href={Informations.instagram_url}
            >
              <div>
                <Icons.Instagram className="Icon" size={20} fill="white" />
              </div>
              <span>{Informations.instagram_name}</span>
            </a>
          </li>
          <li>
            <a target="_blank" rel="noreferrer" href={Informations.youtube_url}>
              <div>
                <Icons.Youtube className="Icon" size={20} fill="white" />
              </div>
              <span>{Informations.youtube_name}</span>
            </a>
          </li>
        </div>
      </div>
      <div className="Footer-Bottom">
        <hr />
        <div className="MadeBy">
          <div>
            <li>
              Copyright 2023 <a href="/">Derby10</a> | Made by{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href={Informations.CentaurGames_url}
              >
                Centaur Interactive
              </a>
            </li>
          </div>
          <div>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="/kurumsal/kullanim-kosullari"
              >
                Kullanım Koşulları
              </a>{" "}
              |{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="/kurumsal/gizlilik-politikasi"
              >
                Gizlilik Politikası
              </a>
            </li>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
