import Icons from "../../../Icons/svg-icon";
import { NavLink } from "react-router-dom";
import "./Error404.css";

export default function Error404() {
  return (
    <div className="Error404Page" id="page">
      <Icons.Error404Icon fill={"#17161A"} className="Icon" />
      <li>Sayfa Bulunamadı</li>
      <NavLink className={"MainMenu-button"} to={"/"}>
        Ana Sayfa
      </NavLink>
    </div>
  );
}
