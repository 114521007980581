import { Buffer } from "buffer";
import { httpsCallable } from "firebase/functions";
import { FirebaseProvider } from "./firebaseProvider";
import { KeyHelper } from "./key";
import { MFAProvider } from "./mfa";

export interface BidRequest {
  nonce: string;
  amount: number;
  createdOn: number;
  device: string;
  user: string;
  for: string;
}

export async function bid(auctionId: string, amount: number) {
  const arr = Buffer.alloc(32);
  window.crypto.getRandomValues(arr);

  const bidReq: BidRequest = {
    nonce: arr.toString("base64"),
    amount,
    createdOn: new Date().getTime(),
    device: FirebaseProvider.deviceId ?? "",
    for: auctionId,
    user: FirebaseProvider.auth.currentUser?.uid ?? "",
  };

  await MFAProvider.ensureMFA();

  const sigBuf = Buffer.from(
    await window.crypto.subtle.sign(
      {
        hash: "SHA-256",
        name: "ECDSA",
        saltLength: 32,
      },
      (
        await KeyHelper.getKey()
      ).privateKey,
      Buffer.from(JSON.stringify(bidReq)),
    ),
  );

  await httpsCallable(
    FirebaseProvider.functions,
    "bid",
  )({
    BidRequest: bidReq,
    signature: sigBuf.toString("base64"),
    mfaToken: MFAProvider.mfaToken ?? "",
  });
}
