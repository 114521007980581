/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FirebaseError } from "@firebase/util";
import { Component, createRef } from "react";
import { AuthHelpers } from "../../BackendHelpers/auth";
import { MFAError } from "../../BackendHelpers/mfa";
import Icons from "../../Icons/svg-icon";
import "../../Pages/AuthPages/AuthPages.css";
import { feedback } from "../../stores/feedbackStore";
import { loadingStore } from "../../stores/LoadingStore";
import { passwordMatcher } from "../AuthPages/RegisterPages/RegisterPage";

export default class RefreshPasswordPage extends Component {
  data: {
    oldPassword?: string;
    newPassword?: string;
    newPasswordAgain?: string;
  };

  constructor(props: object) {
    super(props);
    this.data = {};
  }

  resetPasswordInputs() {
    this.oldPasswordInput.current!.style.boxShadow = "inherit";
    this.oldPasswordInfo.current!.style.color = "inherit";
    this.oldPasswordInfo.current!.innerHTML = "";
    this.newPasswordInput.current!.style.boxShadow = "inherit";
    this.newPasswordInfo.current!.style.color = "inherit";
    this.newPasswordInfo.current!.innerHTML = "";
    this.newPasswordAgainInput.current!.style.boxShadow = "inherit";
    this.newPasswordAgainInfo.current!.style.color = "inherit";
    this.newPasswordAgainInfo.current!.innerHTML = "";
  }

  doInputCheck() {
    if (!passwordMatcher.test(this.data.oldPassword ?? "")) {
      this.oldPasswordInput.current!.style.boxShadow =
        "0px 0px 5px rgb(255 0 0)";
      this.oldPasswordInfo.current!.style.color = "red";
      this.oldPasswordInfo.current!.innerHTML = "Şifre geçersiz";
      throw new Error("eski sifre gecersiz");
    }

    if (!passwordMatcher.test(this.data.newPassword ?? "")) {
      this.newPasswordInput.current!.style.boxShadow =
        "0px 0px 5px rgb(255 0 0)";
      this.newPasswordInfo.current!.style.color = "red";
      this.newPasswordInfo.current!.innerHTML = "Şifre Geçersiz.";
      throw new Error("yeni sifre gecersiz");
    }

    if (this.data.newPassword !== this.data.newPasswordAgain) {
      this.newPasswordAgainInput.current!.style.boxShadow =
        "0px 0px 5px rgb(255 0 0)";
      this.newPasswordAgainInfo.current!.style.color = "red";
      this.newPasswordAgainInfo.current!.innerHTML = "Şifreler uyuşmuyor.";
      throw new Error("sifreler uyusmuyor");
    }
  }

  doPasswordReset() {
    this.resetPasswordInputs();
    this.doInputCheck();

    loadingStore.dispatch({ type: "open" });
    AuthHelpers.changePassword(this.data.oldPassword!, this.data.newPassword!)
      .then(() => {
        loadingStore.dispatch({ type: "close" });
        feedback.push("Şifreniz değiştirildi.", "green", 3);
      })
      .catch((e) => {
        loadingStore.dispatch({ type: "close" });
        if (e instanceof MFAError) {
          feedback.push("İki faktör doğrulama hatası.", "red", 3);
        } else if (e instanceof FirebaseError) {
          if (e.code === "auth/wrong-password") {
            this.oldPasswordInput.current!.style.boxShadow =
              "0px 0px 5px rgb(255 0 0)";
            this.oldPasswordInfo.current!.style.color = "red";
            this.oldPasswordInfo.current!.innerHTML = "Şifre yanlış.";
            feedback.push("Şifre Yanlış", "red", 3);
          } else {
            feedback.push("Bir hata oluştu", "red", 3);
          }
        } else {
          feedback.push("Bir hata oluştu", "red", 3);
        }
      });
  }

  oldPasswordInput = createRef<HTMLDivElement>();
  oldPasswordInfo = createRef<HTMLDivElement>();

  newPasswordInput = createRef<HTMLDivElement>();
  newPasswordInfo = createRef<HTMLDivElement>();

  newPasswordAgainInput = createRef<HTMLDivElement>();
  newPasswordAgainInfo = createRef<HTMLDivElement>();

  render() {
    return (
      <div className="AuthPageHierarchy" id="page">
        <div className="AuthPageBackground">
          <div className="Title">Şifre Yenileme</div>
          <div className="InputBars">
            <div>
              <div className="InputBar">
                Şu Anki şifreniz
                <div className="Input" ref={this.oldPasswordInput}>
                  <Icons.Password
                    className="Icon"
                    size={25}
                    fill="rgba(0, 0, 0, 0.2)"
                  ></Icons.Password>
                  <input
                    placeholder="Şifre"
                    type={"password"}
                    onChange={(e) => {
                      this.data.oldPassword = e.target.value;
                    }}
                  />
                </div>
              </div>
              <div className="info" ref={this.oldPasswordInfo} />
            </div>
            <div>
              <div className="InputBar">
                Şifreniz
                <div className="Input" ref={this.newPasswordInput}>
                  <Icons.Password
                    className="Icon"
                    size={25}
                    fill="rgba(0, 0, 0, 0.2)"
                  ></Icons.Password>
                  <input
                    placeholder="Şifre"
                    type={"password"}
                    onChange={(e) => {
                      this.data.newPassword = e.target.value;
                    }}
                  />
                </div>
              </div>
              <div className="info" ref={this.newPasswordInfo} />
            </div>
            <div>
              <div className="InputBar">
                Şifreniz (Tekrar)
                <div className="Input" ref={this.newPasswordAgainInput}>
                  <Icons.Password
                    className="Icon"
                    size={25}
                    fill="rgba(0, 0, 0, 0.2)"
                  ></Icons.Password>
                  <input
                    placeholder="Şifre"
                    type={"password"}
                    onChange={(e) => {
                      this.data.newPasswordAgain = e.target.value;
                    }}
                  />
                </div>
              </div>
              <div className="info" ref={this.newPasswordAgainInfo} />
            </div>
          </div>
          <div className="Buttons">
            <button
              className="Button"
              id="Red"
              onClick={() => {
                this.doPasswordReset();
              }}
            >
              Güncelle
            </button>
          </div>
        </div>
      </div>
    );
  }
}
