import { FirebaseError } from "@firebase/app";
import { createRef } from "react";
import { useNavigate } from "react-router-dom";
import { AuthHelpers } from "../../../BackendHelpers/auth";
import { MFAProvider } from "../../../BackendHelpers/mfa";
import Icons from "../../../Icons/svg-icon";
import { loadingStore } from "../../../stores/LoadingStore";
import { feedback } from "../../../stores/feedbackStore";
import { phoneNumberMatcher } from "../RegisterPages/RegisterPage";

export default function ForgotPassword() {
  const inputRef = createRef<HTMLInputElement>();
  const navigate = useNavigate();

  let phoneNumberLastValue = "";

  return (
    <div className="AuthPageHierarchy" id="page">
      <div className="AuthPageBackground">
        <div className="Title">Şifre Yenileme</div>
        <div className="InputBars">
          <div className="InputBar">
            Telefon Numaranız
            <div className="Input">
              <Icons.Telephone
                className="Icon"
                size={24}
                fill="rgba(0, 0, 0, 0.2)"
              ></Icons.Telephone>
              <input
                placeholder="+90"
                type={"tel"}
                ref={inputRef}
                onChange={(event) => {
                  if (phoneNumberLastValue == event.target.value) {
                    return;
                  }
                  let val = event.target.value;

                  val = val.replace(" ", "");

                  let beforeVal = "";
                  if (!val.startsWith("+90") && val.length < 2) {
                    beforeVal = val;
                  }
                  val = "+90" + val.substring(3, val.length) + beforeVal;

                  if (!inputRef.current) return;
                  phoneNumberLastValue = val;
                  inputRef.current.value = val;
                }}
              />
            </div>
          </div>
        </div>
        <div className="Buttons">
          <button
            className="Button"
            id="Red"
            onClick={() => {
              if (!inputRef.current) {
                return;
              }

              if (!phoneNumberMatcher.test(inputRef.current.value)) {
                feedback.push("Geçersiz email adresi", "red", 3);
                return;
              }

              loadingStore.dispatch({ type: "open" });
              AuthHelpers.sendPasswordResetCode(inputRef.current?.value)
                .then(() => {
                  loadingStore.dispatch({ type: "close" });
                })
                .catch((e) => {
                  loadingStore.dispatch({ type: "close" });
                  if (e instanceof FirebaseError) {
                    if (e.code === "functions/not-found") {
                      feedback.push("Telefon numarası bulunamadı.", "red", 3);
                      return;
                    }
                  }

                  feedback.push("Bir hata oluştu.", "red", 3);
                })
                .then(() => {
                  MFAProvider.mfaResolvePromise?.then((v) => {
                    console.log(v);
                    if (v) {
                      navigate(`/sifre-yenile?token=${v.token}`);
                      return;
                    }

                    throw Error("dont got token");
                  });
                });
            }}
          >
            Şifremi Yenile
          </button>
          <button className="Button" id="Outlined" onClick={() => navigate(-1)}>
            Önceki Sayfaya Dön
          </button>
        </div>
      </div>
    </div>
  );
}
