/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { onValue, ref } from "firebase/database";
import { httpsCallable } from "firebase/functions";
import { Component, createRef } from "react";
import { FirebaseProvider } from "../../BackendHelpers/firebaseProvider";
import { MFAProvider } from "../../BackendHelpers/mfa";
import Icons from "../../Icons/svg-icon";
import "../../Pages/AuthPages/AuthPages.css";
import { loadingStore } from "../../stores/LoadingStore";
import { AreYouSure } from "../../stores/areYouSureStore";
import { feedback } from "../../stores/feedbackStore";
import {
  dobMatcher,
  mailAddressMatch,
  phoneNumberMatcher,
} from "../AuthPages/RegisterPages/RegisterPage";

interface InfoState {
  username?: string;
  fullname?: string;
  dateOfBirth?: string;
  province?: string;
  city?: string;
  mailAddress?: string;
  phoneNumber?: string;
}

export default class MyInformationsPage extends Component<unknown, InfoState> {
  authSub: (() => void) | null = null;
  dbSub: (() => void) | null = null;

  constructor(props: object) {
    super(props);
    this.state = {};
  }

  componentDidMount(): void {
    this.authSub = FirebaseProvider.auth.onAuthStateChanged((user) => {
      if (this.dbSub) this.dbSub();
      if (user) {
        this.dbSub = onValue(
          ref(FirebaseProvider.db, `/users/${user.uid}/personalInfo`),
          (snap) => {
            const val = snap.val();
            if (!val) return;

            if (this._dobInput.current && val.dateOfBirth) {
              this._dobInput.current.value = val.dateOfBirth;
            }

            this.setState({
              ...val,
            });
          },
        );
      }
    });
  }

  shouldComponentUpdate(
    nextProps: Readonly<unknown>,
    nextState: InfoState,
  ): boolean {
    const keys = Object.keys(this.state);

    if (keys.length === 0) {
      return true;
    }

    for (const key of keys) {
      // @ts-expect-error iteration with known keys
      if (this.state[key] !== nextState[key]) {
        return true;
      }
    }

    return false;
  }

  componentWillUnmount(): void {
    if (this.dbSub) this.dbSub();
    if (this.authSub) this.authSub();
  }

  stringDefault(a: string, defaultValue: string) {
    if (a == "") return defaultValue;
    return a;
  }

  doUpdateAfterMFA() {
    loadingStore.dispatch({ type: "open" });

    MFAProvider.ensureMFA()
      .then(() => {
        httpsCallable(
          FirebaseProvider.functions,
          "updatePersonalData",
        )({
          userData: {
            username: this.stringDefault(
              this._userNameInput.current!.value,
              this.state.username!,
            ),
            fullname: this.stringDefault(
              this._nameInput.current!.value,
              this.state.fullname!,
            ),
            dateOfBirth: this.stringDefault(
              this._dobInput.current!.value,
              this.state.dateOfBirth!,
            ),
            province: this.stringDefault(
              this._provinceInput.current!.value,
              this.state.province!,
            ),
            city: this.stringDefault(
              this._cityInput.current!.value,
              this.state.city!,
            ),
            mailAddress: this.stringDefault(
              this._mailInput.current!.value,
              this.state.mailAddress!,
            ),
            phoneNumber: this.stringDefault(
              this._phoneNumberInput.current!.value,
              this.state.phoneNumber!,
            ),
          },
          deviceId: FirebaseProvider.deviceId,
          mfaToken: MFAProvider.mfaToken,
        })
          .then(() => {
            loadingStore.dispatch({ type: "close" });
            feedback.push("Kullanıcı verisi güncelleme başarılı.", "green", 3);
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((e: any) => {
            loadingStore.dispatch({ type: "close" });
            feedback.push(e.message ?? "Bir hata oluştu.", "red", 3);
          });
      })
      .catch(() => {
        loadingStore.dispatch({ type: "close" });
        feedback.push("İki faktör doğrulama hatası", "red", 3);
      });
  }

  updateUserData() {
    AreYouSure.open(
      "Güncellemek istediğiniz bilgiler onaylanana kadar hesabınız devre dışı bırakılacaktır. Devam etmek istiyor musunuz?",
      () => this._updateUserData(),
      null,
    );
  }

  async _updateUserData() {
    if (
      !this._mailInput.current ||
      !this._userNameInput.current ||
      !this._nameInput.current ||
      !this._dobInput.current ||
      !this._phoneNumberInput.current ||
      !this._cityInput.current ||
      !this._provinceInput.current ||
      !this.mailInput.current ||
      !this.mailInfo.current ||
      !this.userNameInput.current ||
      !this.userNameInfo.current ||
      !this.nameInput.current ||
      !this.nameInfo.current ||
      !this.dobInput.current ||
      !this.dobInfo.current ||
      !this.phoneNumberInfo.current ||
      !this.phoneNumberInput.current ||
      !this.state.username ||
      !this.state.fullname ||
      !this.state.dateOfBirth ||
      !this.state.province ||
      !this.state.city ||
      !this.state.mailAddress ||
      !this.state.phoneNumber
    ) {
      return;
    }

    if (
      this.state.mailAddress ===
        this.stringDefault(
          this._mailInput.current.value,
          this.state.mailAddress,
        ) &&
      this.state.city ===
        this.stringDefault(this._cityInput.current.value, this.state.city) &&
      this.state.dateOfBirth ===
        this.stringDefault(
          this._dobInput.current.value,
          this.state.dateOfBirth,
        ) &&
      this.state.fullname ===
        this.stringDefault(
          this._nameInput.current.value,
          this.state.fullname,
        ) &&
      this.state.phoneNumber ===
        this.stringDefault(
          this._phoneNumberInput.current.value,
          this.state.phoneNumber,
        ) &&
      this.state.province ===
        this.stringDefault(
          this._provinceInput.current.value,
          this.state.province,
        ) &&
      this.state.username ===
        this.stringDefault(
          this._userNameInput.current.value,
          this.state.username,
        )
    ) {
      feedback.push("Değişiklik yok.", "red", 3);
      return;
    }

    this.mailInfo.current.innerHTML = "";
    this.mailInfo.current.style.color = "inherit";
    this.mailInput.current.style.boxShadow = "inherit";
    this.phoneNumberInfo.current.innerHTML = "";
    this.phoneNumberInfo.current.style.color = "inherit";
    this.phoneNumberInput.current.style.boxShadow = "inherit";
    this.dobInfo.current.innerHTML = "";
    this.dobInfo.current.style.color = "inherit";
    this.dobInput.current.style.boxShadow = "inherit";

    if (
      !mailAddressMatch.test(
        this.stringDefault(
          this._mailInput.current.value,
          this.state.mailAddress,
        ),
      )
    ) {
      feedback.push("Mail adresi geçersiz.", "red", 3);
      this.mailInfo.current.innerHTML = "Mail Geçersiz.";
      this.mailInfo.current.style.color = "red";
      this.mailInput.current.style.boxShadow = "0px 0px 5px rgb(255 0 0)";
      return;
    }

    if (!phoneNumberMatcher.test(this.state.phoneNumber ?? "")) {
      feedback.push("Telefon numarası geçersiz.", "red", 3);
      this.phoneNumberInfo.current.innerHTML = "Telefon Geçersiz.";
      this.phoneNumberInfo.current.style.color = "red";
      this.phoneNumberInput.current.style.boxShadow =
        "0px 0px 5px rgb(255 0 0)";
      return;
    }

    if (
      !dobMatcher.test(
        this.stringDefault(
          this._dobInput.current.value,
          this.state.dateOfBirth,
        ),
      )
    ) {
      feedback.push("Doğum tarihi geçersiz.", "red", 3);
      this.dobInfo.current.innerHTML = "Doğum tarihi geçersiz.";
      this.dobInfo.current.style.color = "red";
      this.dobInput.current.style.boxShadow = "0px 0px 5px rgb(255 0 0)";
      return;
    }

    const mfaTargets: string[] = [];

    if (
      this.state.mailAddress !==
      this.stringDefault(this._mailInput.current.value, this.state.mailAddress)
    ) {
      mfaTargets.push(this._mailInput.current.value);
    }

    if (
      this.state.phoneNumber !==
      this.stringDefault(
        this._phoneNumberInput.current.value,
        this.state.phoneNumber,
      )
    ) {
      mfaTargets.push(this._phoneNumberInput.current.value);
    }

    for (const target of mfaTargets) {
      loadingStore.dispatch({ type: "open" });

      await MFAProvider.requestChannelledMFA(
        "Bilgi Güncellemesi İçin Doğrulama",
        target,
      ).catch((e) => {
        loadingStore.dispatch({ type: "close" });
        if (e.details?.reason === "mfaPending") {
          feedback.push(
            "Bir doğrulama işlemi devam etmekte, daha sonra tekrar deneyin.",
            "red",
            3,
          );
        } else {
          feedback.push("İki faktör doğrulama hatası", "red", 3);
        }

        throw e;
      });

      await MFAProvider.mfaResolvePromise!.then(() => {
        loadingStore.dispatch({ type: "close" });
      }).catch((e) => {
        feedback.push("İptal edildi", "red", 3);
        throw e;
      });
    }

    this.doUpdateAfterMFA();
  }

  _mailInput = createRef<HTMLInputElement>();
  _userNameInput = createRef<HTMLInputElement>();
  _nameInput = createRef<HTMLInputElement>();
  _dobInput = createRef<HTMLInputElement>();
  _phoneNumberInput = createRef<HTMLInputElement>();
  _cityInput = createRef<HTMLInputElement>();
  _provinceInput = createRef<HTMLInputElement>();

  mailInput = createRef<HTMLDivElement>();
  mailInfo = createRef<HTMLDivElement>();

  userNameInput = createRef<HTMLDivElement>();
  userNameInfo = createRef<HTMLDivElement>();

  nameInput = createRef<HTMLDivElement>();
  nameInfo = createRef<HTMLDivElement>();

  dobInput = createRef<HTMLDivElement>();
  dobInfo = createRef<HTMLDivElement>(); // if you want use this prop, add to render

  phoneNumberInput = createRef<HTMLDivElement>();
  phoneNumberInfo = createRef<HTMLDivElement>();

  render() {
    return (
      <div
        className="AuthPageHierarchy"
        style={{
          width: "95%",
        }}
      >
        <div className="AuthPageBackground">
          <div className="Title">Üyelik Bilgilerim</div>
          <div className="InputBars">
            <div>
              <div className="InputBar">
                E-Posta Adresiniz
                <div className="Input" ref={this.mailInput}>
                  <Icons.Mail
                    className="Icon"
                    size={25}
                    fill="rgba(0, 0, 0, 0.2)"
                  ></Icons.Mail>
                  <input
                    placeholder={this.state.mailAddress}
                    ref={this._mailInput}
                  />
                </div>
              </div>
              <div className="info" ref={this.mailInfo} />
            </div>
            <div>
              <div className="InputBar">
                Kullanıcı Adınız
                <div className="Input" ref={this.userNameInput}>
                  <Icons.MultipleUser
                    className="Icon"
                    size={25}
                    fill="rgba(0, 0, 0, 0.2)"
                  ></Icons.MultipleUser>
                  <input
                    disabled
                    type="text"
                    placeholder={this.state.username}
                    ref={this._userNameInput}
                  />
                </div>
              </div>
              <div className="info" ref={this.userNameInfo} />
            </div>
            <div>
              <div className="InputBar">
                Adınız Soyadınız
                <div className="Input" ref={this.nameInput}>
                  <Icons.User
                    className="Icon"
                    size={25}
                    fill="rgba(0, 0, 0, 0.2)"
                  ></Icons.User>
                  <input
                    disabled
                    type="text"
                    placeholder={this.state.fullname}
                    ref={this._nameInput}
                  />
                </div>
              </div>
              <div className="info" ref={this.nameInfo} />
            </div>
            <div className="InputBarsRow">
              <div className="InputBar">
                Doğum Tarihiniz
                <div className="Input" ref={this.dobInput}>
                  <Icons.Date
                    className="Icon"
                    size={24}
                    fill="rgba(0, 0, 0, 0.2)"
                  ></Icons.Date>
                  <input type={"date"} ref={this._dobInput} />
                  <div
                    className="info"
                    ref={this.dobInfo}
                    style={{ display: "none" }}
                  ></div>
                </div>
              </div>
              <div className="InputBar">
                Telefon Numaranız
                <div className="Input" ref={this.phoneNumberInput}>
                  <Icons.Telephone
                    className="Icon"
                    size={24}
                    fill="rgba(0, 0, 0, 0.2)"
                  ></Icons.Telephone>
                  <input
                    type={"tel"}
                    placeholder={this.state.phoneNumber}
                    ref={this._phoneNumberInput}
                  />
                </div>
                <div
                  className="info"
                  ref={this.phoneNumberInfo}
                  style={{ display: "none" }}
                ></div>
              </div>
            </div>
            <div className="InputBarsRow">
              <div className="InputBar">
                İl
                <div className="Input">
                  <Icons.Location
                    className="Icon"
                    size={24}
                    fill="rgba(0, 0, 0, 0.2)"
                  ></Icons.Location>
                  <input
                    placeholder={this.state.province}
                    ref={this._provinceInput}
                  />
                </div>
              </div>
              <div className="InputBar">
                İlçe
                <div className="Input">
                  <Icons.Compass
                    className="Icon"
                    size={24}
                    fill="rgba(0, 0, 0, 0.2)"
                  ></Icons.Compass>
                  <input placeholder={this.state.city} ref={this._cityInput} />
                </div>
              </div>
            </div>
          </div>

          <div
            className="Buttons"
            style={{
              gap: "5px",
            }}
          >
            <span
              style={{
                marginLeft: "38px",
                marginRight: "38px",
                fontFamily: "Rubik-Medium",
                fontSize: "14px",
                color: "rgba(0, 0, 0, 0.3)",
              }}
            >
              Değişiklikler onaylanana kadar hesabınız devre dışı bırakılacak
            </span>
            <button
              className="Button"
              id="Red"
              onClick={() => {
                this.updateUserData();
              }}
            >
              Güncelle
            </button>
          </div>
        </div>
      </div>
    );
  }
}
