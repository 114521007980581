import { configureStore } from "@reduxjs/toolkit";

export interface areYouSure {
  isOpen: boolean;
  message: string;
}

export const areYouSureStore = configureStore<{ rus: areYouSure }>({
  reducer: (state, action) => {
    return {
      rus: action.type.rus,
    };
  },
});

export class AreYouSure {
  static yesCallBack = () => {
    /**/
  };
  static noCallBack = () => {
    /**/
  };

  static close() {
    this.yesCallBack = () => {
      /**/
    };
    this.noCallBack = () => {
      /**/
    };

    areYouSureStore.dispatch({
      type: {
        rus: {
          isOpen: false,
          message: "",
        },
      },
    });
  }

  static open(
    message: string,
    yes: (() => void) | null,
    no: (() => void) | null,
  ) {
    if (yes !== null) this.yesCallBack = yes;
    if (no !== null) this.noCallBack = no;

    areYouSureStore.dispatch({
      type: {
        rus: {
          isOpen: true,
          message: message,
        },
      },
    });
  }
}
