import { FirebaseError } from "@firebase/app";
import { Component, createRef } from "react";
import { NavLink, Navigate } from "react-router-dom";
import {
  AuthHelpers,
  InvalidEmail,
  LoginFailed,
  QuotaExceeded,
  UserNotFound,
  WrongPassword,
} from "../../../BackendHelpers/auth";
import { AuthMissing, MFAError } from "../../../BackendHelpers/mfa";
import Icons from "../../../Icons/svg-icon";
import { loadingStore } from "../../../stores/LoadingStore";
import { feedback } from "../../../stores/feedbackStore";
import "../AuthPages.css";
import {
  passwordMatcher,
  phoneNumberMatcher,
} from "../RegisterPages/RegisterPage";

interface State {
  redirect: JSX.Element | null;
}

export default class LoginPage extends Component<unknown, State> {
  data: {
    target?: string;
    password?: string;
  };

  phoneNumberInput = createRef<HTMLDivElement>();
  phoneNumberInfo = createRef<HTMLDivElement>();
  phoneNumberInputBar = createRef<HTMLInputElement>();
  phoneNumberLastValue = "";

  passwordInput = createRef<HTMLDivElement>();
  passwordInfo = createRef<HTMLDivElement>();

  constructor(props: object) {
    super(props);
    this.data = {};
    this.state = {
      redirect: null,
    };
  }

  async doLogin() {
    this.validateInputs();
    this.doPendingFeedback("Giriş işlemi sürüyor...");

    try {
      await AuthHelpers.login(this.data.target ?? "", this.data.password ?? "");
    } catch (e) {
      if (e instanceof InvalidEmail) {
        this.doMailErrorFeedback("Mail geçersiz");
      } else if (e instanceof WrongPassword) {
        this.doPasswordErrorFeedback("Şifre Yanlış");
      } else if (e instanceof UserNotFound) {
        this.doMailErrorFeedback("Telefon Numarası bulunamadı");
      } else if (e instanceof QuotaExceeded) {
        this.doErrorFeedback("Çok fazla başarısız deneme yapıldı.");
      } else if (e instanceof LoginFailed) {
        this.doErrorFeedback(
          "Giriş başarısız. Mail ve şifrenizi kontrol ediniz.",
        );
      } else if (e instanceof AuthMissing) {
        feedback.push("Kayıt tamamlanmamış", "red", 3);
        this.setState({ redirect: <Navigate to="/kayit-ol"></Navigate> });
      } else if (e instanceof MFAError) {
        this.doErrorFeedback("İki faktör doğrulama hatası.");
      } else {
        if (e instanceof FirebaseError) {
          if (e.code === "functions/already-exists") {
            this.doErrorFeedback(
              "Devam eden bir doğrulama işlemi bulunmakta, birazdan tekrar deneyiniz.",
            );
          } else {
            console.error(e, e.code);
            this.doErrorFeedback("Bir hata oluştu.");
          }
        } else {
          console.error(e);
          this.doErrorFeedback("Bir hata oluştu.");
        }
      }

      return;
    } finally {
      loadingStore.dispatch({ type: "close" });
    }

    this.setState({ redirect: <Navigate to="/"></Navigate> });
    this.doSuccessFeedback("Giriş başarılı.");
  }

  validateInputs() {
    this.resetInputErrors();
    if (!phoneNumberMatcher.test(this.data.target ?? "")) {
      this.doMailErrorFeedback("Telefon numarası geçerli değil.");
      throw new LoginValidationFailed();
    }
    if (!passwordMatcher.test(this.data.password ?? "")) {
      this.doPasswordErrorFeedback("Şifre geçerli değil.");
      throw new LoginValidationFailed();
    }
  }

  resetInputErrors() {
    if (this.phoneNumberInfo.current && this.phoneNumberInput.current) {
      this.phoneNumberInfo.current.innerHTML = "";
      this.phoneNumberInfo.current.style.color = "inherit";
      this.phoneNumberInput.current.style.boxShadow = "inherit";
    }

    if (this.passwordInfo.current && this.passwordInput.current) {
      this.passwordInfo.current.innerHTML = "";
      this.passwordInfo.current.style.color = "inherit";
      this.passwordInput.current.style.boxShadow = "inherit";
    }
  }

  doMailErrorFeedback(message: string) {
    if (this.phoneNumberInfo.current && this.phoneNumberInput.current) {
      this.phoneNumberInfo.current.innerHTML = message;
      this.phoneNumberInfo.current.style.color = "red";
      this.phoneNumberInput.current.style.boxShadow =
        "0px 0px 5px rgb(255 0 0)";
    }
  }

  doPasswordErrorFeedback(message: string) {
    if (this.passwordInfo.current && this.passwordInput.current) {
      this.passwordInfo.current.innerHTML = message;
      this.passwordInfo.current.style.color = "red";
      this.passwordInput.current.style.boxShadow = "0px 0px 5px rgb(255 0 0)";
    }
  }

  doPendingFeedback(message: string) {
    loadingStore.dispatch({ type: "open" });
  }

  doErrorFeedback(message: string) {
    feedback.push(message, "red", 3);
  }

  doSuccessFeedback(message: string) {
    feedback.push(message, "green", 1);
  }

  render() {
    return this.state.redirect ? (
      this.state.redirect
    ) : (
      <div className="AuthPageHierarchy" id="page">
        <div className="AuthPageBackground">
          <div className="Title">Giriş Yap</div>
          <div className="InputBars">
            <div>
              <div className="InputBar">
                Telefon Numaranız
                <div className="Input" ref={this.phoneNumberInput}>
                  <Icons.Telephone
                    className="Icon"
                    size={24}
                    fill="rgba(0, 0, 0, 0.2)"
                  ></Icons.Telephone>
                  <input
                    placeholder="+90"
                    type={"tel"}
                    ref={this.phoneNumberInputBar}
                    onChange={(event) => {
                      if (this.phoneNumberLastValue == event.target.value) {
                        return;
                      }
                      let val = event.target.value;

                      val = val.replace(" ", "");

                      let beforeVal = "";
                      if (!val.startsWith("+90") && val.length < 2) {
                        beforeVal = val;
                      }
                      val = "+90" + val.substring(3, val.length) + beforeVal;

                      if (!this.phoneNumberInputBar.current) return;
                      this.data.target = val;
                      this.phoneNumberLastValue = val;
                      this.phoneNumberInputBar.current.value = val;
                    }}
                  />
                </div>
                <div className="info" ref={this.phoneNumberInfo}></div>
              </div>
            </div>
            <div>
              <div className="InputBar">
                Şifreniz
                <div className="Input" ref={this.passwordInput}>
                  <Icons.Password
                    className="Icon"
                    size={25}
                    fill="rgba(0, 0, 0, 0.2)"
                  ></Icons.Password>
                  <input
                    type="password"
                    placeholder="Şifre"
                    onChange={(e) => {
                      this.data.password = e.target.value;
                    }}
                  />
                </div>
              </div>
              <div className="info" ref={this.passwordInfo} />
            </div>
          </div>
          <NavLink to="/giris-yap/sifremi-unuttum" className={"ForgotPassword"}>
            Şifremi Unuttum
          </NavLink>
          <div className="Buttons">
            <button
              className="Button"
              id="Red"
              onClick={() => {
                this.doLogin();
              }}
            >
              Giriş Yap
            </button>
            <NavLink to={"/kayit-ol"} className="Button" id="Black">
              Kayıt Ol
            </NavLink>
          </div>
        </div>
        <div className="Information">
          Kişisel verileriniz,{" "}
          <NavLink to={"/kurumsal/gizlilik-politikasi"}>
            Gizlilik Politikası
          </NavLink>{" "}
          kapsamında işlenmektedir. “Giriş Yap” veya “Kayıt Ol” butonlarından
          birine basarak{" "}
          <NavLink to={"/kurumsal/kullanim-kosullari"}>
            Kullanıcı Sözleşmesi
          </NavLink>
          ’ni ve{" "}
          <NavLink to={"/kurumsal/cerez-politikasi"}>Çerez Politikası</NavLink>
          ’nı okuduğunuzu ve kabul ettiğinizi onaylıyorsunuz.
        </div>
      </div>
    );
  }
}

export class LoginValidationFailed extends Error {}
