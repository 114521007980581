import { Component, createRef } from "react";
import "./NewsPage.css";

import Icons from "../../Icons/svg-icon";

import profilePhoto from "../../Images/Profile.png";

import { Buffer } from "buffer";
import { onValue, ref } from "firebase/database";
import { httpsCallable } from "firebase/functions";
import { Navigate, useParams } from "react-router-dom";
import { FirebaseProvider } from "../../BackendHelpers/firebaseProvider";
import { fetchHtmlPage } from "../../BackendHelpers/htmlFetch";
import {
  ENDPOINT_BASE,
  PagedResource,
} from "../../BackendHelpers/pagedResource";
import NewsSearchArea from "../../Components/News/NewsSearchArea";
import { feedback } from "../../stores/feedbackStore";
import { loadingStore } from "../../stores/LoadingStore";

export default function NewsPage() {
  const { id } = useParams();
  return id ? <_NewsPage id={id} key={id}></_NewsPage> : null;
}

const QUERY_ADDR = `${ENDPOINT_BASE}/newsDataQuery.php`;

export class _NewsPage extends Component<
  { id: string },
  {
    publish_date: number;
    comment_count: number;
    title: string;
    main_picture: string;
    views: number;
    likes: number;
    page_data: string;
    err: boolean;
    selfLike: boolean;
    selfCommentCount: number;
  }
> {
  content = "";

  constructor(props: { id: string }) {
    super(props);
    this.state = {
      publish_date: 0,
      comment_count: 0,
      title: "",
      main_picture: "",
      views: 0,
      likes: 0,
      page_data: "",
      err: false,
      selfLike: false,
      selfCommentCount: 0,
    };
  }

  componentDidMount() {
    this.fetchMeta();
  }

  async fetchMeta() {
    let resp;
    try {
      resp = await fetch(QUERY_ADDR, {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: JSON.stringify({
          id: Number.parseInt(this.props.id),
        }),
      });

      if (resp.status !== 200) {
        this.setState({ err: true });
        throw new Error("news query failed");
      }
    } catch {
      this.setState({ err: true });
      throw new Error("news query failed");
    }

    const json: {
      publish_date: number;
      comment_count: number;
      title: string;
      main_picture: string;
      views: number;
      likes: number;
      page_data: string;
    } = await resp.json();

    this.setState({
      ...json,
      err: false,
    });

    this.fetchContent(json.page_data);
  }

  fetchContent(pageData: string) {
    fetchHtmlPage(pageData)
      .then((val) => {
        val
          ?.getReader()
          .read()
          .then((val) => {
            if (val.value) {
              this.content = Buffer.from(val.value).toString();
              this.setState({});
            }
          });
      })
      .catch(() => {
        this.setState({ err: true });
        throw new Error("news content fetch failed");
      });
  }

  render() {
    return (
      <div className="newspage-viewport">
        <div className="news-content">
          <div
            style={{ overflowWrap: "break-word" }}
            dangerouslySetInnerHTML={{ __html: this.content }}
          />
          {!this.state.err ? (
            <div className="news-inf-area">
              <div>
                <Icons.Date size={20}></Icons.Date>
                {new Date(this.state.publish_date).toLocaleTimeString()}
              </div>
              <div>
                <Icons.Message size={20}></Icons.Message>
                {this.state.comment_count + this.state.selfCommentCount} yorum
              </div>
              <div>
                <Icons.Eye size={20}></Icons.Eye>
                {this.state.views} Görüntülenme
              </div>
              <div>
                <Icons.Heart size={20}></Icons.Heart>
                {this.state.likes + (this.state.selfLike ? 1 : 0)} Beğeni
              </div>
            </div>
          ) : (
            <span className="not-found">Haber yüklenemedi.</span> // TODO
          )}
          <Comments id={this.props.id} parent={this}></Comments>
        </div>
        <NewsSearchArea></NewsSearchArea>
      </div>
    );
  }
}

interface CommentData {
  comment_id: number;
  message: string;
  publish_date: number;
  username: string;
}

export class CommentsManager extends PagedResource<CommentData> {
  ENDPOINT = `${ENDPOINT_BASE}/commentsQuery.php`;
  ITEM_CNT_PER_PAGE = 10;

  itemBuilder(e: CommentData) {
    return Comments.commentComponent(e.username, e.message);
  }
}

export class Comments extends Component<
  {
    id: string;
    parent: _NewsPage;
  },
  {
    isLiked: boolean;
    pageIndex: number;
    err: boolean;
    navigateToLogin: boolean;
    canLoadMore: boolean;
  }
> {
  commentManager: CommentsManager;
  selfComments: JSX.Element[] = [];

  likeListeners: (() => void)[] = [];

  commentInput = createRef<HTMLInputElement>();

  constructor(props: { id: string; parent: _NewsPage }) {
    super(props);
    this.commentManager = new CommentsManager();
    this.state = {
      pageIndex: 0,
      isLiked: false,
      err: false,
      navigateToLogin: false,
      canLoadMore: true,
    };
  }

  componentDidMount(): void {
    this.commentManager
      .loadPage(0, this, { id: Number.parseInt(this.props.id) })
      .then(() => {
        if (this.commentManager.loadFinished) {
          this.setState({ canLoadMore: false });
        }
      })
      .catch((e) => {
        this.setState({ err: true });
      });

    this.listenLikeStatus();
  }

  componentWillUnmount(): void {
    if (
      this.commentManager.loadedItems.length ||
      this.commentManager.loadFinished
    ) {
      this.commentManager.clear();
    }

    this.unlistenLikeStatus();
  }

  static commentComponent(
    userName: string,
    comment: string,
    photoUrl?: string,
  ) {
    return (
      <div className="comment">
        <img
          src={photoUrl == null || photoUrl == "" ? profilePhoto : photoUrl}
          alt=""
        />
        <div className="infs">
          <h3>{userName}</h3>
          <p>{comment}</p>
        </div>
      </div>
    );
  }

  heartIcon() {
    if (this.state.isLiked) {
      return <Icons.HeartFull fill={"#D02224"} size={25}></Icons.HeartFull>;
    } else return <Icons.Heart fill={"#D02224"} size={25}></Icons.Heart>;
  }

  listenLikeStatus() {
    FirebaseProvider.auth.onAuthStateChanged((user) => {
      this.likeListeners.forEach((e) => e());
      this.likeListeners.length = 0;

      if (!user) {
        this.setState({ isLiked: false });
        return;
      }

      this.likeListeners.push(
        onValue(
          ref(
            FirebaseProvider.db,
            `/users/${FirebaseProvider.auth.currentUser?.uid}/likedNews/${this.props.id}`,
          ),
          (val) => {
            this.props.parent.setState({
              selfLike: val.exists(),
            });
            this.setState({ isLiked: val.exists() });
          },
        ),
      );
    });
  }

  unlistenLikeStatus() {
    this.likeListeners.forEach((e) => e());
    this.likeListeners.length = 0;
  }

  unlikePost() {
    if (!FirebaseProvider.auth.currentUser) {
      this.setState({ navigateToLogin: true });
      return;
    }

    const prev = this.state.isLiked;
    this.setState({ isLiked: false });
    if (!FirebaseProvider.auth.currentUser?.uid) {
      return;
    }

    this.setState({ isLiked: false });
    httpsCallable(
      FirebaseProvider.functions,
      "unlikeNew",
    )({
      id: Number.parseInt(this.props.id),
    })
      .then(() => {
        this.props.parent.setState({
          selfLike: false,
        });
        this.setState({ isLiked: false });
      })
      .catch(() => {
        this.setState({ isLiked: prev });
        feedback.push("Beğeni kaldırılamadı.", "red", 3);
      });
  }

  likePost() {
    if (!FirebaseProvider.auth.currentUser) {
      this.setState({ navigateToLogin: true });
      return;
    }

    const prev = this.state.isLiked;
    this.setState({ isLiked: true });
    httpsCallable(
      FirebaseProvider.functions,
      "likeNew",
    )({
      id: Number.parseInt(this.props.id),
    })
      .then((val) => {
        if (typeof val === "boolean") {
          this.setState({ isLiked: val });
        }
      })
      .catch(() => {
        this.setState({ isLiked: prev });
        feedback.push("Haber beğenilemedi.", "red", 3);
      });
  }

  toggleLike() {
    if (this.state.isLiked) this.unlikePost();
    else this.likePost();
  }

  makeComment() {
    if (!this.commentInput.current?.value) {
      return;
    }

    if (!FirebaseProvider.auth.currentUser) {
      this.setState({ navigateToLogin: true });
      return;
    }

    const val = this.commentInput.current?.value;

    loadingStore.dispatch({ type: "open" });

    httpsCallable(
      FirebaseProvider.functions,
      "makeComment",
    )({
      postId: Number.parseInt(this.props.id),
      comment: val,
    })
      .then(() => {
        this.selfComments.push(
          Comments.commentComponent(
            FirebaseProvider.auth.currentUser!.displayName ?? "",
            val,
          ),
        );
        loadingStore.dispatch({ type: "close" });
        this.props.parent.setState((prev) => {
          return {
            selfCommentCount: prev.selfCommentCount + 1,
          };
        });
        if (this.commentInput.current !== null) {
          this.commentInput.current.value = "";
        }
        this.setState({});
      })
      .catch(() => {
        feedback.push("Bir hata ile karşılaştık", "red", 3);
        loadingStore.dispatch({ type: "close" });
      });
  }

  render() {
    if (this.state.navigateToLogin) {
      return <Navigate to="/giris-yap"></Navigate>;
    }

    return !this.state.err ? (
      <div>
        <h2>Yorum Yazın</h2>
        <div className="comment-bar">
          <div>
            <Icons.Message
              className="Icon"
              size={16}
              fill={"#17161A"}
              style={{ overflow: "hidden" }}
            ></Icons.Message>
          </div>
          <input
            type="text"
            placeholder={"Yorumunuz"}
            ref={this.commentInput}
          />
        </div>
        <div className="comment-buttons">
          <button id="send" onClick={() => this.makeComment()}>
            Gönder
          </button>
          <button id="like" onClick={() => this.toggleLike()}>
            {this.heartIcon()}
          </button>
        </div>
        <div className="comments">
          {this.selfComments.length ? <div>{this.selfComments}</div> : null}
          {this.commentManager.loadedItems.length ? (
            <div>{this.commentManager.loadedItems}</div>
          ) : null}
          {!this.commentManager.loadedItems.length &&
          !this.selfComments.length ? (
            <span className="not-found">İlk yorumu sen yap</span>
          ) : null}
          <button
            style={{
              display: this.state.canLoadMore ? "block" : "none",
              width: "min-content",
              alignSelf: "center",
              whiteSpace: "nowrap",
              backgroundColor: "#17161A",
              color: "white",
              borderRadius: "8px",
              padding: "10px",
              paddingTop: "6px",
              paddingBottom: "6px",
              fontFamily: "Rubik-Medium",
              fontSize: "18px",
            }}
            onClick={() => {
              loadingStore.dispatch({ type: "open" });
              this.commentManager.incrementPage(
                this,
                { id: Number.parseInt(this.props.id) },
                () => {
                  loadingStore.dispatch({ type: "close" });
                  this.selfComments.length = 0;
                  if (this.commentManager.loadFinished) {
                    this.setState({ canLoadMore: false });
                  }
                },
                () => {
                  loadingStore.dispatch({ type: "close" });
                },
              );
            }}
          >
            Daha fazla yükle
          </button>
        </div>
      </div>
    ) : (
      <span className="not-found">Yorumlar yüklenemedi</span>
    );
  }
}
