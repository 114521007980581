import { Buffer } from "buffer";
import React from "react";
import Error500 from "../Pages/ErrorPages/500/Error500";

export class ErrorHandler extends React.Component<
  { children: JSX.Element },
  {
    hasError: boolean;
    key: number;
    err: { error: Error; errorInfo: React.ErrorInfo } | null;
  }
> {
  constructor(props: { children: JSX.Element }) {
    super(props);
    this.state = {
      key: 0,
      hasError: false,
      err: null,
    };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({
      hasError: true,
      err: { error, errorInfo },
    });

    console.log("err catched");
  }

  render() {
    return !this.state.hasError ? (
      <div key={this.state.key}>{this.props.children}</div>
    ) : (
      <Error500>
        {this.state.err?.errorInfo.componentStack +
          " / msg:" +
          Buffer.from(this.state.err?.error.message ?? "").toString("base64") +
          " / cause: " +
          this.state.err?.error.cause}
        <br></br>
        <div>stack: {this.state.err?.error.stack ?? "none"}</div>
      </Error500>
    );
  }
}
