import { Component } from "react";
import Icons from "../../Icons/svg-icon";
import "./News.css";

import { NavLink } from "react-router-dom";
import { NewsData } from "../../Pages/Newscast/Newscast";

export default class News extends Component<NewsData> {
  render() {
    return (
      <NavLink to={`/haberler/${this.props.new_id}`} style={{
        textDecoration: "none",
        padding: "0",
      }}>
        <div className="news-background">
          <div style={{
            margin: "0",
          }}>
            <img src={this.props.main_picture} alt="" className="photo" style={{
              objectFit: "cover",
            }} />
          </div>
          <div style={{
            color: "#17161A",
            fontFamily: "Rubik-Regular",
            fontSize: "14px",
          }}>
            <li>
              <Icons.Date size={18}></Icons.Date>{" "}
              {new Date(this.props.publish_date).toLocaleString()}
            </li>
            <div />
            <li>
              <Icons.Message size={18}></Icons.Message>{" "}
              {this.props.comment_count} Yorum
            </li>
          </div>
          <li className="title">{this.props.title}</li>
        </div>
      </NavLink>
    );
  }
}
