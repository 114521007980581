import { configureStore } from "@reduxjs/toolkit";

export const feedbackStore = configureStore<{
  count: number;
  message: string;
  messageColor: string;
}>({
  reducer: (state, action) => {
    return {
      count: action.type.count,
      message: action.type.message,
      messageColor: action.type.messageColor,
    };
  },
});

export class feedback {
  static lastMessage: string;
  static lastColor: string;

  static initalize() {
    feedbackStore.dispatch({
      type: {
        message: "",
        messageColor: "tranparent",
        count: 0,
      },
    });
  }

  static push(message: string, messageColor: string, second: number) {
    this.lastMessage = message;
    this.lastColor = messageColor;

    feedbackStore.dispatch({
      type: {
        message: message,
        messageColor: messageColor,
        count: feedbackStore.getState().count + 1,
      },
    });
    setTimeout(() => {
      if (feedbackStore.getState().count < 1) return;

      feedbackStore.dispatch({
        type: {
          message: this.lastMessage,
          messageColor: this.lastColor,
          count: feedbackStore.getState().count - 1,
        },
      });
    }, 1000 * second + 400);
  }

  static removeAll() {
    feedbackStore.dispatch({
      type: {
        message: this.lastMessage,
        messageColor: this.lastColor,
        count: 0,
      },
    });
  }
}
