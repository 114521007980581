import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "./SliderLayout.css";

// reqs
interface Props {
  items: JSX.Element[];
  name?: string;
  startMiddle?: boolean;
  isAutomatic?: boolean;
  hideSelectors?: boolean;
  justResponsive?: boolean;
  infinity?: boolean;
  AutomaticTimer?: number;
  keepButton?: string;
  bottomButton?: string;
  to?: string;
  itemPercents?: number;
  maxScale?: number;
  minScale?: number;
  clearance?: number;
}

interface SliderState {
  WindowSize: number;
  SlideIndex: number;
  startLoc: number;
  isPressed: boolean;
  lastTouch: number;
}

export default class SliderLayout extends Component<Props, SliderState> {
  interval: NodeJS.Timer | undefined;

  constructor(props: Props) {
    super(props);
    this.state = {
      WindowSize: window.innerWidth,
      SlideIndex: this.props.startMiddle
        ? SliderLayout.clamp(
            0,
            this.props.items.length - 1,
            Math.floor(this.props.items.length / 2),
          )
        : 0,
      startLoc: window.innerWidth / 2,
      isPressed: false,
      lastTouch: new Date().getTime(),
    };
  }

  static clamp(minVal: number, maxVal: number, currentVal: number) {
    if (currentVal > maxVal) return maxVal;
    if (currentVal < minVal) return minVal;
    return currentVal;
  }

  resizerFunc = this.resizer;
  componentDidMount(): void {
    this.resizerFunc = () => {
      this.resizer();
    };
    window.addEventListener("resize", this.resizerFunc);

    if (!this.props.isAutomatic) return;

    this.interval = setInterval(
      () => {
        if (new Date().getTime() - this.state.lastTouch < 3000) return;
        if (this.state.isPressed) return;

        this.setState((state) => {
          return {
            SlideIndex:
              state.SlideIndex < this.props.items.length - 1
                ? state.SlideIndex + 1
                : 0,
          };
        });
      },
      !this.props.AutomaticTimer
        ? 3000
        : this.props.AutomaticTimer < 3000
        ? 3000
        : this.props.AutomaticTimer,
    );
  }
  componentWillUnmount(): void {
    if (this.props.isAutomatic) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
    window.removeEventListener("resize", this.resizerFunc);
  }
  resizer() {
    this.setState({ WindowSize: window.innerWidth });
  }

  getJustify(index: number) {
    if (this.state.SlideIndex > index) return "right";
    else if (this.state.SlideIndex < index) return "left";
    else return "center";
  }

  // Mouse Controller
  onMouseMove(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (!this.state.isPressed) {
      return;
    }
    this.controlSwipe(event.clientX);
  }
  onMouseDown(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    this.setState({ startLoc: event.clientX });
    this.setState({ isPressed: true });
  }

  // Touch
  onTouchMove(e: React.TouchEvent<HTMLDivElement>) {
    if (!this.state.isPressed) return;
    this.controlSwipe(e.changedTouches[0].clientX);
  }
  onTouchEnd() {
    this.setState({ isPressed: false });
    this.setState({ lastTouch: new Date().getTime() });
  }
  onTouchStart(e: React.TouchEvent<HTMLDivElement>) {
    this.setState({ startLoc: e.changedTouches[0].clientX });
    this.setState({ isPressed: true });
  }

  controlSwipe(clientX = 0) {
    const diff = clientX - this.state.startLoc;

    const width = window.innerWidth;

    if (diff / width > 0.1 && this.state.SlideIndex > 0) {
      this.setState({ isPressed: false });
      this.setState({ startLoc: clientX });
      this.setState({ SlideIndex: this.state.SlideIndex - 1 });
    } else if (
      diff / width < -0.1 &&
      this.state.SlideIndex <
        (this.props.to === undefined
          ? this.props.items.length - 1
          : this.props.items.length)
    ) {
      this.setState({ isPressed: false });
      this.setState({ startLoc: clientX });
      this.setState({ SlideIndex: this.state.SlideIndex + 1 });
    }
  }

  render() {
    if (this.props.justResponsive === false) return this.responsive();
    if (window.innerWidth <= 1100) return this.responsive();
    else return this.pc();
  }

  getLeftMargin(index: number) {
    if (this.state.SlideIndex < index) return "0%";
    else if (this.state.SlideIndex > index) return "50%";
    else return "0%";
  }

  getRightMargin(index: number) {
    if (this.state.SlideIndex < index) return "50%";
    else if (this.state.SlideIndex > index) return "0%";
    else return "0%";
  }

  getLastButton(
    maxScale: number,
    minScale: number,
    keepButton?: string,
    to?: string,
  ) {
    if (to === undefined) return null;
    else if (keepButton === undefined) return null;
    else {
      return (
        <div className="item" style={{
          justifyContent: "center",
        }}>
          <NavLink
            to={to}
            className="see-all"
            style={{
              scale:
                this.state.SlideIndex === this.props.items.length
                  ? maxScale
                  : minScale,
            }}
          >
            {keepButton}
          </NavLink>
        </div>
      );
    }
  }

  getBottomButton(bottomButton?: string, to?: string) {
    if (to === undefined) return null;
    else {
      return (
        <NavLink to={to} className="see-all-bottom">
          {bottomButton}
        </NavLink>
      );
    }
  }

  getName(bottom: string, name?: string) {
    if (name === undefined) return null;
    else {
      return (
        <div className="title" style={{ marginBottom: bottom }}>
          {name}
        </div>
      );
    }
  }

  getSelectors() {
    if (this.props.hideSelectors) return null;

    const _out = [];
    for (let i = 0; i < this.props.items.length; i++) {
      _out.push(
        <button
          key={i}
          style={{
            backgroundColor: this.state.SlideIndex === i ? "#D02224" : "white",
          }}
          onClick={() => {
            this.setState({ SlideIndex: i });
          }}
        />,
      );
    }

    return <div className="selectors">{_out}</div>;
  }

  getItemsWithScaleForInf(minScale: number, maxScale: number) {
    const _out = [];

    for (let i = 0; i < this.props.items.length; i++) {
      _out.push(
        <div
          className="item"
          key={i}
          style={{
            scale:
              this.state.SlideIndex === i
                ? maxScale?.toString()
                : minScale?.toString(),
          }}
        >
          {this.props.items[i]}
        </div>,
      );
    }
    return _out;
  }

  getItemsWithScale(minScale: number, maxScale: number) {
    const _out = [];
    for (let i = 0; i < this.props.items.length; i++) {
      _out.push(
        <div
          className="item"
          key={i}
          style={{
            scale:
              this.state.SlideIndex === i
                ? maxScale?.toString()
                : minScale?.toString(),
          }}
        >
          {this.props.items[i]}
        </div>,
      );
    }
    return _out;
  }

  getItems() {
    const _out = [];
    for (let i = 0; i < this.props.items.length; i++) {
      _out.push(
        <div className="item" key={i}>
          {this.props.items[i]}
        </div>,
      );
    }

    return _out;
  }

  responsive() {
    let {
      itemPercents,
      name,
      maxScale,
      minScale,
      to,
      bottomButton,
      keepButton,
      clearance,
    } = this.props;
    if (itemPercents === undefined) itemPercents = 50;
    if (maxScale === undefined) maxScale = 1;
    if (minScale === undefined) minScale = 0.6;
    if (clearance === undefined) clearance = 10;

    const contentWidth =
      itemPercents * (this.props.items.length + (to === undefined ? 0 : 1));
    const contentPadding = (100 - itemPercents) / 2;

    return (
      <div className="slider-place-holder">
        {this.getName("0", name)}
        <div className="viewport">
          <div
            className="content"
            style={{
              width: contentWidth + "%",
              position: "relative",
              paddingRight: contentPadding + "%",
              paddingLeft: contentPadding + "%",
              right: this.state.SlideIndex * itemPercents + "%",
              marginTop: clearance + "px",
              marginBottom: clearance + "px",
            }}
            onMouseMove={(event) => this.onMouseMove(event)}
            onMouseLeave={() => {
              this.onTouchEnd();
            }}
            onMouseDown={(event) => {
              this.onMouseDown(event);
            }}
            onMouseUp={() => {
              this.onTouchEnd();
            }}
            onTouchEnd={() => {
              this.onTouchEnd();
            }}
            onTouchCancel={() => {
              this.onTouchEnd();
            }}
            onTouchMove={(e) => {
              this.onTouchMove(e);
            }}
            onTouchStart={(e) => {
              this.onTouchStart(e);
            }}
          >
            {this.getItemsWithScale(minScale, maxScale)}
            {this.getLastButton(maxScale, minScale, keepButton, to)}
          </div>
        </div>

        {this.getSelectors()}
        {this.getBottomButton(bottomButton, to)}
      </div>
    );
  }

  pc() {
    const { name, bottomButton, to } = this.props;

    return (
      <div className="slider-place-holder">
        {this.getName("10px", name)}
        <div
          className="content"
          style={{
            gap: "25px",
            marginLeft: "5%",
            marginRight: "5%",
            justifyContent: "space-around",
          }}
        >
          {this.getItems()}
        </div>
        {this.getBottomButton(bottomButton, to)}
      </div>
    );
  }
}
