import { FunctionsError } from "@firebase/functions";
import { createRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  AuthHelpers,
  InvalidPassword,
  PasswordsDifferent,
} from "../../../BackendHelpers/auth";
import Icons from "../../../Icons/svg-icon";
import { loadingStore } from "../../../stores/LoadingStore";
import { feedback } from "../../../stores/feedbackStore";

export default function RefreshPassword() {
  const navigate = useNavigate();
  const token: string | null = new URLSearchParams(location.search).get(
    "token",
  );

  const sendButton = createRef<HTMLButtonElement>();
  const passwordRef = createRef<HTMLInputElement>();
  const passwordAgainRef = createRef<HTMLInputElement>();

  AuthHelpers.queryResetTokenStatus(token).catch(
    (e: FunctionsError | Error) => {
      if (passwordRef.current) {
        passwordRef.current.disabled = true;
      }

      if (passwordAgainRef.current) {
        passwordAgainRef.current.disabled = true;
      }

      if (sendButton.current) {
        sendButton.current.onclick = null;
      }

      if ("details" in e) {
        // @ts-expect-error firebase returns
        if (e.details?.expired) {
          feedback.push("Bu linkin süresi geçmiş.", "red", 3);
          if (sendButton.current) {
            sendButton.current.innerHTML = "Bu linkin süresi geçmiş.";
          }
        } else {
          feedback.push("Bu linkte bir problem var.", "red", 3);

          if (sendButton.current) {
            sendButton.current.innerHTML = "Bir hata oluştu.";
            sendButton.current.onclick = null;
          }
        }
      }

      if (!("details" in e)) {
        feedback.push("Bu linkte bir problem var.", "red", 3);

        if (sendButton.current) {
          sendButton.current.innerHTML = "Bir hata oluştu.";
          sendButton.current.onclick = null;
        }
      }
    },
  );

  return (
    <div className="AuthPageHierarchy" id="page">
      <div className="AuthPageBackground">
        <div className="Title">Şifre Yenileme</div>
        <div className="InputBars">
          <div>
            <div className="InputBarsRow">
              <div className="InputBar">
                Şifreniz
                <div className="Input">
                  <Icons.Password
                    className="Icon"
                    size={24}
                    fill="rgba(0, 0, 0, 0.2)"
                  ></Icons.Password>
                  <input
                    placeholder="Şifre"
                    type={"password"}
                    ref={passwordRef}
                  />
                </div>
              </div>
              <div className="InputBar">
                Şifreniz (Tekrar)
                <div className="Input">
                  <Icons.Password
                    className="Icon"
                    size={24}
                    fill="rgba(0, 0, 0, 0.2)"
                  ></Icons.Password>
                  <input
                    placeholder="Şifre"
                    type={"password"}
                    ref={passwordAgainRef}
                  />
                </div>
              </div>
            </div>
            <div className="info">
              Şifreniz en az 8 karakter olmalı, harf, rakam ve büyük harf
              içermelidir.
            </div>
          </div>
        </div>
        <div className="Buttons">
          <button
            className="Button"
            id="Red"
            ref={sendButton}
            onClick={() => {
              if (!passwordRef.current || !passwordAgainRef.current) {
                throw new Error("password refs not mounted");
              }

              loadingStore.dispatch({ type: "open" });
              AuthHelpers.resetPassword(
                token,
                passwordRef.current?.value,
                passwordAgainRef.current?.value,
              )
                .then(() => {
                  loadingStore.dispatch({ type: "close" });
                  feedback.push("Şifreniz Sıfırlandı.", "green", 3);
                  navigate("/giris-yap");
                })
                .catch((e) => {
                  loadingStore.dispatch({ type: "close" });

                  if (e instanceof PasswordsDifferent) {
                    feedback.push("Şifreler uyuşmuyor.", "red", 3);
                    return;
                  }
                  if (e instanceof InvalidPassword) {
                    feedback.push("Geçersiz şifre", "red", 3);
                    return;
                  }

                  feedback.push("Bir hata oluştu.", "red", 3);
                });
            }}
          >
            Şifremi Yenile
          </button>
        </div>
      </div>
    </div>
  );
}
