import { configureStore } from "@reduxjs/toolkit";

export const verificationStore = configureStore<{ overlayOpen: boolean }>({
  reducer: (state, action) => {
    if (action.type == "open") {
      return { overlayOpen: true };
    } else {
      return { overlayOpen: false };
    }
  },
});
