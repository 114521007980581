import "./GroupAuction.css";

import { Unsubscribe } from "firebase/auth";
import { onValue, ref } from "firebase/database";
import { Component } from "react";
import { NavLink } from "react-router-dom";
import { FirebaseProvider } from "../../BackendHelpers/firebaseProvider";
import notFoundImage from "../../Images/notFound.png";

export interface ArchiveProps {
  id: string;
  title: string;
  photo: string;
}

export interface ArchiveState {
  t: number;
  childCount: number;
  totalAmount: number;
  totalAuctionsCount: number;
  totalBidCount: number;
  photo: string;
}
export default class ArchiveGroup extends Component<
  ArchiveProps,
  ArchiveState
> {
  props: ArchiveProps;
  unsubs: Unsubscribe[] = [];

  constructor(props: ArchiveProps) {
    super(props);
    this.props = props;
    this.state = {
      childCount: 0,
      t: 0,
      totalAmount: 0,
      totalAuctionsCount: 0,
      totalBidCount: 0,
      photo: this.props.photo,
    };
  }

  componentDidMount() {
    this.unsubs.push(
      onValue(
        ref(
          FirebaseProvider.db,
          `/auctionContainers/${this.props.id}/summaryParams/childCount`,
        ),
        (snap) => {
          this.setState({ childCount: snap.val() });
        },
      ),
      onValue(
        ref(
          FirebaseProvider.db,
          `/auctionContainers/${this.props.id}/summaryParams/totalAmount`,
        ),
        (snap) => {
          this.setState({ totalAmount: snap.val() });
        },
      ),
      onValue(
        ref(
          FirebaseProvider.db,
          `/auctionContainers/${this.props.id}/summaryParams/totalAuctionsCount`,
        ),
        (snap) => {
          this.setState({ totalAuctionsCount: snap.val() });
        },
      ),
      onValue(
        ref(
          FirebaseProvider.db,
          `/auctionContainers/${this.props.id}/summaryParams/totalBidCount`,
        ),
        (snap) => {
          this.setState({ totalBidCount: snap.val() });
        },
      ),
    );
  }

  componentWillUnmount() {
    while (this.unsubs.length > 0) {
      this.unsubs.pop()!();
    }
  }

  render() {
    return (
      <div className="GroupAuction-background" {...this.props}>
        <div className="Status">
          <div
            className="Ball"
            style={{
              backgroundColor: "#D02224",
            }}
          ></div>
          Arşiv
        </div>
        <img
          src={this.state.photo ? this.state.photo : notFoundImage}
          alt=""
          className="photo-box"
          onError={() => {
            this.setState({
              photo: notFoundImage,
            });
          }}
        />
        <div className="inf-area">
          <div
            className="information-box"
            style={{ justifyContent: "space-evenly" }}
          >
            <div>
              <li id="title">{this.props.title}</li>
            </div>
            <div>
              <div>
                <li id="auction-count">
                  Grup İhale Sayısı: <li>{this.state.childCount}</li>
                </li>
              </div>
              <div>
                <li id="auction-count">
                  Açık arttırma sayısı: <li>{this.state.totalAuctionsCount}</li>
                </li>
              </div>
            </div>
            <div>
              <li id="offer-count">
                Toplam Teklif ({this.state.totalBidCount})
              </li>
              <li id="price">{this.state.totalAmount}TL</li>
            </div>
          </div>
          <NavLink to={`/arsiv/${this.props.id}`}>Arsive Git</NavLink>
        </div>
      </div>
    );
  }
}
