import { Component } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { AuthHelpers } from "../../BackendHelpers/auth";
import TitlePhoto from "../../Components/Photo/TitlePhoto";
import Icons from "../../Icons/svg-icon";
import { AreYouSure } from "../../stores/areYouSureStore";
import { feedback } from "../../stores/feedbackStore";

import "./MyAccountPage.css";

export default class MyAccountPageLayout extends Component {
  logOut() {
    AreYouSure.open(
      "Hesabınızdan çıkış yapmak istiyor musunuz?",
      () => {
        AuthHelpers.logout()
          .then(() => {
            feedback.push("Çıkış başarılı.", "#38B000", 3);
          })
          .catch((e) => {
            console.error(e);
            feedback.push(
              "Çıkış esnasında bir problem ile karşılaşıldı.",
              "#D02224",
              3,
            );
          });
      },
      null,
    );
  }

  render() {
    return (
      <div id="page">
        <TitlePhoto title="Hesabım"></TitlePhoto>
        <div className="my-account-background">
          <div className="options">
            <span className="link-title">Açık Arttırmalar</span>
            <NavLink
              replace
              to={"/hesabim/teklif-verilen-acik-arttirmalarim"}
              className="link-button"
            >
              <Icons.Report
                size={22}
                fill={
                  window.location.href.includes(
                    "teklif-verilen-acik-arttirmalarim",
                  )
                    ? "#FFFFFF"
                    : "#6A696C"
                }
              ></Icons.Report>
              Teklif Verdiğim Açık Arttırmalar
            </NavLink>
            <NavLink
              replace
              to={"/hesabim/devam-eden-acik-arttirmalarim"}
              className="link-button"
            >
              <Icons.TimeCircle
                size={22}
                fill={
                  window.location.href.includes("devam-eden-acik-arttirmalarim")
                    ? "#FFFFFF"
                    : "#6A696C"
                }
              ></Icons.TimeCircle>
              Devam Eden Açık Arttırmalarım
            </NavLink>
            <NavLink
              replace
              to={"/hesabim/kazanan-arttirmalarim"}
              className="link-button"
            >
              <Icons.Secure
                size={22}
                fill={
                  window.location.href.includes("kazanan-arttirmalarim")
                    ? "#FFFFFF"
                    : "#6A696C"
                }
              ></Icons.Secure>
              Kazanan Açık Arttırmalarım
            </NavLink>
            <NavLink
              replace
              to={"/hesabim/kaybeden-arttirmalarim"}
              className="link-button"
            >
              <Icons.InSecure
                size={22}
                fill={
                  window.location.href.includes("kaybeden-arttirmalarim")
                    ? "#FFFFFF"
                    : "#6A696C"
                }
              ></Icons.InSecure>
              Kaybeden Açık Arttırmalarım
            </NavLink>
            <NavLink
              replace
              to={"/hesabim/acik-arttirma-ayarlari"}
              className="link-button"
            >
              <Icons.Compass
                size={22}
                fill={
                  window.location.href.includes("acik-arttirma-ayarlari")
                    ? "#FFFFFF"
                    : "#6A696C"
                }
              ></Icons.Compass>
              Ayarlar
            </NavLink>
            <span className="link-title">Diğer</span>
            <NavLink to={"/guvercinlerim"} className="link-button">
              <Icons.TicketStar
                size={22}
                fill={
                  window.location.href.includes("katilimcilar")
                    ? "#FFFFFF"
                    : "#6A696C"
                }
              ></Icons.TicketStar>
              Güvercinlerim
            </NavLink>
            <NavLink replace to={"/hesabim/bilgilerim"} className="link-button">
              <Icons.User
                size={22}
                fill={
                  window.location.href.includes("bilgilerim")
                    ? "#FFFFFF"
                    : "#6A696C"
                }
              ></Icons.User>
              Kullanıcı Bilgilerim
            </NavLink>
            <NavLink
              replace
              to={"/hesabim/sifre-yenile"}
              className="link-button"
            >
              <Icons.Password
                size={22}
                fill={
                  window.location.href.includes("sifre-yenile")
                    ? "#FFFFFF"
                    : "#6A696C"
                }
              ></Icons.Password>
              Şifre Yenile
            </NavLink>
            <NavLink to={"/iletisim"} className="link-button">
              <Icons.Calling
                size={22}
                fill={
                  window.location.href.includes("iletisim")
                    ? "#FFFFFF"
                    : "#6A696C"
                }
              ></Icons.Calling>
              İletişim
            </NavLink>
            <button
              className="link-button"
              onClick={() => {
                this.logOut();
              }}
            >
              <Icons.LogOut
                size={22}
                fill={
                  window.location.href.includes("centaur-games")
                    ? "#FFFFFF"
                    : "#6A696C"
                }
              ></Icons.LogOut>
              Çıkış Yap
            </button>
          </div>
          <hr />
          <div className="content">
            <Outlet></Outlet>
          </div>
        </div>
      </div>
    );
  }
}
