import { Unsubscribe } from "firebase/auth";
import { Component, createRef } from "react";
import { NavLink, Navigate } from "react-router-dom";
import {
  AuthHelpers,
  CityInvalid,
  DobInvalid,
  InvalidNewEmail,
  InvalidPassword,
  MailAlreadyInUse,
  PhoneNumberInvalid,
  PhoneNumberTaken,
  ProvinceInvalid,
  RegistrationFailed,
  UserDataPersonalInfo,
  UserdataAlreadyCreated,
  UsernameInvalid,
  UsernameTaken,
} from "../../../BackendHelpers/auth";
import { FirebaseProvider } from "../../../BackendHelpers/firebaseProvider";
import { MFAError, PendingMFAError } from "../../../BackendHelpers/mfa";
import Icons from "../../../Icons/svg-icon";
import { loadingStore } from "../../../stores/LoadingStore";
import { feedback } from "../../../stores/feedbackStore";

export const phoneNumberMatcher =
  /^\+90(501|505|506|507|551|552|553|554|555|559|516|530|531|532|533|534|535|536|537|538|539|561|541|542|543|544|545|546|547|548|549)\d{7}$/;
export const passwordMatcher = /^[\w[\]!"#$%&'()*+,./:;<=>?@\\^_`{|}~]{6,}$/;
export const mailAddressMatch = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const dobMatcher =
  /^(19|20){1}\d{2}-(0[123456789]|1[012]{1})-(0[123456789]|1\d|2\d|3(0|1))$/;
export const usernameMatcher = /^[\w.-]{4,12}$/;
export const nameMatcher =
  /^(?:\p{Lu}\p{Ll}{1,39})(?:\s\p{Lu}\p{Ll}{1,39}){1,2}$/u;
export const longNameMatcher = // 8 sections
  /^(?:\p{Lu}\p{Ll}{1,39})(?:\s\p{Lu}\p{Ll}{1,39}){0,8}$/u;

export const titleCaseFunc = (val: string): string => {
  let o = "";
  const reg = /\s/u;
  for (let i = 0; i < val.length; i++) {
    const lower = val[i] == val[i].toLowerCase();
    const whitespace = reg.test(val[i]);
    const prevwhite = i == 0 || reg.test(val[i - 1]);

    if (prevwhite && whitespace) {
      continue;
    } else if (prevwhite && lower) {
      o += val[i].toUpperCase();
    } else {
      o += val[i];
    }
  }

  return o;
};

export class RegisterPage extends Component {
  userData: UserDataPersonalInfo = {
    city: "",
    dateOfBirth: "",
    mailAddress: null,
    fullname: "",
    phoneNumber: "",
    province: "",
    username: "",
  };

  password = "";
  passwordAgain = "";

  _authStateUnsubscriber: Unsubscribe | undefined;
  state: {
    mailaddr: string;
    phone: string;
  };

  constructor(props: object) {
    super(props);
    this.state = {
      mailaddr: "",
      phone: "",
    };
  }

  mailAddrInput = createRef<HTMLDivElement>();
  mailInfo = createRef<HTMLDivElement>();
  passwordInfo = createRef<HTMLDivElement>();
  passwordInput = createRef<HTMLDivElement>();
  passwordAgainInput = createRef<HTMLDivElement>();
  usernameInput = createRef<HTMLDivElement>();
  usernameInfo = createRef<HTMLDivElement>();
  nameInput = createRef<HTMLDivElement>();
  dobInput = createRef<HTMLDivElement>();
  phoneNumberInput = createRef<HTMLDivElement>();
  phoneNumberInfo = createRef<HTMLDivElement>();

  /**
   * called when component mount.
   * Sets up the auth state listener.
   */
  componentDidMount() {
    this._authStateUnsubscriber = FirebaseProvider.auth.onAuthStateChanged(
      async (user) => {
        this.userData.mailAddress = user?.email ?? null;
        if (this.mailAddrInputBar.current) {
          this.mailAddrInputBar.current.value = this.userData.mailAddress ?? "";
        }

        if (user) {
          if (this.userData.mailAddress != user.email) {
            localStorage.removeItem("lastRegisterInfo");
            this.userData = {
              mailAddress: user.email,
            } as UserDataPersonalInfo;
          }

          this.userData.phoneNumber = user.phoneNumber
            ? user.phoneNumber
            : (await AuthHelpers.getRegisterPhone(user.uid)) ?? "";
          if (this.phoneNumberInputBar.current) {
            this.phoneNumberInputBar.current.value = this.userData.phoneNumber;
          }
        }

        this.setState({
          mailaddr: user?.email
            ? user.email.endsWith("derby10arena.com")
              ? null
              : user.email
            : null,
          phone: this.userData.phoneNumber,
        });
      },
    );

    const registerInfo = localStorage.getItem("lastRegisterInfo");

    if (this.phoneNumberInputBar.current) {
      this.phoneNumberInputBar.current.value = "+90";
    }

    if (registerInfo !== null) {
      const registerInfoParsed = JSON.parse(registerInfo);
      this.userData = {
        ...this.userData,
        ...registerInfoParsed,
      };

      if (this.userNameInputBar.current) {
        this.userNameInputBar.current.value = this.userData.username;
      }
      if (this.nameSurnameInputBar.current) {
        this.nameSurnameInputBar.current.value = this.userData.fullname;
      }
      if (this.provinceInputBar.current) {
        this.provinceInputBar.current.value = this.userData.province;
      }
      if (this.cityInputBar.current) {
        this.cityInputBar.current.value = this.userData.city;
      }
    }
  }

  /**
   * called on component unmount.
   * unsubscribes authstate listener.
   */
  componentWillUnmount(): void {
    if (this._authStateUnsubscriber) {
      this._authStateUnsubscriber();
    }
  }

  /**
   * called when the user wants to start the registration operations
   */
  async onRegisterPressed() {
    try {
      this.doInputValidation();
    } catch {
      return;
    }

    this.doPendingFeedback(
      "Kayıt işlemi gerçekleştiriliyor.",
      "Lütfen bekleyiniz.",
    );

    try {
      await AuthHelpers.register(this.password, this.userData);
    } catch (e) {
      if (e instanceof InvalidNewEmail) {
        this.doMailAddressErrorFeedback("Girilen mail adresi geçersizdir.");
      } else if (e instanceof DobInvalid) {
        this.doErrorFeedback("Kayıt başarısız", "Doğum tarihi geçersiz.");
      } else if (e instanceof UsernameInvalid) {
        this.doUsernameErrorFeedback("Kullanıcı adı geçersiz.");
      } else if (e instanceof PhoneNumberInvalid) {
        this.doPhoneNumberErrorFeedback("Telefon numarası geçersiz.");
      } else if (e instanceof ProvinceInvalid) {
        this.doErrorFeedback("Kayıt başarısız.", "İl geçersiz");
      } else if (e instanceof CityInvalid) {
        this.doErrorFeedback("Kayıt başarısız.", "İlçe geçersiz");
      } else if (e instanceof InvalidPassword) {
        this.doPasswordErrorFeedback("Girilen şifre geçersizdir.");
      } else if (e instanceof MailAlreadyInUse) {
        this.doMailAddressErrorFeedback(
          "Girilen Mail başka bir kullanıcı tarafından kullanılıyor.",
        );
      } else if (e instanceof PhoneNumberTaken) {
        this.doPhoneNumberErrorFeedback(
          "Girilen Telefon numarası başka bir kullanıcı tarafından kullanılıyor.",
        );
      } else if (e instanceof UsernameTaken) {
        this.doUsernameErrorFeedback(
          "Girilen kullanıcı adı başka bir kullanıcı tarafından kullanılıyor.",
        );
      } else if (e instanceof UserdataAlreadyCreated) {
        this.doErrorFeedback(
          "Kayıt Başarısız",
          "Kullanıcı bilgisi oluşturulmuş.",
        );
      } else if (e instanceof PendingMFAError) {
        this.doErrorFeedback(
          "Kayıt Başarısız",
          "Şu anda bir doğrulama gerçekleşmekte, birazdan tekrar deneyin.",
        );
      } else if (e instanceof MFAError) {
        this.doErrorFeedback("Kayıt Başarısız", "İki faktör doğrulama hatası.");
      } else if (e instanceof RegistrationFailed) {
        this.doErrorFeedback("Kayıt Başarısız", "Kayıt başarısız oldu.");
      } else {
        this.doErrorFeedback("Kayıt Başarısız", "Kayıt başarısız oldu.");
      }

      loadingStore.dispatch({ type: "close" });

      return;
    }

    this.doSuccessFeedback("Giriş başarılı.", "Kayıt başarılı.");

    if (this._authStateUnsubscriber) {
      this._authStateUnsubscriber();
    }

    this.setState({ phone: "a" });
  }

  /**
   * checks the inputs for format compliance.
   */
  doInputValidation() {
    this.clearFormErrors();

    if (!phoneNumberMatcher.test(this.userData.phoneNumber)) {
      this.doPhoneNumberErrorFeedback(
        "Telefon numarası yanlış, +905xxxxxxxxx şeklinde olmalıdır.",
      );
      throw new ParamValidationFailed();
    }

    if (!FirebaseProvider.auth.currentUser) {
      if (this.password !== this.passwordAgain) {
        this.doVerifyPasswordFeedback("Şifreler uyuşmuyor.");
        throw new ParamValidationFailed();
      }

      if (!passwordMatcher.test(this.password)) {
        this.doPasswordErrorFeedback(
          "Şifreniz en az altı karakter uzunluğunda olmalıdır.",
        );
        throw new ParamValidationFailed();
      }
    }

    if (!usernameMatcher.test(this.userData.username)) {
      this.doUsernameErrorFeedback("Girilen kullanıcı adı geçersizdir.");
      throw new ParamValidationFailed();
    }

    if (!nameMatcher.test(this.userData.fullname)) {
      feedback.push("İsim soyisim geçersiz.", "red", 3);
      throw new ParamValidationFailed();
    }

    if (this.userData.mailAddress) {
      if (!mailAddressMatch.test(this.userData.mailAddress)) {
        this.doMailAddressErrorFeedback("Mail adresi geçerli değil.");
        throw new ParamValidationFailed();
      }
    }

    if (!dobMatcher.test(this.userData.dateOfBirth)) {
      feedback.push("Doğum tarihi geçersiz", "red", 3);
      throw new ParamValidationFailed();
    }

    if (!longNameMatcher.test(this.userData.province)) {
      feedback.push("İl Geçersiz.", "red", 3);
      throw new ParamValidationFailed();
    }

    if (!longNameMatcher.test(this.userData.city)) {
      feedback.push("İlçe geçersiz", "red", 3);
      throw new ParamValidationFailed();
    }
  }

  /**
   * Show a password error feedback message on the password input.
   * called by the doInputValidation when
   * a passwords typing is wrong
   * @param {string} message message to show
   */
  doPasswordErrorFeedback(message: string) {
    if (this.passwordInfo.current && this.passwordInput.current) {
      this.passwordInfo.current.innerHTML = message;
      this.passwordInfo.current.style.color = "red";
      this.passwordInput.current.style.boxShadow = "0px 0px 5px rgb(255 0 0)";
    }
  }

  /**
   * Show a password confirmation error feedback message on the confirmation input
   * called by the doInputValidation when passwordAgain
   * input does not match with the password input.
   * @param {string} message the message to show.
   */
  doVerifyPasswordFeedback(message: string) {
    if (this.passwordInfo.current && this.passwordAgainInput.current) {
      this.passwordInfo.current.innerHTML = message;
      this.passwordInfo.current.style.color = "red";
      this.passwordAgainInput.current.style.boxShadow =
        "0px 0px 5px rgb(255 0 0)";
    }
  }

  /**
   * Show a phone number format error feedback message on the phonenumber input
   * called by the doInputValidation when phonenumber input does not conform to E.164 format
   * @param {string} message message to show
   */
  doPhoneNumberErrorFeedback(message: string) {
    if (this.phoneNumberInfo.current && this.phoneNumberInput.current) {
      this.phoneNumberInfo.current.style.display = "block";
      this.phoneNumberInfo.current.innerHTML = message;
      this.phoneNumberInfo.current.style.color = "red";
      this.phoneNumberInput.current.style.boxShadow =
        "0px 0px 5px rgb(255 0 0)";
    }
  }

  /**
   * Show a email format or existance error feedback message on the email input
   * called by the doInputValidation when mail input does not conform to the format.
   * @param {string} message message to show
   */
  doMailAddressErrorFeedback(message: string) {
    if (this.mailAddrInput.current && this.mailInfo.current) {
      this.mailInfo.current.style.display = "block";
      this.mailInfo.current.innerHTML = message;
      this.mailInfo.current.style.color = "red";
      this.mailAddrInput.current.style.boxShadow = "0px 0px 5px rgb(255 0 0)";
    }
  }

  /**
   * Show a username format or existance error feedback message on the username input
   * called by the doInputValidation when username input does not conform to the format
   * @param {string} message message to show
   */
  doUsernameErrorFeedback(message: string) {
    if (this.usernameInfo.current && this.usernameInput.current) {
      this.usernameInfo.current.innerHTML = message;
      this.usernameInfo.current.style.color = "red";
      this.usernameInput.current.style.boxShadow = "0px 0px 5px rgb(255 0 0)";
    }
  }

  /**
   * clears all form error feedbacks to initial values
   */
  clearFormErrors() {
    if (this.mailAddrInput.current && this.mailInfo.current) {
      this.mailInfo.current.style.display = "none";
      this.mailInfo.current.innerHTML = "";
      this.mailInfo.current.style.color = "inherit";
      this.mailAddrInput.current.style.boxShadow = "inherit";
    }

    if (this.passwordInfo.current && this.passwordInput.current) {
      this.passwordInfo.current.innerHTML =
        "Şifreniz en az 6 karakter olmalıdır";
      this.passwordInfo.current.style.color = "inherit";
      this.passwordInput.current.style.boxShadow = "inherit";
    }

    if (this.passwordAgainInput.current) {
      this.passwordAgainInput.current.style.boxShadow = "inherit";
    }

    if (this.phoneNumberInfo.current && this.phoneNumberInput.current) {
      this.phoneNumberInfo.current.style.display = "none";
      this.phoneNumberInfo.current.innerHTML = "";
      this.phoneNumberInfo.current.style.color = "inherit";
      this.phoneNumberInput.current.style.boxShadow = "inherit";
    }

    if (this.usernameInfo.current && this.usernameInput.current) {
      this.usernameInfo.current.innerHTML =
        "Kullanıcı adınız en fazla 12 karakter içerebilir.";
      this.usernameInfo.current.style.color = "inherit";
      this.usernameInput.current.style.boxShadow = "inherit";
    }
  }

  /**
   * Called when a register operation starts.
   * locks the inputs and shows a user that the register operation is pending.
   * @param {string} title the title to show
   * @param {string} message message to shows
   */
  doPendingFeedback(title: string, message: string) {
    loadingStore.dispatch({ type: "open" });
  }

  /**
   * Called when a register operation fails with an error.
   * if there was a pendingFeedback shown clears it and Unlocks the ui
   * @param {string} title the title to show
   * @param {string} message the message to show
   */
  doErrorFeedback(title: string, message: string) {
    feedback.push(message, "red", 3);
    loadingStore.dispatch({ type: "close" });
  }

  /**
   * Called when a register operation is successful.
   * if there was a pendingFeedback shown clears it and Unlocks the ui
   * @param {string} title the title to show
   * @param {string} message the message to show
   */
  doSuccessFeedback(title: string, message: string) {
    feedback.push(message, "green", 3);
    loadingStore.dispatch({ type: "close" });
  }

  _getMailInput(isAccountAlreadyCreated: boolean) {
    if (isAccountAlreadyCreated) {
      return (
        <input
          placeholder="E-Posta"
          ref={this.mailAddrInputBar}
          type={"email"}
          value={this.state.mailaddr}
          disabled={isAccountAlreadyCreated}
        />
      );
    }

    return (
      <input
        placeholder="E-Posta"
        ref={this.mailAddrInputBar}
        type={"email"}
        onChange={(event) => {
          this.userData.mailAddress = event.target.value
            ? event.target.value
            : null;
        }}
      />
    );
  }

  _getPhoneNumberInput() {
    return (
      <input
        placeholder="+90"
        type={"tel"}
        ref={this.phoneNumberInputBar}
        onChange={(event) => {
          let val = event.target.value
            .replaceAll(" ", "")
            .replace(/[^0-9]/g, "");

          let i = 0;
          if (!val.startsWith("+90")) {
            for (const c of ["+", "9", "0"]) {
              if (c == val[i]) {
                i++;
              }
              if (i >= val.length || i >= 3) {
                break;
              }
            }
            val = "+90" + val.substring(i);
          }

          if (this.phoneNumberInputBar.current) {
            this.phoneNumberInputBar.current.value = val;
          }
          this.userData.phoneNumber = val;
        }}
      />
    );
  }

  _getPasswordInput(isAccountAlreadyCreated: boolean) {
    if (isAccountAlreadyCreated) {
      return (
        <input
          placeholder="Şifre"
          type={"password"}
          value="******"
          disabled={isAccountAlreadyCreated}
        />
      );
    } else {
      return (
        <input
          placeholder="Şifre"
          type={"password"}
          onChange={(event) => {
            this.password = event.target.value;
          }}
        />
      );
    }
  }

  _getPasswordAgainInput(isAccountAlreadyCreated: boolean) {
    if (isAccountAlreadyCreated) {
      return (
        <input
          placeholder="Şifre"
          type={"password"}
          value="******"
          disabled={isAccountAlreadyCreated}
        />
      );
    } else {
      return (
        <input
          placeholder="Şifre"
          type={"password"}
          onChange={(event) => {
            this.passwordAgain = event.target.value;
          }}
        />
      );
    }
  }

  userNameInputBar = createRef<HTMLInputElement>();
  nameSurnameInputBar = createRef<HTMLInputElement>();
  phoneNumberInputBar = createRef<HTMLInputElement>();
  mailAddrInputBar = createRef<HTMLInputElement>();
  provinceInputBar = createRef<HTMLInputElement>();
  cityInputBar = createRef<HTMLInputElement>();

  render() {
    console.log(this.userData);
    console.log(this.state);
    const isAccountAlreadyCreated =
      this.userData.mailAddress !== null && this.state.phone !== null;

    if (FirebaseProvider.auth.currentUser?.phoneNumber) {
      // TODO: might be triggered on successful registers.
      // and this error might pop up due to that. maybe remove?
      return <Navigate to="/" />;
    }

    return (
      <div className="AuthPageHierarchy">
        <div className="AuthPageBackground">
          <div className="Title">
            {isAccountAlreadyCreated ? "Kaydınızı Tamamlayın" : "Kayıt Ol"}
          </div>
          <div className="InputBars">
            <div>
              <div className="InputBar">
                Telefon Numaranız
                <div className="Input" ref={this.phoneNumberInput}>
                  <Icons.Telephone
                    className="Icon"
                    size={24}
                    fill="rgba(0, 0, 0, 0.2)"
                  ></Icons.Telephone>
                  {this._getPhoneNumberInput()}
                </div>
                <div
                  className="info"
                  ref={this.phoneNumberInfo}
                  style={{ display: "none" }}
                ></div>
              </div>
            </div>
            <div>
              <div className="InputBarsRow">
                <div className="InputBar">
                  Şifreniz
                  <div className="Input" ref={this.passwordInput}>
                    <Icons.Password
                      className="Icon"
                      size={24}
                      fill="rgba(0, 0, 0, 0.2)"
                    ></Icons.Password>
                    {this._getPasswordInput(isAccountAlreadyCreated)}
                  </div>
                </div>
                <div className="InputBar">
                  Şifreniz (Tekrar)
                  <div className="Input" ref={this.passwordAgainInput}>
                    <Icons.Password
                      className="Icon"
                      size={24}
                      fill="rgba(0, 0, 0, 0.2)"
                    ></Icons.Password>
                    {this._getPasswordAgainInput(isAccountAlreadyCreated)}
                  </div>
                </div>
              </div>
              <div className="info" ref={this.passwordInfo}>
                Şifreniz en az 6 karakter olmalıdır.
              </div>
            </div>
            <div>
              <div className="InputBar">
                Kullanıcı Adınız
                <div className="Input" ref={this.usernameInput}>
                  <Icons.MultipleUser
                    className="Icon"
                    size={24}
                    fill="rgba(0, 0, 0, 0.2)"
                  ></Icons.MultipleUser>
                  <input
                    placeholder="Kullanıcı Adı"
                    type={"text"}
                    ref={this.userNameInputBar}
                    onChange={(event) => {
                      let val = event.target.value;

                      val = val.substring(0, 12);

                      val = val.toLowerCase();

                      val = val.replaceAll(" ", "_");

                      val = val.replaceAll("ı", "i");
                      val = val.replaceAll("ö", "o");
                      val = val.replaceAll("ü", "u");
                      val = val.replaceAll("ğ", "g");
                      val = val.replaceAll("ş", "s");
                      val = val.replaceAll("ç", "c");

                      val = val.replaceAll(".", "");
                      val = val.replaceAll(",", "");

                      this.userData.username = val;
                      if (this.userNameInputBar.current) {
                        this.userNameInputBar.current.value = val;
                      }
                    }}
                  />
                </div>
              </div>
              <div className="info" ref={this.usernameInfo}>
                Kullanıcı adınız en fazla 12 karakter içerebilir.
              </div>
            </div>
            <div className="InputBar">
              Adınız Soyadınız
              <div className="Input" ref={this.nameInput}>
                <Icons.User
                  className="Icon"
                  size={24}
                  fill="rgba(0, 0, 0, 0.2)"
                ></Icons.User>
                <input
                  placeholder="Ad Soyad"
                  ref={this.nameSurnameInputBar}
                  onBlur={(event) => {
                    if (this.nameSurnameInputBar.current) {
                      this.userData.fullname =
                        this.nameSurnameInputBar.current.value =
                          event.target.value.trim();
                    }
                  }}
                  onChange={(event) => {
                    if (this.nameSurnameInputBar.current) {
                      this.userData.fullname =
                        this.nameSurnameInputBar.current.value = titleCaseFunc(
                          event.target.value,
                        );
                    }
                  }}
                />
              </div>
            </div>
            <div className="InputBarsRow">
              <div className="InputBar">
                Doğum Tarihiniz
                <div className="Input" ref={this.dobInput}>
                  <Icons.Date
                    className="Icon"
                    size={24}
                    fill="rgba(0, 0, 0, 0.2)"
                  ></Icons.Date>
                  <input
                    placeholder="00/00/0000"
                    type={"date"}
                    onChange={(event) => {
                      this.userData.dateOfBirth = event.target.value;
                    }}
                  />
                </div>
              </div>
              <div className="InputBar">
                E-Posta Adresiniz (İstege Bağlı)
                <div className="Input" ref={this.mailAddrInput}>
                  <Icons.Mail
                    className="Icon"
                    size={24}
                    fill="rgba(0, 0, 0, 0.2)"
                  ></Icons.Mail>
                  {this._getMailInput(isAccountAlreadyCreated)}
                </div>
                <div
                  className="info"
                  ref={this.mailInfo}
                  style={{ display: "none" }}
                ></div>
              </div>
            </div>
            <div className="InputBarsRow">
              <div className="InputBar">
                İl
                <div className="Input">
                  <Icons.Location
                    className="Icon"
                    size={24}
                    fill="rgba(0, 0, 0, 0.2)"
                  ></Icons.Location>
                  <input
                    placeholder="İl"
                    ref={this.provinceInputBar}
                    onBlur={(event) => {
                      if (this.provinceInputBar.current) {
                        this.userData.province =
                          this.provinceInputBar.current.value =
                            event.target.value.trim();
                      }
                    }}
                    onChange={(event) => {
                      if (this.provinceInputBar.current) {
                        this.userData.province =
                          this.provinceInputBar.current.value = titleCaseFunc(
                            event.target.value,
                          );
                      }
                    }}
                  />
                </div>
              </div>
              <div className="InputBar">
                İlçe
                <div className="Input">
                  <Icons.Compass
                    className="Icon"
                    size={24}
                    fill="rgba(0, 0, 0, 0.2)"
                  ></Icons.Compass>
                  <input
                    placeholder="İlçe"
                    ref={this.cityInputBar}
                    onBlur={(event) => {
                      if (this.cityInputBar.current) {
                        this.userData.city = this.cityInputBar.current.value =
                          event.target.value.trim();
                      }
                    }}
                    onChange={(event) => {
                      if (this.cityInputBar.current) {
                        this.userData.city = this.cityInputBar.current.value =
                          titleCaseFunc(event.target.value);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="Buttons">
            <button
              className="Button"
              id="Red"
              onClick={() => this.onRegisterPressed()}
            >
              Kayıt Ol
            </button>
            <NavLink to={"/giris-yap"} className="Button" id="Black">
              Zaten Hesabım Var
            </NavLink>
            <NavLink to={"/iletisim"} className="Button" id="Black">
              İletişim
            </NavLink>
          </div>
        </div>
        <div className="Information">
          Kişisel verileriniz,{" "}
          <NavLink to={"/kurumsal/gizlilik-politikasi"}>
            Gizlilik Politikası
          </NavLink>{" "}
          kapsamında işlenmektedir. “Giriş Yap” veya “Kayıt Ol” butonlarından
          birine basarak{" "}
          <NavLink to={"/kurumsal/kullanim-kosullari"}>
            Kullanıcı Sözleşmesi
          </NavLink>
          ’ni ve{" "}
          <NavLink to={"/kurumsal/cerez-politikasi"}>Çerez Politikası</NavLink>
          ’nı okuduğunuzu ve kabul ettiğinizi onaylıyorsunuz.
        </div>
      </div>
    );
  }
}

export default RegisterPage;

class ParamValidationFailed extends Error {}
