import { Unsubscribe, onValue, ref } from "firebase/database";
import React, { Component } from "react";
import { useParams } from "react-router-dom";
import { FirebaseProvider } from "../../BackendHelpers/firebaseProvider";
import {
  ENDPOINT_BASE,
  _PagedResource,
} from "../../BackendHelpers/pagedResource";
import Auction, {
  dateConverter,
  getStatusColor,
  getStatusText,
} from "../../Components/Auction/Auction";
import GroupAuction from "../../Components/Auction/GroupAuction";
import { GalleryPhoto } from "../../Components/Photo/Photo";
import notFoundImage from "../../Images/notFound.png";
import { AuctionData, GroupAuctionData } from "./AuctionsLayout";
import "./GroupAuctionPage.css";

interface Props {
  id?: string;
  archival?: boolean;
}

interface State {
  name: string;
  childCount: number;
  totalBidCount: number;
  totalAuctionsCount: number;
  totalAmount: number;
  description: string;
  expiry: number;
  photo: string;
  isFinalContainer: boolean;
  loadedItems: (AuctionData | GroupAuctionData)[];
  pageError?: unknown;
}

export default function GroupAuctionPage(prop: Props) {
  const { id } = useParams();

  return id ? (
    <_GroupAuctionPage {...prop} id={id} key={id}></_GroupAuctionPage>
  ) : null;
}

const itemsMngr: _PagedResource<AuctionData | GroupAuctionData> =
  new _PagedResource(`${ENDPOINT_BASE}/auctionQuery.php`, 20);
const _loadedItems: (AuctionData | GroupAuctionData)[] = [];
let mngrLastParent: string | undefined;

export class _GroupAuctionPage extends Component<Props, State> {
  loading = false;
  finished = false;
  itemsRef = React.createRef<HTMLDivElement>();
  listenerCancellers: Unsubscribe[] = [];
  interval: NodeJS.Timer | null = null;
  scrollPoller: NodeJS.Timer | undefined;

  constructor(props: Props) {
    super(props);
    this.state = {
      name: "",
      childCount: 0,
      totalAuctionsCount: 0,
      totalBidCount: 0,
      totalAmount: 0,
      description: "yükleniyor...",
      expiry: 0,
      photo: "",
      isFinalContainer: true,
      loadedItems: _loadedItems,
    };
  }

  componentDidMount(): void {
    this.load();

    if (this.interval) clearInterval(this.interval);
    this.interval = setInterval(() => {
      this.setState({});
    }, 1000);
  }

  componentWillUnmount(): void {
    if (this.interval) clearInterval(this.interval);

    if (this.scrollPoller) {
      clearInterval(this.scrollPoller);
      this.scrollPoller = undefined;
    }

    for (const x of Object.values(this.listenerCancellers)) {
      x();
    }
  }

  load() {
    this.listenerCancellers.push(
      onValue(
        ref(FirebaseProvider.db, `auctionContainers/${this.props.id}/name`),
        (snap) =>
          this.setState({
            name: snap.val(),
          }),
        { onlyOnce: true },
      ),
      onValue(
        ref(
          FirebaseProvider.db,
          `auctionContainers/${this.props.id}/summaryParams/childCount`,
        ),
        (snap) =>
          this.setState({
            childCount: snap.val(),
          }),
      ),
      onValue(
        ref(
          FirebaseProvider.db,
          `auctionContainers/${this.props.id}/summaryParams/totalBidCount`,
        ),
        (snap) =>
          this.setState({
            totalBidCount: snap.val(),
          }),
      ),
      onValue(
        ref(
          FirebaseProvider.db,
          `auctionContainers/${this.props.id}/summaryParams/totalAuctionsCount`,
        ),
        (snap) =>
          this.setState({
            totalAuctionsCount: snap.val(),
          }),
      ),
      onValue(
        ref(
          FirebaseProvider.db,
          `auctionContainers/${this.props.id}/summaryParams/totalAmount`,
        ),
        (snap) =>
          this.setState({
            totalAmount: snap.val(),
          }),
      ),
      onValue(
        ref(
          FirebaseProvider.db,
          `auctionContainers/${this.props.id}/description`,
        ),
        (snap) =>
          this.setState({
            description: snap.val(),
          }),
      ),
      onValue(
        ref(
          FirebaseProvider.db,
          `auctionContainers/${this.props.id}/summaryParams/expiry`,
        ),
        (snap) =>
          this.setState({
            expiry: snap.val(),
          }),
      ),
      onValue(
        ref(FirebaseProvider.db, `auctionContainers/${this.props.id}/photo`),
        (snap) =>
          this.setState({
            photo: snap.val(),
          }),
      ),
      onValue(
        ref(
          FirebaseProvider.db,
          `auctionContainers/${this.props.id}/isFinalContainer`,
        ),
        (snap) => {
          itemsMngr.ENDPOINT = snap.val()
            ? `${ENDPOINT_BASE}/auctionQuery.php`
            : `${ENDPOINT_BASE}/auctionContainerQuery.php`;
          this.setState({ isFinalContainer: snap.val() });

          if (!this.scrollPoller) {
            this.scrollPoller = setInterval(this.loadNextIfNecessary, 100);
          }
        },
      ),
    );
  }

  loadNextIfNecessary = () => {
    if (this.itemsRef.current) {
      const itemsRect = this.itemsRef.current?.getBoundingClientRect();

      if (
        itemsRect.bottom < window.innerHeight &&
        !this.loading &&
        !this.finished
      ) {
        this.loading = true;
        itemsMngr
          .loadNextPage({
            0: { op: "where" },
            parent: {
              op: "qparam",
              v: this.state.isFinalContainer ? "`auctions`.`parent`" : "parent",
            },
            yx0parent1: { op: "e" },
            yx0parent2: { op: "qmark", t: "s", v: this.props.id },
          })
          .then((v) => {
            if (v.length < itemsMngr.CNT) {
              this.finished = true;
              clearInterval(this.scrollPoller);
              this.scrollPoller = undefined;
            }

            _loadedItems.push(...v);

            this.setState(
              {
                loadedItems: _loadedItems,
              },
              () => {
                this.loading = false;
              },
            );
          })
          .catch((e) => {
            console.log(e);
            this.setState({ pageError: e });
            clearInterval(this.scrollPoller);
            this.scrollPoller = undefined;

            this.loading = false;
          });
      }
    }
  };

  reloadParent() {
    itemsMngr.setWindowToStart();
    this.finished = false;
    _loadedItems.length = 0;
    mngrLastParent = this.props.id;
    this.setState({ loadedItems: [] });
  }

  render() {
    if (this.props.id !== mngrLastParent) {
      this.reloadParent();
      return;
    }

    return (
      <div className="group-auction-page-background">
        <div className="top-area">
          <div style={{ flex: 1 }}>
            <GalleryPhoto
              className="main-image"
              src={this.state.photo ? this.state.photo : notFoundImage}
              onError={() => {
                this.setState({ photo: notFoundImage });
              }}
            />
          </div>
          <div className="informations">
            <div className="status">
              <div
                className="status-balloon"
                style={{
                  backgroundColor: getStatusColor(
                    this.state.expiry - new Date().getTime(),
                  ),
                }}
              />{" "}
              {getStatusText(this.state.expiry - new Date().getTime())}
            </div>
            <span className="title">{this.state.name}</span>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "3%",
                marginTop: "25px",
              }}
            >
              <div className="info-box">
                <span className="info-title">
                  Toplam Teklif ({this.state.totalBidCount})
                </span>
                <span className="description-text">
                  {this.state.totalAmount} TL
                </span>
              </div>
              <div className="info-box">
                {!this.props.archival ? (
                  <div>
                    <span className="info-title">Bitmesine Kalan</span>
                    <br />
                    <span className="description-text">
                      {dateConverter(this.state.expiry)}
                    </span>
                  </div>
                ) : (
                  <div>
                    <span className="info-title">
                      Grup İhale Sayısı:{" "}
                      <strong>{this.state.childCount}</strong>
                    </span>
                    <br />
                    <span className="info-title">
                      Açık Arttırma Sayısı:{" "}
                      <strong>{this.state.totalAuctionsCount}</strong>
                    </span>
                  </div>
                )}
              </div>
              <div className="info-box-flex"></div>
            </div>
            <span className="explanation">{this.state.description}</span>
          </div>
        </div>
        <div
          className="items"
          ref={this.itemsRef}
          style={{ paddingTop: "50px" }}
        >
          {!this.state.pageError ? (
            _loadedItems.map((v) =>
              this.state.isFinalContainer ? (
                <Auction
                  key={(v as AuctionData)["`auctions`.`id`"]}
                  auctionId={(v as AuctionData)["`auctions`.`id`"]}
                  name={(v as AuctionData)["`auctions`.`name`"]}
                  ownerName={(v as AuctionData)["`users`.`fullname`"]}
                />
              ) : (
                <GroupAuction
                  id={(v as GroupAuctionData).id}
                  title={(v as GroupAuctionData).name}
                  key={(v as GroupAuctionData).id}
                  photo={(v as GroupAuctionData).photo}
                ></GroupAuction>
              ),
            )
          ) : (
            <div>Bir Hata Oluştu</div>
          )}
        </div>
      </div>
    );
  }
}
