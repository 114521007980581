import { Buffer } from "buffer";

export class KeyHelper {
  static cachedKey: CryptoKeyPair | undefined;
  static keyParams = {
    name: "ECDSA",
    namedCurve: "P-521",
  };

  static async generateSigningKey() {
    this.cachedKey = await window.crypto.subtle.generateKey(
      this.keyParams,
      false,
      ["sign", "verify"],
    );

    return this.cachedKey;
  }

  static saveKey(key: CryptoKeyPair) {
    const indexedDB =
      window.indexedDB ||
      // @ts-expect-error indexed db is probably enough but just in case
      window.mozIndexedDB ||
      // @ts-expect-error indexed db is probably enough but just in case
      window.webkitIndexedDB ||
      // @ts-expect-error indexed db is probably enough but just in case
      window.msIndexedDB ||
      // @ts-expect-error indexed db is probably enough but just in case
      window.shimIndexedDB;
    const open = indexedDB.open("keys", 1);

    open.addEventListener("upgradeneeded", () => {
      const db = open.result;
      db.createObjectStore("keystore", { keyPath: "id" });
    });

    open.addEventListener("success", () => {
      const db = open.result;
      const tx = db.transaction("keystore", "readwrite");
      const store = tx.objectStore("keystore");

      store.put({ id: 1, key });

      tx.addEventListener("complete", () => {
        db.close();
      });
    });
  }

  static getKey() {
    const keyPromise = new Promise<CryptoKeyPair>((resolve, reject) => {
      if (this.cachedKey) {
        resolve(this.cachedKey);
        return;
      }

      const indexedDB =
        window.indexedDB ||
        // @ts-expect-error indexed db is probably enough but just in case
        window.mozIndexedDB ||
        // @ts-expect-error indexed db is probably enough but just in case
        window.webkitIndexedDB ||
        // @ts-expect-error indexed db is probably enough but just in case
        window.msIndexedDB ||
        // @ts-expect-error indexed db is probably enough but just in case
        window.shimIndexedDB;
      const open = indexedDB.open("keys", 1);
      open.addEventListener("upgradeneeded", () => {
        const db = open.result;
        db.createObjectStore("keystore", { keyPath: "id" });
      });

      open.addEventListener("success", () => {
        const db = open.result;
        const tx = db.transaction("keystore", "readwrite");
        const store = tx.objectStore("keystore");

        const dat = store.get(1);
        dat.addEventListener("success", () => {
          if (!dat.result) reject(new Error("resp empty"));
          const keys: CryptoKeyPair = dat.result.key;
          this.cachedKey = keys;
          resolve(keys);
        });
        dat.addEventListener("error", reject);

        tx.addEventListener("complete", () => {
          db.close();
        });
        tx.addEventListener("error", reject);
      });

      open.addEventListener("error", reject);
    });

    return keyPromise;
  }

  static async sign(data: string) {
    const key = await this.getKey();
    const buf = await window.crypto.subtle.sign(
      {
        name: "ECDSA",
        hash: "SHA-256",
      },
      key.privateKey,
      Buffer.from(data),
    );

    return Buffer.from(buf).toString("base64");
  }

  static async verify(data: string, signature: string) {
    const key = await this.getKey();
    const sig = Buffer.from(signature, "base64");
    const res = await window.crypto.subtle.verify(
      {
        name: "ECDSA",
        hash: "SHA-256",
      },
      key.publicKey,
      sig,
      Buffer.from(data),
    );

    if (!res) {
      throw new SignatureInvalid();
    }
  }

  static async exportPublicKey() {
    const key = await this.getKey();

    const ekey = await window.crypto.subtle.exportKey("jwk", key.publicKey);
    return JSON.stringify(ekey);
  }
}

export class SignatureInvalid extends Error {}
