import React from "react";
import notFoundImage from "../../Images/notFound.png";
import "./GroupAuction.css";

import { Unsubscribe } from "firebase/auth";
import { onValue, ref } from "firebase/database";
import { NavLink } from "react-router-dom";
import { FirebaseProvider } from "../../BackendHelpers/firebaseProvider";
import { dateConverter, getStatusColor, getStatusText } from "./Auction";

export interface GroupAuctionProps {
  id: string;
  title: string;
  photo: string;
}
export interface GroupAuctionState {
  expiry: number;
  auctionCount: number;
  bidCount: number;
  totalAmount: number;
  t: number;
  photo?: string;
}

export default class GroupAuction extends React.Component<
  GroupAuctionProps,
  GroupAuctionState
> {
  unsubs: Unsubscribe[] = [];
  props: GroupAuctionProps;
  state: GroupAuctionState;

  time: NodeJS.Timer | undefined;

  constructor(props: GroupAuctionProps) {
    super(props);
    this.props = props;
    this.state = {
      auctionCount: 0,
      bidCount: 0,
      expiry: 0,
      totalAmount: 0,
      t: 0,
      photo: this.props.photo,
    };
  }

  componentDidMount(): void {
    this.unsubs.push(
      onValue(
        ref(
          FirebaseProvider.db,
          `/auctionContainers/${this.props.id}/summaryParams/totalAuctionsCount`,
        ),
        (snap) => {
          this.setState({ auctionCount: snap.val() });
        },
      ),
      onValue(
        ref(
          FirebaseProvider.db,
          `/auctionContainers/${this.props.id}/summaryParams/totalAmount`,
        ),
        (snap) => {
          this.setState({ totalAmount: snap.val() });
        },
      ),
      onValue(
        ref(
          FirebaseProvider.db,
          `/auctionContainers/${this.props.id}/summaryParams/expiry`,
        ),
        (snap) => {
          this.setState({ expiry: snap.val() });
        },
      ),
      onValue(
        ref(
          FirebaseProvider.db,
          `/auctionContainers/${this.props.id}/summaryParams/totalAmount`,
        ),
        (snap) => {
          this.setState({ totalAmount: snap.val() });
        },
      ),
      onValue(
        ref(
          FirebaseProvider.db,
          `/auctionContainers/${this.props.id}/summaryParams/totalBidCount`,
        ),
        (snap) => {
          this.setState({ bidCount: snap.val() });
        },
      ),
    );

    this.time = setInterval(() => {
      this.setState({ t: this.state.expiry - new Date().getTime() });
    }, 1000);
  }

  componentWillUnmount(): void {
    clearInterval(this.time);
    this.time = undefined;
    while (this.unsubs.length > 0) {
      this.unsubs.pop()!();
    }
  }

  render() {
    return (
      <div className="GroupAuction-background" {...this.props}>
        <div className="Status">
          <div
            className="Ball"
            style={{
              backgroundColor: getStatusColor(
                this.state.t,
              ),
            }}
          ></div>
          {getStatusText(this.state.t)}
        </div>
        <img
          src={this.state.photo ? this.state.photo : notFoundImage}
          alt=""
          className="photo-box"
          onError={() => {
            this.setState({
              photo: notFoundImage,
            });
          }}
        />
        <div className="inf-area">
          <div className="information-box">
            <div>
              <li id="title">{this.props.title}</li>
            </div>
            <div>
              <li id="auction-count">
                Açık arttırma sayısı:
                <li>{this.state.auctionCount}</li>
              </li>
            </div>
            <div>
              <li id="offer-count">Toplam Teklif ({this.state.bidCount})</li>
              <li id="price">{this.state.totalAmount} TL</li>
            </div>
            <div>
              <li id="remaining-header">Bitmesine Kalan</li>
              <li
                id="remaining"
                style={{
                  color: this.state.t / 1000 > 3600 ? "#17161A" : "#D02224",
                }}
              >
                {dateConverter(this.state.expiry)}
              </li>
            </div>
          </div>
          <NavLink to={`/grup-ihaleler/${this.props.id}`}>
            Grup İhaleye Git
          </NavLink>
        </div>
      </div>
    );
  }
}
