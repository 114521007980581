import React from "react";
import notFoundImage from "../../Images/notFound.png";
import "./Auction.css";

import {
  DatabaseReference,
  onValue,
  ref,
  Unsubscribe,
} from "firebase/database";
import { NavLink } from "react-router-dom";
import { FirebaseProvider } from "../../BackendHelpers/firebaseProvider";

enum AuctionStatus {
  continues = 0,
  almost_done = 1,
  expired = 2,
}

export function dateConverter(epoch: number): string {
  epoch -= new Date().getTime();
  epoch /= 1000;
  if (epoch < 0) epoch = 0;
  // year
  const year = Math.floor(epoch >= 29030400 ? epoch / 29030400 : 0);
  epoch -= year * 29030400;

  // month
  const month = Math.floor(epoch >= 2419200 ? epoch / 2419200 : 0);
  epoch -= month * 2419200;

  // day
  const day = Math.floor(epoch >= 86400 ? epoch / 86400 : 0);
  epoch -= day * 86400;

  // hour
  const hour = Math.floor(epoch >= 3600 ? epoch / 3600 : 0);
  epoch -= hour * 3600;

  // minute
  const minute = Math.floor(epoch >= 60 ? epoch / 60 : 0);
  epoch -= minute * 60;

  // second
  const second = Math.floor(epoch >= 1 ? epoch / 1 : 0);
  epoch -= second * 1;

  if (year > 0) return year + " yıldan fazla";
  else if (month > 0) return month + " ay " + (day > 0 ? day + " gün" : "");
  else if (day > 0) return day + " gün " + (hour > 0 ? hour + " saat " : "");
  else if (hour > 0 || minute > 0 || second > 0) {
    return (
      (hour > 0 ? hour + " saat " : "") +
      (minute > 0 ? minute + " Dk. " : "") +
      (second > 0 ? second + " sn." : "")
    );
  } else return "Süresi Doldu";
}

export function dateConventerWithoutSeconds(epoch: number): string {
  epoch -= new Date().getTime();
  epoch /= 1000;
  if (epoch < 0) epoch = 0;
  // year
  const year = Math.floor(epoch >= 29030400 ? epoch / 29030400 : 0);
  epoch -= year * 29030400;

  // month
  const month = Math.floor(epoch >= 2419200 ? epoch / 2419200 : 0);
  epoch -= month * 2419200;

  // day
  const day = Math.floor(epoch >= 86400 ? epoch / 86400 : 0);
  epoch -= day * 86400;

  // hour
  const hour = Math.floor(epoch >= 3600 ? epoch / 3600 : 0);
  epoch -= hour * 3600;

  // minute
  const minute = Math.floor(epoch >= 60 ? epoch / 60 : 0);
  epoch -= minute * 60;

  // second
  const second = Math.floor(epoch >= 1 ? epoch / 1 : 0);
  epoch -= second * 1;

  if (year > 0) return year + " yıldan fazla";
  else if (month > 0) return month + " ay " + (day > 0 ? day + " gün" : "");
  else if (day > 0) return day + " gün " + (hour > 0 ? hour + " saat " : "");
  else if (hour > 0 || minute > 0) {
    return (
      (hour > 0 ? hour + " saat " : "") + (minute > 0 ? minute + " Dk. " : "")
    );
  } else if (hour == 0 && minute == 0) return "Kısa süre";
  else return "Süresi Doldu";
}

export function getStatus(time: number) {
  const epoch = time / 1000;
  if (epoch > 3600) {
    return AuctionStatus.continues;
  }
  if (epoch > 0) {
    return AuctionStatus.almost_done;
  } else {
    return AuctionStatus.expired;
  }
}

export function getStatusColor(time: number) {
  const auctionStatus = getStatus(time);
  switch (auctionStatus) {
    case AuctionStatus.continues:
      return "#38B000";
    case AuctionStatus.almost_done:
      return "#E85D04";
    case AuctionStatus.expired:
      return "#D02224";
  }
}

export function getStatusText(time: number) {
  const auctionStatus = getStatus(time);
  switch (auctionStatus) {
    case AuctionStatus.continues:
      return "Devam Ediyor";
    case AuctionStatus.almost_done:
      return "Bitmek Üzere";
    case AuctionStatus.expired:
      return "Süresi Doldu";
  }
}

export interface AuctionProps {
  auctionId: string;
  name: string;
  ownerName: string;
}

interface AuctionState {
  expiry?: number;
  bidCount?: number;
  startingBid?: number;
  picture: string | null;
  lastPrice?: number;
  latestPrice?: number;
  displayState?: string;
  expiryString?: string;
}

export default class Auction extends React.Component<
  AuctionProps,
  AuctionState
> {
  static states: { [key: string]: AuctionState } = {};

  listenersInitialized = false;
  listenerCancellers: Unsubscribe[] = [];
  dbRefMap: { [key: string]: DatabaseReference } = {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  listenerActions: { [key: string]: (val: any) => void } = {};

  expInterval: ReturnType<typeof setInterval> | null = null;

  constructor(props: AuctionProps) {
    super(props);

    this.state =
      props.auctionId in Auction.states
        ? Auction.states[props.auctionId]
        : {
            picture: null,
          };
  }

  _setState(_new: object) {
    Auction.states[this.props.auctionId] = {
      ...Auction.states[this.props.auctionId],
      ..._new,
    };
    return this.setState(_new);
  }

  setupRefMap() {
    this.dbRefMap.bidCount = ref(
      FirebaseProvider.db,
      `auctions/${this.props.auctionId}/bidCount`,
    );
    this.dbRefMap.startingBid = ref(
      FirebaseProvider.db,
      `auctions/${this.props.auctionId}/startingBid`,
    );
    this.dbRefMap.expiry = ref(
      FirebaseProvider.db,
      `auctions/${this.props.auctionId}/concurrentParams/expiry`,
    );
    this.dbRefMap.latestPrice = ref(
      FirebaseProvider.db,
      `auctions/${this.props.auctionId}/concurrentParams/currentBid/amount`,
    );
    this.dbRefMap.lastPrice = ref(
      FirebaseProvider.db,
      `auctions/${this.props.auctionId}/concurrentParams/currentBid/ancestorAmount`,
    );

    this.dbRefMap.pigeonId = ref(
      FirebaseProvider.db,
      `auctions/${this.props.auctionId}/pigeon`,
    );

    this.expInterval = setInterval(() => {
      this._setState({
        expiryString: dateConverter(this.state.expiry ?? 0),
      });
    }, 1000);
  }

  setupListenerActions() {
    this.listenerActions.bidCount = (val: number) =>
      this._setState({ bidCount: val });
    this.listenerActions.startingBid = (val: number) =>
      this._setState({ startingBid: val });
    this.listenerActions.expiry = (val: number) =>
      this._setState({ expiry: val });
    this.listenerActions.lastPrice = (val: number) =>
      this._setState({ lastPrice: val });
    this.listenerActions.latestPrice = (val: number) =>
      this._setState({ latestPrice: val });

    this.listenerActions.pigeonId = (val: string) => {
      this.listenerCancellers.push(
        onValue(ref(FirebaseProvider.db, `pigeons/${val}/pictures`), (snap) => {
          if (snap.exists()) {
            this._setState({
              picture: Object.values<string>(snap.val())[0],
            });
          }
        }),
      );
    };
  }

  initializeListeners() {
    for (const key of Object.keys(this.dbRefMap)) {
      this.listenerCancellers.push(
        onValue(this.dbRefMap[key], (snap) => {
          if (snap.exists()) {
            if (this.listenerActions[key]) {
              this.listenerActions[key](snap.val());
            }
          }
        }),
      );
    }
  }

  componentDidMount(): void {
    this.setupRefMap();
    this.setupListenerActions();
    this.initializeListeners();
  }

  componentWillUnmount(): void {
    for (const x of Object.values(this.listenerCancellers)) {
      x();
    }
  }

  getStatusComponent() {
    if (!this.state.expiry) {
      return (
        <div className="Status">
          <div className="Ball" style={{ backgroundColor: "#AAAAAA" }} />
          Yükleniyor...
        </div>
      );
    }

    return (
      <div className="Status">
        <div
          className="Ball"
          style={{
            backgroundColor: getStatusColor(
              this.state.expiry - new Date().getTime(),
            ),
          }}
        />
        {getStatusText(this.state.expiry - new Date().getTime())}
      </div>
    );
  }

  getImageComponent() {
    return (
      <img
        src={this.state.picture ? this.state.picture : notFoundImage}
        alt=""
        className="photo-box"
        onError={() => {
          this.setState({
            picture: notFoundImage,
          });
        }}
      />
    );
  }

  getOwnerComponent() {
    return <li id="owner">{this.props.ownerName}</li>;
  }

  getTitleComponent() {
    if (!this.props.name) {
      return <li id="title">Yükleniyor...</li>;
    }
    return <li id="title">{this.props.name}</li>;
  }

  getOfferCountComponent() {
    return <li id="offer-count">Teklif ({this.state.bidCount ?? 0})</li>;
  }

  getOfferPriceComponent() {
    return (
      <li id="price">
        {this.state.latestPrice ?? this.state.startingBid} TL
        {this.state.lastPrice ? (
          <li id="old-price">{this.state.lastPrice} TL</li>
        ) : null}
      </li>
    );
  }

  getRemainingTimeComponent() {
    if (!this.state.expiry) {
      return (
        <li id="remaining" style={{ color: "#AAAAAA" }}>
          Yükleniyor...
        </li>
      );
    }

    return (
      <li
        id="remaining"
        style={{ color: this.state.expiry > 3600000 ? "#17161A" : "#D02224" }}
      >
        {this.state.expiryString}
      </li>
    );
  }

  render() {
    return (
      <NavLink
        className="Auction-background"
        style={{ display: "flex", padding: 0 }}
        to={`/acik-arttirmalar/${this.props.auctionId}`}
      >
        {this.getStatusComponent()}
        {this.getImageComponent()}
        <div className="information-box">
          <div>
            {this.getTitleComponent()}
            {this.getOwnerComponent()}
          </div>
          <div>
            {this.getOfferCountComponent()}
            {this.getOfferPriceComponent()}
          </div>
          <div>
            <li id="remaining-header">Bitmesine Kalan</li>
            {this.getRemainingTimeComponent()}
          </div>
        </div>
      </NavLink>
    );
  }
}
