import { Component } from "react";

import notFoundImage from "../../Images/notFound.png";
import "../../Pages/AuctionsPage/AuctionPage.css";
import { photoStore } from "../../stores/photoStore";

interface Props {
  maxWidth?: number;
  alt: string;
  src: string;
}

export default class Photo extends Component<Props> {
  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          aspectRatio: "1",
          maxWidth: !this.props.maxWidth ? "none" : this.props.maxWidth + "px",
        }}
      >
        <img
          style={{
            width: "100%",
            aspectRatio: "1",
            borderRadius: "25px",
            objectFit: "contain"
          }}
          src={this.props.src}
          alt={this.props.alt}
        />
      </div>
    );
  }
}

export class GalleryPhoto extends Component<{
  maxWidth?: number;
  className?: string;
  src: string;
  onError?: () => void;
  disableMag?: boolean;
}> {
  render() {
    return (
      <div
        className={this.props.className}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          aspectRatio: "1",
          maxWidth: !this.props.maxWidth ? "none" : this.props.maxWidth + "px",
        }}
        onClick={() => {
          if (!this.props.disableMag || this.props.src === notFoundImage) {
            photoStore.dispatch({
              type: { action: "open", photo: this.props.src },
            });
          }
        }}
      >
        <img
          style={{
            width: "100%",
            aspectRatio: "1",
            borderRadius: "25px",
            objectFit: "cover",
          }}
          src={this.props.src}
          alt=""
          onError={this.props.onError}
        />
      </div>
    );
  }
}
