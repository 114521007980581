import { Component } from "react";
import {
  areYouSure,
  AreYouSure,
  areYouSureStore,
} from "../../stores/areYouSureStore";
import "./areYouSureOverlay.css";

export default class AreYouSureOverlay extends Component<object, areYouSure> {
  unsub: (() => void) | undefined;
  constructor(props: object) {
    super(props);

    this.state = {
      isOpen: false,
      message: "",
    };
  }

  componentDidMount(): void {
    this.unsub = areYouSureStore.subscribe(() => {
      this.setState(() => {
        return {
          isOpen: areYouSureStore.getState().rus.isOpen,
          message: areYouSureStore.getState().rus.message,
        };
      });
    });
  }

  componentWillUnmount(): void {
    if (this.unsub) this.unsub();
  }

  callback(answer: boolean) {
    if (answer) AreYouSure.yesCallBack();
    else AreYouSure.noCallBack();

    AreYouSure.close();
  }

  render() {
    return (
      <div
        className="are-you-sure-background"
        style={{
          display: this.state.isOpen ? "flex" : "none",
        }}
      >
        <div className="content">
          <span>{this.state.message}</span>
          <div>
            <button id="yes" onClick={() => this.callback(true)}>
              Evet
            </button>
            <button id="no" onClick={() => this.callback(false)}>
              Hayır
            </button>
          </div>
        </div>
      </div>
    );
  }
}
