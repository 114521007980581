import { Component } from "react";

import {
  ENDPOINT_BASE,
  _PagedResource,
} from "../../BackendHelpers/pagedResource";
import AboutUs from "../../Components/AboutUs/AboutUs";
import Auction from "../../Components/Auction/Auction";
import GroupAuction from "../../Components/Auction/GroupAuction";
import HowToBid from "../../Components/HowToBid/HowToBid";
import { Informations } from "../../Components/Informations";
import MainTitle from "../../Components/MainTitles/MainTitle";
import Photo from "../../Components/Photo/Photo";
import ImageSlider from "../../Components/Sliders/ImageSlider";
import SliderLayout from "../../Components/Sliders/SliderLayout";
import { AuctionData, GroupAuctionData } from "../AuctionsPage/AuctionsLayout";
import { mngr as GalleryManager } from "../Gallery/Gallery";
import { mngr as NewsManager } from "../Newscast/Newscast";
import "./MainPage.css";

const AuctionDataMngr = new _PagedResource<AuctionData>(
  `${ENDPOINT_BASE}/auctionQuery.php`,
  4,
);

const GroupAuctionDataMngr = new _PagedResource<GroupAuctionData>(
  `${ENDPOINT_BASE}/auctionContainerQuery.php`,
  2,
);

export default class MainPage extends Component<
  unknown,
  {
    auctions: JSX.Element[];
    groupAuctions: JSX.Element[];
  }
> {
  state: { auctions: JSX.Element[]; groupAuctions: JSX.Element[] };

  constructor(props: unknown) {
    super(props);
    this.state = {
      auctions: [],
      groupAuctions: [],
    };
  }

  componentDidMount(): void {
    AuctionDataMngr.setWindowToStart();
    GroupAuctionDataMngr.setWindowToStart();

    AuctionDataMngr.loadNextPage({
      0: { op: "where" },
      yz1: { op: "qparam", v: "`auctions`.`status`" },
      yz2: { op: "e" },
      yz3: { op: "qmark", t: "i", v: 2 },
      yz5: { op: "orderby" },
      yz6: { op: "qparam", v: "`auctions`.`endingTime`" },
      yz7: { op: "orderbyasc" },
    }).then((resp) =>
      this.setState({
        auctions: resp.map((val) => (
          <Auction
            key={(val as AuctionData)["`auctions`.`id`"]}
            auctionId={(val as AuctionData)["`auctions`.`id`"]}
            name={(val as AuctionData)["`auctions`.`name`"]}
            ownerName={(val as AuctionData)["`users`.`fullname`"]}
          />
        )),
      }),
    );

    GroupAuctionDataMngr.loadNextPage({
      0: { op: "where" },
      yy1: { op: "qparam", v: "parent"},
      yy2: { op: "e",},
      yy3: { op: "qmark", v: "-NvprCaPFqIoVT4lc9Bi", t: "s"},
      yy4: { op: "and" },
      yz1: { op: "qparam", v: "endingTime" },
      yz2: { op: "gt" },
      yz3: { op: "qmark", t: "i", v: new Date().getTime() },
      yz4: { op: "orderby" },
      yz5: { op: "qparam", v: "endingTime" },
      yz6: { op: "orderbyasc" },
    }).then((resp) => {
      this.setState({
        groupAuctions: resp.map((val) => (
          <GroupAuction
            key={val.id}
            id={val.id}
            title={val.name}
            photo={val.photo}
          ></GroupAuction>
        )),
      });
    });
  }

  render() {
    return (
      <div className="MainMenu" id="page">
        <ImageSlider></ImageSlider>

        <div className="line"></div>

        <SliderLayout
          itemPercents={60}
          clearance={40}
          items={Informations.label_titles.map((val, index) => (
            <MainTitle
              key={index}
              title={val.title}
              inside={val.msg}
            ></MainTitle>
          ))}
        />

        <div className="line-break"></div>

        {this.state.auctions.length ? (
          <SliderLayout
            to="/acik-arttirmalar"
            bottomButton="Tüm Açık Arttırmalar"
            keepButton="Hepsini Gör"
            name="Devam Eden Açık Arttırmalar"
            items={this.state.auctions}
          />
        ) : null}

        <div className="line-break"></div>

        {this.state.groupAuctions.length ? (
          <SliderLayout
            itemPercents={90}
            to="/grup-ihaleler"
            bottomButton="Tüm Grup İhaleler"
            keepButton="Hepsini Gör"
            name="Devam Eden Grup İhaleler"
            items={this.state.groupAuctions}
          />
        ) : null}

        <div className="line-break"></div>

        {GalleryManager.loadedItems.length ? (
          <SliderLayout
            itemPercents={50}
            to="/galeri"
            bottomButton="Diğer Görseller"
            keepButton="Hepsini Gör"
            name="Galeri"
            items={GalleryManager.loadedItems.slice(0, 4)}
          />
        ) : null}

        {Informations.sponsors.length ? (
          <div className="line-break"></div>
        ) : null}

        {Informations.sponsors.length ? (
          <SliderLayout
            itemPercents={25}
            name="Sponsorlarımız"
            maxScale={0.8}
            minScale={0.6}
            startMiddle={true}
            isAutomatic={true}
            justResponsive={false}
            hideSelectors={true}
            infinity={true} // not working!
            items={Informations.sponsors.map((val, index) => {
              return <Photo src={val.url} alt={val.alt} key={index} />;
            })}
          />
        ) : null}

        <div className="line-break"></div>

        {NewsManager.loadedItems.length ? (
          <SliderLayout
            itemPercents={50}
            to="/haberler"
            bottomButton="Diğer Haberler"
            keepButton="Hepsini Gör"
            name="Güncel Haberler"
            items={NewsManager.loadedItems.slice(0, 4)}
          />
        ) : null}

        <HowToBid></HowToBid>

        <div className="about-us">
          <AboutUs
            buttonPath="/kurumsal/hakkimizda"
            buttonText="Kurumsal"
          ></AboutUs>
        </div>
      </div>
    );
  }
}
