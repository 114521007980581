import { FirebaseApp, FirebaseOptions, initializeApp } from "@firebase/app";
import { Analytics, getAnalytics } from "firebase/analytics";
import { Auth, getAuth } from "firebase/auth";
import { Database, getDatabase } from "firebase/database";
import { Functions, getFunctions } from "firebase/functions";
import { Messaging, getMessaging, getToken } from "firebase/messaging";

const captchaPublicKey = "";

const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyBqswAu1U7CFQenq_e1LWABCuXmB-Jpyg4",
  authDomain: "derby10arena.firebaseapp.com",
  databaseURL:
    "https://derby10arena-default-rtdb.europe-west1.firebasedatabase.app",
  // "https://derby10arena-test.europe-west1.firebasedatabase.app",
  projectId: "derby10arena",
  storageBucket: "derby10arena.appspot.com",
  messagingSenderId: "988598084571",
  appId: "1:988598084571:web:786c81aa535853af3f7dfe",
  measurementId: "G-PWXC1HCNP7",
};

export class FirebaseProvider {
  static initialized = false;
  static app: FirebaseApp;
  static analytics: Analytics;
  static db: Database;
  static functions: Functions;
  static auth: Auth;
  static messaging: Messaging;
  static deviceId: string | null;
  // static appcheck: AppCheck;

  static initializeFirebase() {
    if (this.initialized) return;

    // TODO initalize esnasında hata alınması durumunda bunun karşılanması gerekli
    try {
      this.app = initializeApp(firebaseConfig);
      this.analytics = getAnalytics(this.app);
      this.db = getDatabase(this.app);
      this.functions = getFunctions(this.app, "europe-west1");
      this.auth = getAuth(this.app);
      this.messaging = getMessaging(this.app);
      getToken(this.messaging);

      this.deviceId = localStorage.getItem("deviceId");
      if (!this.deviceId) {
        this.deviceId = crypto.randomUUID();
        localStorage.setItem("deviceId", this.deviceId);
      }
    } catch (e) {
      console.log("firebase initalize error");
      this.initialized = false;
    }

    // this.appcheck = initializeAppCheck(this.app, {
    //     provider: new ReCaptchaV3Provider(captchaPublicKey),
    //     isTokenAutoRefreshEnabled: true,
    // });

    this.initialized = true;
  }
}
