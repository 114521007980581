import { Component } from "react";
import News from "../../Components/News/News";
import TitlePhoto from "../../Components/Photo/TitlePhoto";
import "./Newscast.css";

import {
  ENDPOINT_BASE,
  PagedResource,
} from "../../BackendHelpers/pagedResource";
import NewsSearchArea from "../../Components/News/NewsSearchArea";

interface State {
  pageIndex: number;
  isFocused: boolean;
}

export interface NewsData {
  new_id: number;
  publish_date: number;
  comment_count: number;
  title: string;
  main_picture: string;
}

export class NewscastManager extends PagedResource<NewsData> {
  ENDPOINT = `${ENDPOINT_BASE}/newsQuery.php`;
  ITEM_CNT_PER_PAGE = 10;

  itemBuilder(e: NewsData) {
    return <News {...e}></News>;
  }
}

export const mngr = new NewscastManager();

export default class Newscast extends Component<object, State> {
  constructor(props: object) {
    super(props);
    this.state = {
      pageIndex: 0,
      isFocused: false,
    };
  }

  render() {
    return (
      <div id="page">
        <TitlePhoto title="Haberler"></TitlePhoto>
        <div className="newscast-viewport">
          <div className="newscast-area">
            {mngr.loadedItems.length ? (
              <div className="news">{mngr.getPageItems(this.state)}</div>
            ) : (
              <span className="not-found">Haber bulunamadı</span>
            )}
            {mngr.loadedItems.length ? (
              <div className="page-buttons">
                <button onClick={() => mngr.decrementPage(this)}>Geri</button>
                <div>{this.state.pageIndex + 1}</div>
                <button onClick={() => mngr.incrementPage(this)}>İleri</button>
              </div>
            ) : null}
          </div>
          <NewsSearchArea></NewsSearchArea>
        </div>
      </div>
    );
  }
}
