import {
  DataSnapshot,
  QueryConstraint,
  Unsubscribe,
  onValue,
  query,
  ref,
} from "firebase/database";
import { Component } from "react";
import { FirebaseProvider } from "../../BackendHelpers/firebaseProvider";
import MyAuction, {
  AuctionStatus,
  Bid,
} from "../../Components/Auction/MyAuction";
import "./MyAuctions.css";

interface Props {
  status: AuctionStatus;
}

interface State {
  auctions: { [Key: string]: Bid };
}

export default class MyAuctions extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      auctions: {},
    };
  }

  componentDidMount(): void {
    this.load();
  }

  load() {
    this.querry(
      `users/${FirebaseProvider.auth.currentUser?.uid}/biddedAuctions`,
      (snap) => {
        this.setState({
          auctions: snap.val(),
        });
      },
      true,
      null,
    );
  }

  querry(
    path: string,
    callback: (snap: DataSnapshot) => unknown,
    onlyOnce: boolean,
    unsub: Unsubscribe[] | Unsubscribe | null,
    queryConstraint: QueryConstraint | null = null,
  ): Unsubscribe {
    const uns = onValue(
      queryConstraint != null
        ? query(ref(FirebaseProvider.db, path), queryConstraint)
        : ref(FirebaseProvider.db, path),
      (snap) => callback(snap),
      { onlyOnce: onlyOnce },
    );

    if (unsub == null) return uns;

    if (Array.isArray(unsub)) {
      unsub.push(uns);
    } else {
      unsub = uns;
    }

    return uns;
  }

  getMyAuctions() {
    if (Object.keys(this.state.auctions ?? {}).map.length == 0) {
      return (
        <span className="not-found">
          Teklif verilen açık arttırma bulunamadı
        </span>
      );
    }

    const _out: JSX.Element[] = [];

    Object.keys(this.state.auctions ?? {}).map((val) => {
      _out.push(
        <MyAuction id={val} status={this.props.status} key={val}></MyAuction>,
      );
    });

    return _out;
  }

  getNotFound() {
    if (Object.keys(this.state.auctions ?? {}).length == 0) {
      return <span className="not-found">Verilen teklif bulunamadı</span>;
    } else return null;
  }

  render() {
    return (
      <div className="my-auctions">
        <input className="search-bar" placeholder="Ara"></input>
        <div className="my-auctions-list">
          {this.getMyAuctions()}
          {this.getNotFound()}
        </div>
      </div>
    );
  }
}
