import {
  DataSnapshot,
  QueryConstraint,
  Unsubscribe,
  limitToLast,
  onValue,
  query,
  ref,
} from "firebase/database";
import { Component } from "react";
import { NavLink } from "react-router-dom";
import { FirebaseProvider } from "../../BackendHelpers/firebaseProvider";
import Icons from "../../Icons/svg-icon";
import "./MyAuction.css";

export enum AuctionStatus {
  allAuctions,
  ongoingAuctions,
  winnerAuctions,
  loserAuctions,
}

interface State {
  bidData: AuctionBidData;
  bottomBar: boolean;
}

interface AuctionBidData {
  photoUrl: string;
  sellerUserName: string;
  sellerNameSurname: string;
  serialNumber: number;
  tagnum: string;
  city: string;
  province: string;
  expiry: number;
  bidDate: number;
  lastBid: number;
  previousLastBid: number;
  myLastBid: number;
  previousMyLastBid: number;
  bidCount: number;
  lastBidder: string | null;
  sealed: boolean;
  bids: { [Key: string]: Bid } | null;
}

export interface Bid {
  on: number;
  amount: number;
  old: number;
}

interface Props {
  id: string;
  status: AuctionStatus;
}

// TODO add interval for every seconds
export default class MyAuction extends Component<Props, State> {
  RootlistenerCancellers: Unsubscribe[] = [];

  constructor(props: Props) {
    super(props);
    this.state = {
      bottomBar: false,
      bidData: {
        bidCount: 0,
        bidDate: 0,
        bids: null,
        city: "",
        province: "",
        expiry: 0,
        lastBid: 0,
        myLastBid: 0,
        photoUrl: "",
        previousLastBid: 0,
        previousMyLastBid: 0,
        sellerNameSurname: "",
        sellerUserName: "",
        serialNumber: 0,
        tagnum: "",
        sealed: false,
        lastBidder: null,
      },
    };
  }

  componentDidMount(): void {
    this.load();
  }
  componentWillUnmount(): void {
    for (const x of Object.values(this.RootlistenerCancellers)) {
      x();
    }
  }

  load() {
    // pigeon
    this.querry(
      `auctions/${this.props.id}/pigeon`,
      (snap) => {
        const pigeonId = snap.val();

        this.querry(
          `pigeons/${pigeonId}`,
          (snap2) => {
            const userPath =
              snap2.val()["owner"].length == 20 ? "fake_users" : "users";

            const personalInfoPath =
              snap2.val()["owner"].length == 20 ? "p" : "personalInfo";

            this.setState((state) => {
              state.bidData.photoUrl = Object.values<string>(
                snap2.val()["pictures"] ?? {},
              )[0];
              state.bidData.serialNumber = snap2.val()["serial"];
              state.bidData.tagnum = snap2.val()["tagNum"];
            });

            this.querry(
              `${userPath}/${
                snap2.val()["owner"]
              }/${personalInfoPath}/fullname`,
              (snap3) =>
                this.setState(
                  (state) => (state.bidData.sellerNameSurname = snap3.val()),
                ),
              true,
              null,
            );

            this.querry(
              `${userPath}/${
                snap2.val()["owner"]
              }/${personalInfoPath}/username`,
              (snap3) => {
                const optionalName = this.state.bidData.sellerNameSurname;
                this.setState(
                  (state) =>
                    (state.bidData.sellerUserName =
                      snap3.val() ?? optionalName),
                );
              },
              true,
              null,
            );

            this.querry(
              `${userPath}/${
                snap2.val()["owner"]
              }/${personalInfoPath}/province`,
              (snap3) =>
                this.setState((state) => (state.bidData.city = snap3.val())),
              true,
              null,
            );
            this.querry(
              `${userPath}/${snap2.val()["owner"]}/${personalInfoPath}/city`,
              (snap3) =>
                this.setState(
                  (state) => (state.bidData.province = snap3.val()),
                ),
              true,
              null,
            );
          },
          true,
          null,
        );
      },
      true,
      null,
    );

    // expiry
    this.querry(
      `auctions/${this.props.id}/concurrentParams/expiry`,
      (snap) => {
        this.setState((state) => (state.bidData.expiry = snap.val()));
      },
      false,
      this.RootlistenerCancellers,
    );

    // sealed
    this.querry(
      `auctions/${this.props.id}/concurrentParams/currentBid/sealed`,
      (snap) => {
        this.setState((state) => (state.bidData.sealed = snap.val()));
      },
      false,
      this.RootlistenerCancellers,
    );

    // last bidder
    this.querry(
      `auctions/${this.props.id}/concurrentParams/currentBid/bidder`,
      (snap) => {
        this.setState((state) => (state.bidData.lastBidder = snap.val()));
      },
      false,
      this.RootlistenerCancellers,
    );

    // bid count
    this.querry(
      `auctions/${this.props.id}/bidCount`,
      (snap) => {
        this.setState((state) => (state.bidData.bidCount = snap.val()));
      },
      false,
      this.RootlistenerCancellers,
    );

    // last bid
    this.querry(
      `auctions/${this.props.id}/concurrentParams/currentBid/amount`,
      (snap) => {
        if (snap.val() != null) {
          this.setState((state) => (state.bidData.lastBid = snap.val()));
          return;
        }

        this.querry(
          `auctions/${this.props.id}/startingBid`,
          (snap2) => {
            this.setState((state) => (state.bidData.lastBid = snap2.val()));
          },
          true,
          null,
        );
      },
      false,
      this.RootlistenerCancellers,
    );

    // last bid prev
    this.querry(
      `auctions/${this.props.id}/concurrentParams/currentBid/ancestorAmount`,
      (snap) => {
        if (snap.val() != null) {
          this.setState(
            (state) => (state.bidData.previousLastBid = snap.val()),
          );
          return;
        }
      },
      false,
      this.RootlistenerCancellers,
    );

    this.querry(
      `auctions/${this.props.id}/bidsSummary`,
      (snap) => {
        if (snap.val() != null) {
          this.setState((state) => (state.bidData.bids = snap.val()));
          return;
        }
      },
      false,
      this.RootlistenerCancellers,
      limitToLast(10),
    );

    this.RootlistenerCancellers.push(
      FirebaseProvider.auth.onAuthStateChanged(() => {
        if (FirebaseProvider.auth.currentUser?.uid == undefined) return;

        this.querry(
          `users/${FirebaseProvider.auth.currentUser?.uid}/biddedAuctions/${this.props.id}/bidId`,
          (snap) => {
            const bidId = snap.val();

            this.querry(
              `auctions/${this.props.id}/bidsSummary/${bidId}`,
              (snapBid) => {
                if (!snapBid.val()["on"]) return;
                // from bid data
                this.setState((state) => {
                  state.bidData.bidDate = snapBid.val()["on"];
                });
              },
              true,
              null,
            );
          },
          true,
          null,
        );
        this.querry(
          `users/${FirebaseProvider.auth.currentUser?.uid}/biddedAuctions/${this.props.id}/bidAmount`,
          (snap) =>
            this.setState((state) => (state.bidData.myLastBid = snap.val())),
          true,
          null,
        );
        this.querry(
          `users/${FirebaseProvider.auth.currentUser?.uid}/biddedAuctions/${this.props.id}/amountBumpedFrom`,
          (snap) =>
            this.setState(
              (state) => (state.bidData.previousMyLastBid = snap.val()),
            ),
          true,
          null,
        );
      }),
    );
  }

  querry(
    path: string,
    callback: (snap: DataSnapshot) => unknown,
    onlyOnce: boolean,
    unsub: Unsubscribe[] | Unsubscribe | null,
    queryConstraint: QueryConstraint | null = null,
  ): Unsubscribe {
    const uns = onValue(
      queryConstraint != null
        ? query(ref(FirebaseProvider.db, path), queryConstraint)
        : ref(FirebaseProvider.db, path),
      (snap) => callback(snap),
      { onlyOnce: onlyOnce },
    );

    if (unsub == null) return uns;

    if (Array.isArray(unsub)) {
      unsub.push(uns);
    } else {
      unsub = uns;
    }

    return uns;
  }

  getWinStatusColor() {
    if (!this.state.bidData.sealed) {
      return "#E85D04";
    }
    if (
      this.state.bidData.lastBidder == FirebaseProvider.auth.currentUser?.uid
    ) {
      return "#38B000";
    } else return "#D02224";
  }

  getWinStatusText() {
    if (!this.state.bidData.sealed) {
      return "Devam Ediyor";
    }
    if (
      this.state.bidData.lastBidder == FirebaseProvider.auth.currentUser?.uid
    ) {
      return "Kazandın";
    } else return "Kaybettin";
  }

  getBidComponent(amount: number, prevAmount: number, date: number) {
    const bidDate = new Date(date);
    return (
      <div className="item-scrollable">
        <span className="thin-text">
          {bidDate.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })}
          - {bidDate.toLocaleDateString()}
        </span>
        <div
          className="for-rows"
          style={{
            alignItems: "baseline",
          }}
        >
          <span
            className="bold-text"
            style={{
              fontFamily: "Rubik-Bold",
              fontSize: "20px",
              color: "#6A696C",
            }}
          >
            {amount} TL
          </span>
          <span
            className="bold-text"
            style={{
              color: "#b3b3b3",
              textDecoration: "line-through",
            }}
          >
            {prevAmount} TL
          </span>
        </div>
      </div>
    );
  }

  getAuctionsStatus() {
    switch (this.props.status) {
      case AuctionStatus.allAuctions:
        return true;
      case AuctionStatus.loserAuctions:
        return (
          this.state.bidData.sealed &&
          this.state.bidData.lastBidder !=
            FirebaseProvider.auth.currentUser?.uid
        );
      case AuctionStatus.ongoingAuctions:
        return !this.state.bidData.sealed;
      case AuctionStatus.winnerAuctions:
        return (
          this.state.bidData.sealed &&
          this.state.bidData.lastBidder ==
            FirebaseProvider.auth.currentUser?.uid
        );
    }
  }

  render() {
    return (
      <div
        className="my-auction"
        style={{
          marginBottom: this.state.bottomBar ? "110px" : "0px",
          transitionDuration: "100ms",
          display: this.getAuctionsStatus() ? "flex" : "none",
        }}
      >
        <NavLink
          to={`/acik-arttirmalar/${this.props.id}`}
          style={{ padding: 0, backgroundColor: "transparent" }}
        >
          <img src={this.state.bidData.photoUrl} alt="" />
        </NavLink>
        <div className="my-auction-content">
          <button
            className="expand"
            onClick={() => {
              this.setState({
                bottomBar: !this.state.bottomBar,
              });
            }}
            style={{
              transitionDuration: "100ms",
              transform: this.state.bottomBar
                ? "translate(-50%, 5px) rotate(180deg)"
                : "translate(-50%, 5px) rotate(0deg)",
            }}
          >
            <Icons.ExpandCircle fill="#b3b3b3" size={35}></Icons.ExpandCircle>
          </button>
          <div className="informations">
            <div className="for-rows item gray">
              <div className="profile">
                <Icons.User size={26} fill="white"></Icons.User>
              </div>
              <div>
                <span className="thin-text">Satıcı Kullanıcı Adı</span>
                <span className="bold-text">
                  {this.state.bidData.sellerUserName != ""
                    ? this.state.bidData.sellerUserName
                    : "yükleniyor..."}
                </span>
              </div>
            </div>
            <div className="item gray">
              <span className="thin-text">Satıcı Adı Soyadı</span>
              <span className="bold-text">
                {this.state.bidData.sellerNameSurname != ""
                  ? this.state.bidData.sellerNameSurname
                  : "yükleniyor..."}
              </span>
            </div>
            <div className="item gray">
              <span className="thin-text">Sıra No</span>
              <span className="bold-text">
                {this.state.bidData.serialNumber.toString().padStart(6, "0")}
              </span>
            </div>
            <div className="item gray">
              <span className="thin-text">Şehir</span>
              <span className="bold-text">
                {this.state.bidData.city != ""
                  ? this.state.bidData.city
                  : "yükleniyor..."}
              </span>
            </div>
            <div className="item gray">
              <span className="thin-text">Künye No</span>
              <span className="bold-text">
                {this.state.bidData.tagnum != ""
                  ? this.state.bidData.tagnum
                  : "yükleniyor..."}
              </span>
            </div>
            <div className="item gray">
              <span className="thin-text">İlçe</span>
              <span className="bold-text">
                {this.state.bidData.province != ""
                  ? this.state.bidData.province
                  : "yükleniyor..."}
              </span>
            </div>
          </div>
          <div className="informations">
            <div
              className="item"
              style={{ borderColor: this.getWinStatusColor() }}
            >
              <span className="thin-text">Teklif ve Bitiş Tarihi</span>
              <span className="bold-text">
                {new Date(this.state.bidData.bidDate).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
                - {new Date(this.state.bidData.bidDate).toLocaleDateString()}
              </span>
              <span className="bold-text">
                {new Date(this.state.bidData.expiry).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
                - {new Date(this.state.bidData.expiry).toLocaleDateString()}
              </span>
            </div>
            <div
              className="item"
              style={{ borderColor: this.getWinStatusColor() }}
            >
              <span className="thin-text">Son Verdiğim Teklif</span>
              <div
                className="for-rows"
                style={{
                  alignItems: "baseline",
                }}
              >
                <span
                  className="bold-text-orange"
                  style={{
                    fontFamily: "Rubik-Bold",
                    fontSize: "20px",
                    color: this.getWinStatusColor(),
                  }}
                >
                  {this.state.bidData.myLastBid} TL
                </span>
                <span
                  className="bold-text"
                  style={{
                    color: "#b3b3b3",
                    textDecoration: "line-through",
                  }}
                >
                  {this.state.bidData.previousMyLastBid} TL
                </span>
              </div>
            </div>
            <div
              className="item"
              style={{
                borderColor: this.getWinStatusColor(),
              }}
            >
              <span className="thin-text">Durum</span>
              <span
                className="bold-text-orange"
                style={{
                  fontFamily: "Rubik-Bold",
                  fontSize: "17px",
                  color: this.getWinStatusColor(),
                }}
              >
                {this.getWinStatusText()}
              </span>
            </div>
            <div
              className="item"
              style={{
                borderColor: this.getWinStatusColor(),
              }}
            >
              <span className="thin-text">Son Verilen Teklif</span>
              <span
                className="bold-text-orange"
                style={{
                  color: this.getWinStatusColor(),
                }}
              >
                Teklif ({this.state.bidData.bidCount})
              </span>
              <div
                className="for-rows"
                style={{
                  alignItems: "baseline",
                }}
              >
                <span
                  className="bold-text-orange"
                  style={{
                    fontFamily: "Rubik-Bold",
                    fontSize: "20px",
                    color: this.getWinStatusColor(),
                  }}
                >
                  {this.state.bidData.lastBid} TL
                </span>
                <span
                  className="bold-text"
                  style={{
                    color: "#b3b3b3",
                    textDecoration: "line-through",
                  }}
                >
                  {this.state.bidData.previousLastBid} TL
                </span>
              </div>
            </div>
          </div>
          <div
            className="bottom-bar"
            style={{
              bottom: this.state.bottomBar ? "-100px" : "10px",
              // height: "145px",
              transitionDuration: "100ms",
              backgroundColor: this.state.bottomBar ? "#FFFFFF" : "transparent",
            }}
          >
            <div className="bottom-bar-viewport">
              <div className="bottom-bar-content">
                {Object.keys(this.state.bidData.bids ?? {})
                  .reverse()
                  .map((val) => {
                    if (!this.state.bidData.bids) return null;
                    const _bid = this.state.bidData.bids[val];
                    return this.getBidComponent(_bid.amount, _bid.old, _bid.on);
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
