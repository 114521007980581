import { Component, createRef } from "react";
import Informations from "../../Components/Informations";
import TitlePhoto from "../../Components/Photo/TitlePhoto";
import UserTable, { TableFields } from "../../Components/Table/Table";
import Icons from "../../Icons/svg-icon";
import "./UsersPage.css";

interface Props {
  title: string;
  self: boolean;
}

interface State {
  selectedFeature: TableFields;
  menuIsOpen: boolean;
}

export default class UsersPage extends Component<Props, State> {
  tableRef = createRef<UserTable>();
  searchRef = createRef<HTMLInputElement>();

  constructor(props: Props) {
    super(props);
    this.state = {
      selectedFeature: TableFields.serialNumber,
      menuIsOpen: false,
    };
  }

  componentDidMount(): void {
    if (this.searchRef.current) {
      this.searchRef.current.value = Informations.users_page_start_serial;
    }
  }

  getFeatureName(_feature: TableFields) {
    switch (_feature) {
      case TableFields.city:
        return "Şehir";
      case TableFields.country:
        return "Ülke";
      case TableFields.imprintNumber:
        return "Künye No";
      case TableFields.nameSurname:
        return "İsim Soyisim";
      case TableFields.serialNumber:
        return "Sıra No";
      case TableFields.active:
        return "Aktif/Pasif";
      case TableFields.state:
        return "Durum";
    }
  }

  render() {
    return (
      <div id="page">
        <TitlePhoto title={this.props.title}></TitlePhoto>
        <div className="users-table">
          <div className="search-area">
            <div className="search-bar">
              <button
                onClick={() => {
                  this.tableRef.current?.search(
                    this.state.selectedFeature,
                    this.searchRef.current?.value,
                  );
                }}
              >
                <Icons.Search
                  className="Icon"
                  size={16}
                  fill={"#17161A"}
                  style={{ overflow: "hidden" }}
                ></Icons.Search>
              </button>
              <input type="text" placeholder={"Ara"} ref={this.searchRef} />
            </div>
            <button
              className="selected-feature"
              onClick={() =>
                this.setState({ menuIsOpen: !this.state.menuIsOpen })
              }
            >
              {this.getFeatureName(this.state.selectedFeature)}
              <Icons.Expand
                size={20}
                fill={"#17161A"}
                style={{
                  transform: this.state.menuIsOpen
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                  transitionDuration: "150ms",
                }}
              ></Icons.Expand>

              <div
                className="state-menu"
                style={{
                  opacity: this.state.menuIsOpen ? "1" : "0",
                  marginTop: this.state.menuIsOpen ? 220 : 150,
                  pointerEvents: this.state.menuIsOpen ? "all" : "none",
                }}
              >
                {(
                  Object.keys(TableFields) as Array<keyof typeof TableFields>
                ).map((key) => {
                  if (!Number.isNaN(Number(key.toString()))) return;
                  if (this.state.selectedFeature === TableFields[key]) return;
                  return (
                    <div key={key}>
                      <button
                        key={key}
                        onClick={() => {
                          this.setState({
                            menuIsOpen: false,
                            selectedFeature: TableFields[key],
                          });

                          this.tableRef.current?.search(
                            TableFields[key],
                            this.searchRef.current?.value,
                          );
                        }}
                      >
                        {this.getFeatureName(TableFields[key])}
                      </button>
                      {/* <hr/> */}
                    </div>
                  );
                })}
              </div>
            </button>
          </div>
          <div className="table-background">
            <UserTable
              ref={this.tableRef}
              height={500}
              self={this.props.self}
            />
          </div>
        </div>
      </div>
    );
  }
}
