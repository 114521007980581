import { httpsCallable } from "firebase/functions";
import { Component, createRef } from "react";
import { FirebaseProvider } from "../../BackendHelpers/firebaseProvider";
import HowToBid from "../../Components/HowToBid/HowToBid";
import { Informations } from "../../Components/Informations";
import TitlePhoto from "../../Components/Photo/TitlePhoto";
import Icons from "../../Icons/svg-icon";
import { feedback } from "../../stores/feedbackStore";
import { mailAddressMatch } from "../AuthPages/RegisterPages/RegisterPage";
import "./Contact.css";

export default class Contact extends Component {
  nameInput = createRef<HTMLDivElement>();
  _nameInput = createRef<HTMLInputElement>();
  mailInput = createRef<HTMLDivElement>();
  _mailInput = createRef<HTMLInputElement>();
  subjectInput = createRef<HTMLDivElement>();
  _subjectInput = createRef<HTMLInputElement>();
  messageInput = createRef<HTMLDivElement>();
  _messageInput = createRef<HTMLTextAreaElement>();
  clearButton = createRef<HTMLButtonElement>();
  submitButton = createRef<HTMLButtonElement>();

  clearDiv(e: HTMLDivElement | null) {
    if (e) {
      e.style.boxShadow = "0px 1px 10px rgba(0, 0, 0, 0.2)";
    }
  }

  setElementError(e: HTMLDivElement | null) {
    if (e) {
      e.style.boxShadow = "0px 0px 5px rgb(255 0 0)";
    }
  }

  clearFormErrors() {
    this.clearDiv(this.nameInput.current);
    this.clearDiv(this.nameInput.current);
    this.clearDiv(this.mailInput.current);
    this.clearDiv(this.subjectInput.current);
    this.clearDiv(this.messageInput.current);
  }

  clear() {
    this.clearFormErrors();

    if (
      !this._nameInput.current ||
      !this._mailInput.current ||
      !this._subjectInput.current ||
      !this._messageInput.current
    ) {
      return;
    }

    this._nameInput.current.value = "";
    this._mailInput.current.value = "";
    this._subjectInput.current.value = "";
    this._messageInput.current.value = "";
  }

  checkFields() {
    if (
      !this._nameInput.current ||
      !this._mailInput.current ||
      !this._subjectInput.current ||
      !this._messageInput.current
    ) {
      throw new Error("not mounted");
    }

    this.clearFormErrors();

    if (!this._nameInput.current.value.length) {
      feedback.push("İsim boş olamaz", "red", 3);
      this.setElementError(this.nameInput.current);
      throw new Error("isim bos");
    }

    if (!mailAddressMatch.test(this._mailInput.current.value)) {
      feedback.push("Mail adresi geçersiz.", "red", 3);
      this.setElementError(this.mailInput.current);
      throw new Error("mail adresi geçersiz");
    }

    if (!this._subjectInput.current.value.length) {
      feedback.push("Konu boş olamaz.", "red", 3);
      this.setElementError(this.subjectInput.current);
      throw new Error("konu boş");
    }

    if (!this._messageInput.current.value.length) {
      feedback.push("mesaj boş olamaz", "red", 3);
      this.setElementError(this.messageInput.current);
      throw new Error("mesaj bos");
    }
  }

  sendContactMail() {
    if (
      !this._nameInput.current ||
      !this._mailInput.current ||
      !this._subjectInput.current ||
      !this._messageInput.current
    ) {
      return;
    }

    this.clearFormErrors();
    this.checkFields();

    httpsCallable(
      FirebaseProvider.functions,
      "sendContactMail",
    )({
      name: this._nameInput.current.value,
      email: this._mailInput.current.value,
      subject: this._subjectInput.current.value,
      message: this._messageInput.current.value,
    })
      .then(() => {
        feedback.push("Mesaj gönderildi", "green", 3);
      })
      .catch(() => {
        feedback.push("Mesaj gönderilemedi.", "red", 3);
      });
  }

  render() {
    return (
      <div className="Contact-background" id="page">
        <TitlePhoto title="İletişim"></TitlePhoto>
        <div className="top">
          <div className="contact-area">
            <li className="title">İletişim</li>
            <div>
              <div className="mail-tel">
                <div>
                  <Icons.Mail size={24} fill="#17161A" />
                  <a href={Informations.mail_url}>{Informations.mail_name}</a>
                </div>
                <div>
                  <Icons.Calling size={24} fill="#17161A" />
                  <a href={Informations.telephone_url}>
                    {Informations.telephone}
                  </a>
                </div>
              </div>
              <div className="other-contact-infos">
                <div className="sub">
                  <li className="subtitle">Sosyal Medya</li>
                  <div className="area">
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={Informations.facebook_url}
                      >
                        <div>
                          <Icons.Facebook
                            className="Icon"
                            size={20}
                            fill="#17161A"
                          />
                        </div>
                        <span>{Informations.facebook_name}</span>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={Informations.instagram_url}
                      >
                        <div>
                          <Icons.Instagram
                            className="Icon"
                            size={20}
                            fill="#17161A"
                          />
                        </div>
                        <span>{Informations.instagram_name}</span>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={Informations.youtube_url}
                      >
                        <div>
                          <Icons.Youtube
                            className="Icon"
                            size={20}
                            fill="#17161A"
                          />
                        </div>
                        <span>{Informations.youtube_name}</span>
                      </a>
                    </li>
                  </div>
                </div>
                <div className="sub">
                  <li className="subtitle">Adres</li>
                  <div className="area">
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={Informations.address_url}
                      >
                        <div>
                          <Icons.Location
                            className="Icon"
                            size={20}
                            fill="#17161A"
                          />
                        </div>
                        <span style={{ fontSize: "14px" }}>
                          {Informations.address_name}
                        </span>
                      </a>
                    </li>
                  </div>
                </div>
              </div>
            </div>
            <iframe
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              src={Informations.address_embed_url}
            ></iframe>
          </div>
          <div className="contact-form">
            <li className="title">İletişim Formu</li>
            <div className="InputBars">
              <div className="InputBar">
                Adınız Soyadınız
                <div className="Input" ref={this.nameInput}>
                  <Icons.Mail
                    className="Icon"
                    size={24}
                    fill="rgba(0, 0, 0, 0.2)"
                  ></Icons.Mail>
                  <input
                    placeholder="Ad Soyad"
                    type={"text"}
                    ref={this._nameInput}
                  />
                </div>
              </div>
              <div className="InputBar">
                E-Posta Adresiniz
                <div className="Input" ref={this.mailInput}>
                  <Icons.Mail
                    className="Icon"
                    size={24}
                    fill="rgba(0, 0, 0, 0.2)"
                  ></Icons.Mail>
                  <input
                    placeholder="E-Posta"
                    type={"email"}
                    ref={this._mailInput}
                  />
                </div>
              </div>
              <div className="InputBar">
                Konu
                <div className="Input" ref={this.subjectInput}>
                  <Icons.Info
                    className="Icon"
                    size={24}
                    fill="rgba(0, 0, 0, 0.2)"
                  ></Icons.Info>
                  <input
                    placeholder="Konu"
                    type={"text"}
                    ref={this._subjectInput}
                  />
                </div>
              </div>
              <div className="InputBar">
                Mesajınız
                <div className="Input" ref={this.messageInput}>
                  <Icons.Document
                    className="Icon"
                    size={24}
                    fill="rgba(0, 0, 0, 0.2)"
                  ></Icons.Document>
                  <textarea
                    placeholder="Mesaj"
                    // type={"text"}
                    ref={this._messageInput}
                  />
                </div>
              </div>
            </div>
            <div className="Buttons">
              <button
                className="Button"
                id="Outlined"
                ref={this.clearButton}
                onClick={() => this.clear()}
              >
                Sıfırla
              </button>
              <button
                className="Button"
                id="Red"
                ref={this.submitButton}
                onClick={() => this.sendContactMail()}
              >
                Gönder
              </button>
            </div>
          </div>
        </div>
        <HowToBid></HowToBid>
      </div>
    );
  }
}
