import { Component, createRef } from "react";
import {
  ENDPOINT_BASE,
  PhpQuery,
  _PagedResource,
} from "../../BackendHelpers/pagedResource";
import Informations from "../Informations";
import "./Table.css";
import TableElement from "./TableElement";

export interface UserData {
  serialNumber: number;
  imprintNumber: string;
  nameSurname: string;
  country: string;
  city: string;
  active: boolean;
  state: number;
}

interface UserDataDb {
  "`pigeons`.`id`": string;
  "`pigeons`.`serial`": number;
  "`pigeons`.`tagNum`": string;
  "`users`.`fullname`": string;
  "`users`.`username`": string;
  "`users`.`province`": string;
  "`users`.`city`": string;
  "`pigeons`.`active`": number;
  "`pigeons`.`state`": number;
}

export enum TableFields {
  imprintNumber,
  nameSurname,
  country,
  city,
  serialNumber,
  active,
  state,
}

interface Props {
  height: number;
  self: boolean;
}

interface State {
  isResponsiveView: boolean;
  opens: Map<number, boolean>;
  selectedField: TableFields;
  searchVal?: string | number;
  itemsCount: number;
  pageError?: unknown;
}

export default class UserTable extends Component<Props, State> {
  mngr = new _PagedResource<UserDataDb>(
    `${ENDPOINT_BASE}/pigeonListQuery.php`,
    20,
  );
  loadedUsers: JSX.Element[] = [];
  hasLoading = false;
  finished = false;
  nextPageIdentifier: number | undefined;

  scrollPoller: NodeJS.Timer | null = null;
  tbodyRef = createRef<HTMLTableSectionElement>();

  resizerFunc = this.resizer;

  constructor(props: Props) {
    super(props);
    this.state = {
      isResponsiveView: window.innerWidth < 800,
      opens: new Map(),
      selectedField: TableFields.serialNumber,
      searchVal:
        Informations.users_page_start_serial === ""
          ? undefined
          : parseInt(Informations.users_page_start_serial),
      itemsCount: 0,
    };
  }

  componentDidMount(): void {
    console.log("MOUNT");
    this.resizerFunc = () => {
      this.resizer();
    };
    window.addEventListener("resize", this.resizerFunc);

    this.clearLoaded();

    if (!this.scrollPoller) {
      this.scrollPoller = setInterval(() => this.loadNextIfNecessary(), 50);
    }
  }

  componentWillUnmount(): void {
    console.log("UMOUNT");
    this.mngr.setWindowToStart();

    if (this.scrollPoller) {
      clearInterval(this.scrollPoller);
      this.scrollPoller = null;
    }

    this.clearLoaded();

    window.removeEventListener("resize", this.resizerFunc);
  }

  shouldComponentUpdate(
    nextProps: Readonly<Props>,
    nextState: Readonly<State>,
    nextContext: any,
  ): boolean {
    if (this.state.isResponsiveView != nextState.isResponsiveView) {
      try {
        this.clearLoaded();
        this.loadNext(10);
        return true;
      } catch {
        return false;
      }
    }

    if (this.state.itemsCount === nextState.itemsCount) {
      return false;
    }

    return true;
  }

  clearLoaded() {
    console.log("clear loaded");
    this.mngr.setWindowToStart();
    this.finished = false;
    this.loadedUsers.length = 0;
  }

  itemBuilder(val: UserData) {
    return !this.state.isResponsiveView ? (
      <tr key={this.loadedUsers.length}>
        <td style={{ paddingLeft: "35px" }}>{val.serialNumber}</td>
        <td>{val.imprintNumber}</td>
        <td>{val.nameSurname}</td>
        <td>{val.country}</td>
        <td>{val.city}</td>
        <td>{val.active ? "aktif" : "pasif"}</td>
      </tr>
    ) : (
      <TableElement
        feature={this.state.selectedField}
        item={val}
        key={this.loadedUsers.length}
      />
    );
  }

  resizer() {
    if (window.innerWidth < 800 != this.state.isResponsiveView) {
      this.clearLoaded();
      this.setState({
        isResponsiveView: window.innerWidth < 800,
        itemsCount: 0,
      });
    }
  }

  search(field: TableFields, val: string | undefined) {
    if (field === this.state.selectedField && val === this.state.searchVal) {
      return;
    }

    this.clearLoaded();

    if (!this.scrollPoller) {
      this.scrollPoller = setInterval(() => this.loadNextIfNecessary(), 50);
    }

    this.setState({
      selectedField: field,
      searchVal: field > 3 ? parseInt(val ?? "0") : val,
      itemsCount: 0,
    });
  }

  loadNext(cnt = 20, useLoading?: boolean) {
    console.log(
      `loadedUsers : ${this.loadedUsers.length} mngrEnd: ${this.mngr.windowEnd}`,
    );

    if (this.finished) {
      if (this.scrollPoller) {
        clearInterval(this.scrollPoller);
        this.scrollPoller = null;
      }

      return;
    }

    if (this.mngr.loading && useLoading) return;

    this.loadedUsers.push(
      <center>
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </center>,
    );

    let query: PhpQuery = {};

    if (this.state.searchVal) {
      switch (this.state.selectedField) {
        case TableFields.active:
          query = {
            0: { op: "where" },
            active1: { op: "qparam", v: "`pigeons`.`active`" },
            active2: { op: "e" },
            active3: { op: "qmark", t: "i", v: this.state.searchVal },
            zy1: {
              op: "orderby",
            },
            zy2: {
              op: "qparam",
              v: "`pigeons`.`serial`",
            },
            zy3: {
              op: "orderbyasc",
            },
          };
          break;
        case TableFields.city:
          query = {
            0: { op: "where" },
            city1: { op: "qparam", v: "`users`.`city`" },
            city2: { op: "like" },
            city3: { op: "qmark", t: "s", v: this.state.searchVal },
            zy1: {
              op: "orderby",
            },
            zy2: {
              op: "qparam",
              v: "`pigeons`.`serial`",
            },
            zy3: {
              op: "orderbyasc",
            },
          };
          break;
        case TableFields.country:
          query = {
            0: { op: "where" },
            province1: { op: "qparam", v: "`users`.`province`" },
            province2: { op: "like" },
            province3: { op: "qmark", t: "s", v: this.state.searchVal },
            zy1: {
              op: "orderby",
            },
            zy2: {
              op: "qparam",
              v: "`pigeons`.`serial`",
            },
            zy3: {
              op: "orderbyasc",
            },
          };
          break;
        case TableFields.imprintNumber:
          query = {
            0: { op: "where" },
            tagnum1: { op: "qparam", v: "`pigeons`.`tagNum`" },
            tagnum2: { op: "like" },
            tagnum3: { op: "qmark", t: "s", v: this.state.searchVal },
            zy1: {
              op: "orderby",
            },
            zy2: {
              op: "qparam",
              v: "`pigeons`.`serial`",
            },
            zy3: {
              op: "orderbyasc",
            },
          };
          break;
        case TableFields.nameSurname:
          query = {
            0: { op: "where" },
            namesur1: { op: "qparam", v: "`users`.`fullname`" },
            namesur2: { op: "like" },
            namesur3: { op: "qmark", t: "s", v: this.state.searchVal },
            zy1: {
              op: "orderby",
            },
            zy2: {
              op: "qparam",
              v: "`pigeons`.`serial`",
            },
            zy3: {
              op: "orderbyasc",
            },
          };
          break;
        case TableFields.serialNumber:
          query = {
            0: { op: "where" },
            namesur1: { op: "qparam", v: "`pigeons`.`serial`" },
            namesur2: { op: "gt" },
            namesur3: { op: "qmark", t: "i", v: this.state.searchVal },
            zy1: {
              op: "orderby",
            },
            zy2: {
              op: "qparam",
              v: "`pigeons`.`serial`",
            },
            zy3: {
              op: "orderbyasc",
            },
          };
          break;
        case TableFields.state:
          query = {
            0: { op: "where" },
            namesur1: { op: "qparam", v: "`pigeons`.`state`" },
            namesur2: { op: "e" },
            namesur3: { op: "qmark", t: "i", v: this.state.searchVal },
            zy1: {
              op: "orderby",
            },
            zy2: {
              op: "qparam",
              v: "`pigeons`.`serial`",
            },
            zy3: {
              op: "orderbyasc",
            },
          };
          break;
      }
    }

    return new Promise<UserData[]>((resolve, reject) => {
      this.mngr
        .loadNextPage(query)
        .then((res: UserDataDb[]) => {
          const resolved: UserData[] = [];
          res.forEach((element) => {
            resolved.push({
              serialNumber: element["`pigeons`.`serial`"],
              imprintNumber: element["`pigeons`.`tagNum`"],
              nameSurname: element["`users`.`fullname`"],
              country: element["`users`.`province`"],
              city: element["`users`.`city`"],
              active: element["`pigeons`.`active`"] == 1,
              state: element["`pigeons`.`state`"],
            });
          });

          this.loadedUsers.pop();

          resolve(resolved);
        })
        .catch((e) => {
          reject(e);
        });
    })
      .then((res: UserData[]) => {
        res.forEach((val) => this.loadedUsers.push(this.itemBuilder(val)));

        this.finished = res.length < 20;
        this.hasLoading = false;
        this.setState({
          itemsCount: this.loadedUsers.length,
        });
      })
      .catch((e) => {
        console.error(e);

        if (this.scrollPoller) {
          this.loadedUsers.pop();
          clearInterval(this.scrollPoller);
          this.scrollPoller = null;
        }

        this.setState({ pageError: e });

        this.loadedUsers.push(
          <center
            style={{
              position: "relative",
              fontFamily: "Rubik-Medium",
              fontSize: "18px",
              color: "#EE6055",
              backgroundColor: "#ffffff",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              zIndex: "5",
            }}
          >
            Yükleme esnasında bir hata oluştu, lütfen sayfayı yeniden yükleyin.
          </center>,
        );
        this.setState({ itemsCount: this.loadedUsers.length + 1 });
      });
  }

  loadNextIfNecessary = () => {
    if (
      ((this.tbodyRef.current &&
        this.tbodyRef.current.offsetHeight + this.tbodyRef.current.scrollTop >=
          this.tbodyRef.current.scrollHeight - 600) ||
        !this.tbodyRef.current) &&
      !this.mngr.loading
    ) {
      this.loadNext();
    }
  };

  getFeatureName(_feature: TableFields) {
    switch (_feature) {
      case TableFields.city:
        return "Şehir";
      case TableFields.country:
        return "Ülke";
      case TableFields.imprintNumber:
        return "Künye No";
      case TableFields.nameSurname:
        return "İsim Soyisim";
      case TableFields.serialNumber:
        return "Sıra No";
      case TableFields.state:
        return "Durum";
    }
  }

  getFeature(userData: UserData) {
    switch (this.state.selectedField) {
      case TableFields.city:
        return userData.city;
      case TableFields.country:
        return userData.country;
      case TableFields.imprintNumber:
        return userData.imprintNumber;
      case TableFields.nameSurname:
        return userData.nameSurname;
      case TableFields.serialNumber:
        return userData.serialNumber;
      case TableFields.state:
        return userData.state ? "aktif" : "pasif";
    }
  }

  getOthers(userData: UserData, index: number) {
    if (!this.state.opens.get(index)) return;
    return (
      <div className="sub-div">
        <tr
          className="sub-tr"
          style={{
            display:
              this.state.selectedField == TableFields.city ? "none" : "flex",
          }}
        >
          Şehir:<strong className="strong">{userData.city}</strong>
        </tr>
        <tr
          className="sub-tr"
          style={{
            display:
              this.state.selectedField == TableFields.country ? "none" : "flex",
          }}
        >
          Ülke:<strong className="strong">{userData.country}</strong>
        </tr>
        <tr
          className="sub-tr"
          style={{
            display:
              this.state.selectedField == TableFields.imprintNumber
                ? "none"
                : "flex",
          }}
        >
          Künye No:<strong className="strong">{userData.imprintNumber}</strong>
        </tr>
        <tr
          className="sub-tr"
          style={{
            display:
              this.state.selectedField == TableFields.nameSurname
                ? "none"
                : "flex",
          }}
        >
          İsim Soyisim:
          <strong className="strong">{userData.nameSurname}</strong>
        </tr>
        <tr
          className="sub-tr"
          style={{
            display:
              this.state.selectedField == TableFields.serialNumber
                ? "none"
                : "flex",
          }}
        >
          Seri No:<strong className="strong">{userData.serialNumber}</strong>
        </tr>
        <tr
          className="sub-tr"
          style={{
            display:
              this.state.selectedField == TableFields.state ? "none" : "flex",
          }}
        >
          Durum:
          <strong className="strong">
            {userData.state ? "aktif" : "pasif"}
          </strong>
        </tr>
      </div>
    );
  }

  render() {
    if (this.loadedUsers.length == 0) {
      return (
        <div
          style={{
            position: "relative",
            height: "100px",
            width: "100%",
            margin: "0",
            marginTop: window.innerWidth > 800 ? "25px" : "0",
          }}
        >
          <span
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              fontFamily: "Rubik-Medium",
              color: "#6A696C",
            }}
          >
            Katılımcı Bulunamadı
          </span>
        </div>
      ); // TODO: participants not found
    }

    if (!this.state.isResponsiveView) {
      return (
        <div className="table" id="table-wrapper">
          <table style={{ height: "100px" }}>
            <thead>
              <tr>
                <th style={{ paddingLeft: "35px" }}>Sıra No</th>
                <th>Künye No</th>
                <th>Ad Soyad</th>
                <th>Ülke</th>
                <th>Şehir</th>
                <th>Durum</th>
              </tr>
            </thead>
            <tbody
              style={{ maxHeight: this.props.height + "px" }}
              ref={this.tbodyRef}
            >
              {this.loadedUsers}
            </tbody>
          </table>
        </div>
      );
    } else {
      return (
        <div className="table" id="table-wrapper">
          <table style={{ maxHeight: "100px" }}>
            <tbody
              style={{ maxHeight: this.props.height + "px" }}
              ref={this.tbodyRef}
            >
              {this.loadedUsers}
            </tbody>
          </table>
        </div>
      );
    }
  }
}
