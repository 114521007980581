import { Unsubscribe } from "firebase/auth";
import {
  DataSnapshot,
  QueryConstraint,
  onValue,
  query,
  ref,
} from "firebase/database";
import { Component } from "react";
import { FirebaseProvider } from "../../BackendHelpers/firebaseProvider";
import Icons from "../../Icons/svg-icon";
import "./UrgentNotification.css";

interface State {
  accountEnabled: boolean;
  genericMessageShow: boolean;
  accountDisableReason: string;
  genericMessage: string;
  isClosed: boolean;
}

export default class UrgentNotification extends Component<unknown, State> {
  onAuthStateChanged: (() => void) | null = null;
  listenerCancellers: Unsubscribe[] = [];
  bannerListenerCanceller: Unsubscribe | null = null;

  constructor(props: unknown) {
    super(props);
    this.state = {
      accountEnabled: true,
      genericMessageShow: false,
      accountDisableReason: "",
      genericMessage: "",
      isClosed: false,
    };
  }

  componentDidMount(): void {
    this.querry(
      `banner`,
      (snap) => {
        if (!snap.val() || !this.state.accountEnabled) {
          this.setState({
            genericMessageShow: false,
            genericMessage: "",
          });
        } else {
          this.setState({
            genericMessageShow: true,
            genericMessage: snap.val(),
          });
        }
      },
      false,
      this.bannerListenerCanceller,
    );

    this.onAuthStateChanged = FirebaseProvider.auth.onAuthStateChanged(() => {
      if (!FirebaseProvider.auth.currentUser) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        while (this.listenerCancellers.length) this.listenerCancellers.pop()!();

        this.setState({
          accountEnabled: true,
          accountDisableReason: "",
          isClosed: false,
        });
        return;
      }
      this.listenerCancellers.push(
        onValue(
          ref(
            FirebaseProvider.db,
            `users/${FirebaseProvider.auth.currentUser?.uid}/authInfo/accountEnabled`,
          ),
          (snap) => {
            if (snap.exists()) {
              this.setState({ accountEnabled: snap.val() });
            }
          },
        ),
        onValue(
          ref(
            FirebaseProvider.db,
            `users/${FirebaseProvider.auth.currentUser?.uid}/authInfo/accountDisableReason`,
          ),
          (snap) => {
            if (snap.exists()) {
              this.setState({ accountDisableReason: snap.val() });
            }
          },
        ),
      );
    });
  }

  componentWillUnmount(): void {
    if (this.onAuthStateChanged) this.onAuthStateChanged();
    if (this.bannerListenerCanceller) this.bannerListenerCanceller();

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    while (this.listenerCancellers.length) this.listenerCancellers.pop()!();
  }

  querry(
    path: string,
    callback: (snap: DataSnapshot) => unknown,
    onlyOnce = false,
    unsub: Unsubscribe[] | Unsubscribe | null = null,
    queryConstraint: QueryConstraint | null = null,
  ): Unsubscribe {
    const uns = onValue(
      queryConstraint != null
        ? query(ref(FirebaseProvider.db, path), queryConstraint)
        : ref(FirebaseProvider.db, path),
      (snap) => callback(snap),
      { onlyOnce: onlyOnce },
    );

    if (unsub == null) return uns;

    if (Array.isArray(unsub)) {
      unsub.push(uns);
    } else {
      unsub = uns;
    }

    return uns;
  }

  render() {
    return (
      <div
        className="urgent-notification-background"
        style={{
          display:
            (!this.state.accountEnabled || this.state.genericMessageShow) &&
            !this.state.isClosed
              ? "flex"
              : "none",
        }}
      >
        <span>
          {this.state.accountEnabled
            ? this.state.genericMessage
            : this.state.accountDisableReason}
        </span>
        <button
          onClick={() =>
            this.setState({
              isClosed: true,
            })
          }
        >
          <Icons.CloseSquare size={30} fill="white"></Icons.CloseSquare>
        </button>
      </div>
    );
  }
}
