import { PureComponent } from "react";
import Icons from "../../Icons/svg-icon";
import "./ContactBar.css";
import { Informations } from "../Informations";

export class ContactBar extends PureComponent {
  render() {
    return (
      <div className="ContactBar" id="page">
        <div className="Contact">
          <a target="_blank" href={Informations.mail_url} rel="noreferrer">
            <Icons.Mail size={20} fill="#F4F4F4" />
            <div className="Text">{Informations.mail_name}</div>
          </a>
          <a href={Informations.telephone_url}>
            <Icons.Calling size={20} fill="#F4F4F4" />
            <div className="Text">{Informations.telephone}</div>
          </a>
        </div>
        <div className="ContactBar-SocialMedia">
          <a target="_blank" href={Informations.facebook_url} rel="noreferrer">
            <Icons.Facebook size={20} fill="#F4F4F4" />
          </a>
          <a target="_blank" href={Informations.instagram_url} rel="noreferrer">
            <Icons.Instagram size={20} fill="#F4F4F4" />
          </a>
          <a target="_blank" href={Informations.youtube_url} rel="noreferrer">
            <Icons.Youtube size={20} fill="#F4F4F4" />
          </a>
        </div>
      </div>
    );
  }
}

export default ContactBar;
