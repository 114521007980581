import searchQuery, { SearchParserOptions } from "search-query-parser";
import { PhpQuery } from "./pagedResource";

export interface SearchMapping {
  [key: string]: PhpQuery;
}
export interface QueryMapping {
  opts: SearchParserOptions;
  queryMapping: SearchMapping;
  defaultMapping: PhpQuery;
}

export class SearchHelper {
  parseOpts: searchQuery.SearchParserOptions;
  mapping: SearchMapping;
  defaultMapping: PhpQuery;

  constructor(
    options: searchQuery.SearchParserOptions,
    mapping: SearchMapping,
    defaultMapping: PhpQuery,
  ) {
    this.parseOpts = { ...options, alwaysArray: true };
    this.mapping = mapping;
    this.defaultMapping = JSON.parse(JSON.stringify(defaultMapping));
  }

  public set queryMapping(v: QueryMapping) {
    this.parseOpts = { ...v.opts, alwaysArray: true };
    this.mapping = v.queryMapping;
    this.defaultMapping = v.defaultMapping;
  }

  sortQuery(q: PhpQuery): PhpQuery {
    const o: PhpQuery = {};

    for (const key of Object.keys(q).sort()) {
      o[key] = q[key];
    }

    return o;
  }

  map(q: string, donttrim = false): PhpQuery {
    const oQuery: PhpQuery = {};

    const parsed = searchQuery.parse(q, this.parseOpts);

    if (typeof parsed === "string") {
      const qmarkKey = this.findQmark(this.defaultMapping);
      this.defaultMapping[qmarkKey].v = this.switchType(
        parsed,
        this.defaultMapping[qmarkKey].t!,
      );

      return this.defaultMapping;
    }

    for (const k of this.parseOpts.keywords ?? []) {
      const f = this.mapping[k];

      if (!f || parsed[k] === undefined) {
        continue;
      }

      const targetKey = this.findQmark(f);

      f[targetKey].v = this.switchType(
        (parsed[k] as string[]).join(","),
        f[targetKey].t!,
      );
      this.copyObj(oQuery, f);
    }

    for (const k of this.parseOpts.ranges ?? []) {
      const f: { from: string; to: string } | undefined = parsed[k];

      if (!f || !this.mapping[k + "from"] || !this.mapping[k + "to"]) {
        continue;
      }

      if (f.from) {
        const ofrom = this.mapping[k + "from"];
        const targetKey = this.findQmark(ofrom);
        ofrom[targetKey].v = this.switchType(f.from, ofrom[targetKey].t!);
        this.copyObj(oQuery, ofrom);
      }

      if (f.to) {
        const oto = this.mapping[k + "to"];
        const targetKey = this.findQmark(oto);
        oto[targetKey].v = this.switchType(f.to, oto[targetKey].t!);
        this.copyObj(oQuery, oto);
      }
    }

    return donttrim ? oQuery : this.trimLastAnd(oQuery);
  }

  padAnds(oQuery: PhpQuery) {
    for (const k of this.parseOpts.keywords ?? []) {
      const f = this.mapping[k];

      if (!f) {
        continue;
      }

      const keys = Object.keys(f);

      if (keys[0] in oQuery) {
        const tmp: PhpQuery = {};
        tmp[keys[keys.length - 1]] = f[keys[keys.length - 1]];
        this.copyObj(oQuery, tmp);
      }
    }

    for (const k of this.parseOpts.ranges ?? []) {
      if (this.mapping[k + "from"]) {
        const f = this.mapping[k + "from"];
        const keys = Object.keys(f);

        if (keys[0] in oQuery) {
          const tmp: PhpQuery = {};
          tmp[keys[keys.length - 1]] = f[keys[keys.length - 1]];
          this.copyObj(oQuery, tmp);
        }
      }

      if (this.mapping[k + "to"]) {
        const f = this.mapping[k + "to"];
        const keys = Object.keys(f);

        if (keys[0] in oQuery) {
          const tmp: PhpQuery = {};
          tmp[keys[keys.length - 1]] = f[keys[keys.length - 1]];
          this.copyObj(oQuery, tmp);
        }
      }
    }
  }

  padLastAnd(oQuery: PhpQuery, prop: string) {
    const qkeys = Object.keys(oQuery);
    const mappingKeys = Object.keys(this.mapping);

    for (
      let index = mappingKeys.indexOf(prop);
      index < mappingKeys.length;
      index++
    ) {
      const indexObj = this.mapping[mappingKeys[index]];
      const indexKeys = Object.keys(indexObj);

      if (qkeys.indexOf(indexKeys[0]) != -1) {
        oQuery[indexKeys[indexKeys.length - 1]] =
          indexObj[indexKeys[indexKeys.length - 1]];
      }
    }
  }

  trimLastAnd(oQuery: PhpQuery): PhpQuery {
    const q = this.sortQuery(oQuery);

    const okeys = Object.keys(q);

    for (let i = okeys.length - 1; i >= 0; i--) {
      if (
        q[okeys[i]].op === "and" &&
        (i === okeys.length - 1 || q[okeys[i + 1]].op !== "qparam")
      ) {
        delete q[okeys[i]];
      }
    }

    return q;
  }

  injectToQuery(q: PhpQuery, prop: { [key: string]: string }): PhpQuery {
    for (const k of Object.keys(prop)) {
      const f = this.mapping[k];

      if (!f || prop[k] === undefined) {
        continue;
      }

      const targetKey = this.findQmark(f);

      f[targetKey].v = this.switchType(prop[k], f[targetKey].t!);

      this.copyObj(q, f);
    }

    this.padAnds(q);

    return this.trimLastAnd(q);
  }

  deleteFromQuery(q: PhpQuery, propNames: string[]): PhpQuery {
    for (const k of propNames) {
      const f = this.mapping[k];

      if (!f) {
        continue;
      }

      for (const key of Object.keys(f)) {
        delete q[key];
      }
    }

    return this.trimLastAnd(q);
  }

  findQmark(q: PhpQuery): string {
    for (const k of Object.keys(q)) {
      if (q[k].op === "qmark") {
        return k;
      }
    }

    throw new Error("not found");
  }

  getType(k: unknown) {
    switch (typeof k) {
      case "string":
        return "s";
      case "boolean":
        return "b";
      case "number":
        return "i";
    }
  }

  switchType(k: string, t: string) {
    switch (t) {
      case "i":
        return parseInt(k);
      case "s":
        return k;
      case "b":
        return Boolean(k);
    }
  }

  copyObj(
    target: { [key: string]: unknown },
    src: { [key: string]: unknown },
    doLast?: boolean,
  ) {
    const keys = Object.keys(src);
    for (const key of doLast ? keys.slice(0, -1) : keys) {
      target[key] = src[key];
    }
  }
}
