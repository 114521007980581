import { Component, createRef } from "react";
import { Link, NavLink } from "react-router-dom";
import Icons from "../../Icons/svg-icon";
import "./NewsSearchArea.css";

import {
  ENDPOINT_BASE,
  PagedResource,
} from "../../BackendHelpers/pagedResource";
import { Informations } from "../Informations";

const MOST_READ_QUERY = `${ENDPOINT_BASE}/mostReadNews.php`;
const NEW_QUERY = `${ENDPOINT_BASE}/newNews.php`;

interface State {
  responsive: boolean;
  isFocused: boolean;
  searching: boolean;
  pageIndex: number;
  err: boolean;
}

interface NewSearchResult {
  id: number;
  title: string;
  img: string;
}

const mostRead: JSX.Element[] = [];
let mostReadFetched = false;
const newNews: JSX.Element[] = [];
let newNewsFetched = false;

class NewsSearchManager extends PagedResource<NewSearchResult> {
  ENDPOINT = `${ENDPOINT_BASE}/newsSearchQuery.php`;
  ITEM_COUNT_PER_PAGE = 3;

  itemBuilder(e: NewSearchResult) {
    return (
      <Link to={`/haberler/${e.id}`} className="sub-news" key={e.id}>
        <img src={e.img} alt="" />
        <span>{e.title}</span>
        <hr />
      </Link>
    );
  }
}

export const mngr = new NewsSearchManager();

export default class NewsSearchArea extends Component<object, State> {
  searching = false;

  typingTimeout: NodeJS.Timeout | null = null;
  lastSearch = "";
  searchBarRef = createRef<HTMLInputElement>();

  scrollPoller: NodeJS.Timer | null = null;
  searchResults = createRef<HTMLDivElement>();

  constructor(props: object) {
    super(props);
    this.state = {
      responsive: window.innerWidth < 750,
      isFocused: false,
      pageIndex: 0,
      searching: false,
      err: false,
    };
  }

  componentDidMount(): void {
    window.addEventListener("resize", this.resizer);

    if (!this.scrollPoller) {
      this.scrollPoller = setInterval(() => {
        if (this.searchResults.current) {
          if (
            this.searchResults.current.offsetHeight +
              this.searchResults.current.scrollTop >=
              this.searchResults.current.scrollHeight - 600 &&
            mngr.loadedItems.length < 3
          ) {
            mngr.incrementPage(this, { q: this.lastSearch });
          }
        }
      }, 100);
    }

    this.fetchMostRead();
    this.fetchNew();
  }

  componentWillUnmount(): void {
    window.removeEventListener("resize", this.resizer);
    if (this.scrollPoller) {
      clearInterval(this.scrollPoller);
      this.scrollPoller = null;
    }
  }

  resizer = () => {
    if (this.state.responsive != window.innerWidth < 750) {
      this.setState({
        responsive: window.innerWidth < 750,
      });
    }
  };

  async fetchMostRead() {
    if (mostReadFetched) return;
    mostReadFetched = true;

    let resp;
    try {
      resp = await fetch(MOST_READ_QUERY, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });
    } catch {
      this.setState({ err: true });
      throw new Error("most read fetch failed");
    }

    if (resp.status !== 200) {
      this.setState({ err: true });
      throw new Error("most read fetch failed");
    }

    const json: { r: NewSearchResult[] } = await resp.json();

    mostRead.length = 0;
    json.r.forEach((val) => {
      mostRead.push(mngr.itemBuilder(val));
    });

    this.setState({});
  }

  async fetchNew() {
    if (newNewsFetched) return;
    newNewsFetched = true;

    let resp;
    try {
      resp = await fetch(NEW_QUERY, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });
    } catch {
      this.setState({ err: true });
      throw new Error("new fetch failed");
    }

    if (resp.status !== 200) {
      throw new Error("new fetch failed");
    }

    const json: { r: NewSearchResult[] } = await resp.json();

    newNews.length = 0;
    json.r.forEach((val) => {
      newNews.push(mngr.itemBuilder(val));
    });

    this.setState({});
  }

  search() {
    if (this.searching || this.state.pageIndex === undefined) return;

    this.searching = true;
    if (this.typingTimeout) clearTimeout(this.typingTimeout);
    if (this.lastSearch === this.searchBarRef.current?.value) {
      this.searching = false;
      return;
    }

    mngr.clear();
    this.lastSearch = this.searchBarRef.current?.value ?? "";

    mngr
      .loadPage(0, this, { q: this.lastSearch })
      .then((res) => {
        this.searching = false;
        this.setState({});
      })
      .catch(() => {
        this.searching = false;
        this.setState({ err: true });
      });
  }

  setupTypingTimeout() {
    if (this.typingTimeout) clearTimeout(this.typingTimeout);
    this.typingTimeout = setTimeout(() => {
      this.search();
    }, 500);
  }

  render() {
    return !this.state.err ? (
      <div className="news-search-area">
        <NavLink className="logo" to={"/"}>
          <img src={Informations.logo} alt="" />
        </NavLink>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <button
            style={{
              height: "30px",
              width: "min-content",
              backgroundColor: "transparent",
              border: "none",
              display:
                this.state.isFocused && this.state.responsive ? "flex" : "none",
              alignItems: "center",
            }}
            onClick={() => {
              if (this.state.responsive) {
                this.setState({ isFocused: false, searching: false });
              }
            }}
          >
            <Icons.CloseSquare size={24}></Icons.CloseSquare>
          </button>
          <div className="search-bar">
            <button
              onClick={() => {
                this.setState({ isFocused: true, searching: true });
                this.search();
              }}
            >
              <Icons.Search
                className="Icon"
                size={16}
                fill={"#17161A"}
                style={{ overflow: "hidden" }}
              ></Icons.Search>
            </button>
            <input
              type="text"
              placeholder={"Ara"}
              onFocus={() => {
                this.setState({ isFocused: true, searching: true });
                this.search();
              }}
              onChange={(e) => {
                if (e.target.value === "") {
                  mngr.clear();
                  this.setState({ searching: false });
                } else {
                  this.setupTypingTimeout();
                  this.setState({ searching: true });
                }
              }}
              ref={this.searchBarRef}
            />
          </div>
        </div>
        <div
          className="news-list"
          style={{ display: this.state.searching ? "flex" : "none" }}
          ref={this.searchResults}
        >
          {mngr.loadedItems.length ? (
            mngr.loadedItems
          ) : (
            <span className="not-found-news">Sonuç bulunamadı</span>
          )}
        </div>
        {mostRead.length ? (
          <div
            className="news-list"
            style={{
              display:
                (!this.state.isFocused && this.state.responsive) ||
                this.state.searching
                  ? "none"
                  : "flex",
            }}
          >
            <li>
              <div className="stroke"></div>
              Çok Okunan Haberler
            </li>
            <div className="news-list-2">{mostRead}</div>
          </div>
        ) : null}
        {newNews.length ? (
          <div
            className="news-list"
            style={{
              display:
                (!this.state.isFocused && this.state.responsive) ||
                this.state.searching
                  ? "none"
                  : "flex",
            }}
          >
            <li>
              <div className="stroke"></div>
              Yeni Haberler
            </li>
            <div className="news-list-2">{newNews}</div>
          </div>
        ) : null}
      </div>
    ) : (
      <span className="not-found-news">
        Yükleme esnasında bir problem oluştu
      </span>
    );
  }
}
