export async function fetchHtmlPage(page: string) {
  const resp = await fetch(page, {
    method: "GET",
    headers: {
      Accept: "text/plain",
    },
  });

  if (resp.status !== 200) {
    throw new Error("fetch failed");
  }

  return resp.body;
}
