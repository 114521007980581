import { Component } from "react";
import "./HowToBid.css";

import learn from "../../Images/learn.png";
import { Informations } from "../Informations";

interface State {
  index: number;
}

type Props = {
  children?: undefined;
};

export default class HowToBid extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      index: 0,
    };
  }

  render() {
    return (
      <div className="HowToBid-background">
        <div className="options">
          <div className="line"></div>
          <div className="button-row">
            <button
              id="button-0"
              style={{
                color: this.state.index == 0 ? "white" : "#D02224",
                backgroundColor:
                  this.state.index == 0 ? "#D02224" : "transparent",
              }}
              onClick={() => {
                this.setState({ index: 0 });
              }}
            >
              Nasıl Teklif Verilir
            </button>
            <button
              id="button-1"
              style={{
                color: this.state.index == 1 ? "white" : "#D02224",
                backgroundColor:
                  this.state.index == 1 ? "#D02224" : "transparent",
              }}
              onClick={() => {
                this.setState({ index: 1 });
              }}
            >
              Nasıl İlan Verilir
            </button>
          </div>
        </div>
        <div className="viewport">
          <div
            className="content"
            style={{
              position: "relative",
              right: this.state.index * 100 + "%",
            }}
          >
            <div id="content-0">
              <div id="image-area">
                <img src={learn} alt="" />
              </div>
              <div id="inf-area">
                <div>
                  <li className="title">
                    Adım 1: <li>{Informations.how_to_bid_step_one_title}</li>
                  </li>
                  <li className="paragraph">
                    {Informations.how_to_bid_step_one}
                  </li>
                </div>
                <div>
                  <li className="title">
                    Adım 2: <li>{Informations.how_to_bid_step_two_title}</li>
                  </li>
                  <li className="paragraph">
                    {Informations.how_to_bid_step_two}
                  </li>
                </div>
                <div>
                  <li className="title">
                    Adım 3: <li>{Informations.how_to_bid_step_three_title}</li>
                  </li>
                  <li className="paragraph">
                    {Informations.how_to_bid_step_three}
                  </li>
                </div>
              </div>
            </div>
            <div id="content-0">
              <div id="image-area">
                <img src={learn} alt="" />
              </div>
              <div id="inf-area">
                <div>
                  <li className="title">
                    Adım 1: <li>{Informations.how_to_ask_step_one_title}</li>
                  </li>
                  <li className="paragraph">
                    {Informations.how_to_ask_step_one}
                  </li>
                </div>
                <div>
                  <li className="title">
                    Adım 2: <li>{Informations.how_to_ask_step_two_title}</li>
                  </li>
                  <li className="paragraph">
                    {Informations.how_to_ask_step_two}
                  </li>
                </div>
                <div>
                  <li className="title">
                    Adım 3: <li>{Informations.how_to_ask_step_three_title}</li>
                  </li>
                  <li className="paragraph">
                    {Informations.how_to_ask_step_three}
                  </li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
