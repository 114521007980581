/* eslint-disable no-useless-catch */
import { httpsCallable } from "@firebase/functions";
import { FirebaseError } from "@firebase/util";
import { signOut } from "firebase/auth";
import { loadingStore } from "../stores/LoadingStore";
import { verificationStore } from "../stores/verificationOverlayStore";
import { FirebaseProvider } from "./firebaseProvider";
import { KeyHelper } from "./key";

const MFA_TOKEN_TTL = 4000;
export const MFA_CHANNELLED_TIMEOUT = 60000;

export interface MFAToken {
  token_id: string;
  iat: number;
  exp: number;
  sub: string;
  device: string;
}

export class MFAProvider {
  static mfaToken: string | undefined;
  static mfaTokenTakeTime = 0;

  static mfaResolvePromise:
    | Promise<{
        message: string;
        token: string;
        code: number;
      } | void>
    | undefined;
  static mfaRegisterKeys: boolean | undefined;
  static mfaResolver:
    | ((
        arg:
          | void
          | {
              message: string;
              token: string;
              code: number;
            }
          | PromiseLike<void | {
              message: string;
              token: string;
              code: number;
            }>,
      ) => void)
    | undefined;
  static mfaRejecter: ((arg: Error) => void) | undefined;
  static hasPendingChanneledMFA = false;
  static passwdReset = false;

  static mfaTarget: string | undefined; // has outside access by VerificationPage.
  static mfaTitle: string | undefined = "İki Aşamalı Doğrulama"; // has outside access by VerificationPage.

  static async requestChannelledMFA(
    title: string,
    target?: string,
    rerequest = false,
    registerKeys = false,
  ) {
    if (!target) {
      if (!FirebaseProvider.auth.currentUser?.phoneNumber) {
        throw new EmailMissing();
      }

      target = FirebaseProvider.auth.currentUser.phoneNumber;
    }

    if (
      new Date().getTime() -
        parseInt(localStorage.getItem("lastMFAReqTime") ?? "NaN") <
      180000
    ) {
      // if mfa req is done in last 3 minutes
      this.bootstrapMFAParamsAndShow(registerKeys, title, target, true);
      return;
    }

    if (!rerequest && this.hasPendingChanneledMFA) {
      this.bootstrapMFAParamsAndShow(registerKeys, title, target, true);

      return;
    }

    try {
      const res = await httpsCallable(
        FirebaseProvider.functions,
        "requestChanneledMFA",
      )({ target: target, deviceId: FirebaseProvider.deviceId });

      // @ts-expect-error code is fine
      if (res.data.code !== 200) {
        // @ts-expect-error data is filled with message and code.
        throw new MFARequestFailed(res.data.message);
      }
    } catch (e) {
      throw e;
      // if (e instanceof MFARequestFailed) throw e;
      // throw new MFARequestFailed("INTERNAL");
    }

    if (!this.hasPendingChanneledMFA) {
      this.bootstrapMFAParamsAndShow(registerKeys, title, target);
    }
  }

  static bootstrapMFAParamsAndShow(
    registerKeys: boolean,
    title: string,
    target?: string,
    rebootstrap?: boolean,
    passwdReset?: boolean,
  ) {
    this.mfaTarget = target;
    this.hasPendingChanneledMFA = true;
    this.mfaRegisterKeys = registerKeys;
    this.passwdReset = passwdReset ?? false;
    this.mfaResolvePromise = new Promise<void | {
      message: string;
      token: string;
      code: number;
    }>((resolve, reject) => {
      this.mfaResolver = resolve;
      this.mfaRejecter = reject;
    });

    if (!rebootstrap) {
      localStorage.setItem("lastMFAReqTime", new Date().getTime().toString());
    }

    this.showMFAScreen(title);
  }

  static showMFAScreen(title: string) {
    if (this.hasPendingChanneledMFA) {
      this.mfaTitle = title;
      verificationStore.dispatch({ type: "open" });
      loadingStore.dispatch({ type: "close" });
    } else {
      console.warn("MFA page show while there is no mfa?");
    }
  }

  static rejectPendingMFA() {
    if (this.hasPendingChanneledMFA) {
      verificationStore.dispatch({ type: "closed" });

      this.mfaRejecter!(new MFAError("rejected"));
      this.hasPendingChanneledMFA = false;
    }
  }

  /**
   * Solves channeled mfa challenge.
   * @param {string} resp challenge data given by the user in the sms
   */
  static async resolveChannelledMFA(resp: string) {
    if (!this.hasPendingChanneledMFA) {
      throw new MFASolutionDenied("does not have a pending mfa");
    }

    loadingStore.dispatch({ type: "open" });

    const solver = httpsCallable<
      unknown,
      {
        message: string;
        token: string;
        code: number;
      }
    >(
      FirebaseProvider.functions,
      !this.passwdReset ? "resolveChanneledMFA" : "getPasswordResetToken",
    );

    try {
      const result = await solver({
        target: this.mfaTarget,
        answer: resp,
        deviceId: FirebaseProvider.deviceId,
      });

      this.hasPendingChanneledMFA = false;

      if (this.mfaRegisterKeys) {
        await this.registerKey();
        await this.doKeyedMFA();
      }

      this.mfaTarget = undefined;
      this.mfaTitle = undefined;

      loadingStore.dispatch({ type: "close" });
      localStorage.removeItem("lastMFAReqTime");
      this.mfaResolver!(result.data);
    } catch (e) {
      loadingStore.dispatch({ type: "close" });

      if (e instanceof FirebaseError) {
        if (e.code === "functions/permission-denied") {
          throw new Error("Cevap kabul edilmedi.");
        }
        if (e.code === "functions/invalid-argument") {
          throw new Error("Cevap geçersiz.");
        }
        if (e.code == "functions/resource-exhausted") {
          localStorage.removeItem("lastMFAReqTime");
          throw new Error("Kota Aşıldı. Daha sonra tekrar deneyin.");
        }
      }

      localStorage.removeItem("lastMFAReqTime");
      this.rejectPendingMFA();
      throw new MFASolutionDenied("INTERNAL");
    }
  }

  static async doKeyedMFA() {
    await this.resolveKeyedMFA(await this.requestKeyedMFA());
  }

  static async requestKeyedMFA() {
    const requester = httpsCallable(
      FirebaseProvider.functions,
      "requestKeyedMFA",
    );

    try {
      const res = await requester({
        deviceId: FirebaseProvider.deviceId,
      });

      // @ts-expect-error data contains code
      if (res.data.code !== 200) {
        // @ts-expect-error data contains message
        throw new MFARequestFailed(res.data.message);
      }

      // @ts-expect-error data contains challenge at this point.
      return res.data.challenge;
    } catch (e) {
      if (e instanceof MFARequestFailed) throw e;
      throw new MFARequestFailed("INTERNAL");
    }
  }

  static async resolveKeyedMFA(challenge: string) {
    const resolver = httpsCallable(
      FirebaseProvider.functions,
      "resolveKeyedMFA",
    );
    const signature = await KeyHelper.sign(challenge);

    try {
      const res = await resolver({
        signature,
        deviceId: FirebaseProvider.deviceId,
      });
      // @ts-expect-error res.data contains code
      if (res.data.code !== 200) {
        // @ts-expect-error res.data contains message
        throw new MFASolutionDenied(res.data.message);
      }

      // @ts-expect-error res.data contains token in here
      this.mfaToken = res.data.token;
      this.mfaTokenTakeTime = new Date().getTime();
      return this.mfaToken;
    } catch (e) {
      if (e instanceof MFASolutionDenied) throw e;
      throw new MFASolutionDenied("INTERNAL");
    }
  }

  static async registerKey() {
    const keyRegistrar = httpsCallable(
      FirebaseProvider.functions,
      "registerMFAKey",
    );

    let key: CryptoKeyPair | undefined;
    try {
      key = await KeyHelper.getKey();
    } catch {
      key = await KeyHelper.generateSigningKey();
    }

    try {
      const res = await keyRegistrar({
        pk: JSON.stringify(
          await window.crypto.subtle.exportKey("jwk", key.publicKey),
        ),
        deviceId: FirebaseProvider.deviceId,
      });

      // @ts-expect-error data contains code
      if (res.data.code !== 200) {
        // @ts-expect-error data contains message
        throw new KeyRegistryFailed(res.data.message);
      }
    } catch (e) {
      if (e instanceof KeyRegistryFailed) throw e;
      throw new KeyRegistryFailed("INTERNAL");
    }
  }

  /**
   * ensures mfa for given phone number and the user.
   * @param {boolean} force should discard the token inside
   */
  static async ensureMFA(force = false) {
    if (
      !FirebaseProvider.auth.currentUser ||
      !FirebaseProvider.auth.currentUser.phoneNumber
    ) {
      throw new AuthMissing();
    }

    if (
      this.mfaToken &&
      this.mfaTokenTakeTime + MFA_TOKEN_TTL > new Date().getTime() &&
      !force
    ) {
      return;
    }

    try {
      await KeyHelper.getKey();
      await this.doKeyedMFA();
    } catch {
      await KeyHelper.generateSigningKey();
      KeyHelper.saveKey(await KeyHelper.getKey());

      try {
        await this.requestChannelledMFA("İki Faktör Doğrulama");
        loadingStore.dispatch({ type: "close" });
        await this.mfaResolvePromise;
      } catch (e) {
        signOut(FirebaseProvider.auth);
        throw e;
      }

      loadingStore.dispatch({ type: "open" });
      await this.registerKey();
      await this.doKeyedMFA();
    }
  }
}

export class MFAError extends Error {}

/**
 * thrown when ensure mfa is called without login.
 */
export class AuthMissing extends MFAError {}

/**
 * thrown when ensuremfa is unable to determine the users phone number.
 */
export class EmailMissing extends MFAError {}

/**
 * thrown when a mfa request is ended with non good code.
 */
export class MFARequestFailed extends MFAError {}

/**
 * thrown when a mfa resolve is denied.
 */
export class MFASolutionDenied extends MFAError {}

/**
 * thrown when a mfa request is timed out.
 */
export class MFATimedOut extends MFAError {}

/**
 * thrown when device key registry is failed.
 */
export class KeyRegistryFailed extends MFAError {}

/**
 * thrown when a channelled mfa is requested while some other is pending.
 */
export class PendingMFAError extends MFAError {}
