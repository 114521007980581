import React, { Component } from "react";

import { onValue, ref } from "firebase/database";
import { FirebaseProvider } from "../../BackendHelpers/firebaseProvider";
import {
  ENDPOINT_BASE,
  PhpQuery,
  _PagedResource,
} from "../../BackendHelpers/pagedResource";
import { SearchHelper } from "../../BackendHelpers/searchHelper";
import {
  auctionQuery,
  groupAuctionQuery,
} from "../../BackendHelpers/searchQueries";
import ArchiveGroup from "../../Components/Auction/ArchiveAuction";
import Auction from "../../Components/Auction/Auction";
import GroupAuction from "../../Components/Auction/GroupAuction";
import { Informations } from "../../Components/Informations";
import TitlePhoto from "../../Components/Photo/TitlePhoto";
import Icons from "../../Icons/svg-icon";
import "./AuctionsLayout.css";

export enum AuctionMode {
  auction,
  groupAuction,
  archive,
}

export enum AuctionLayoutShowMode {
  all = -1,
  continuing = 0,
  almostDone,
  expired,
}

interface Props {
  title: string;
  canFilter: boolean;
  auctionMode: AuctionMode;
}

interface State {
  mode: AuctionLayoutShowMode;
  loadedItems: (AuctionData | GroupAuctionData)[];
  searchQuery: PhpQuery;
  pageError?: unknown;
}

export interface AuctionData {
  "`auctions`.`id`": string;
  "`auctions`.`name`": string;
  "`auctions`.`description`": string;
  "`auctions`.`status`": number;
  "`auctions`.`pigeon`": string;
  "`auctions`.`endingTime`": number;
  "`auctions`.`parent`": string;
  "`auctions`.`amount`": number;
  "`auctions`.`startingBid`": number;
  "`pigeons`.`tagNum`": string;
  "`users`.`fullname`": string;
}

export interface GroupAuctionData {
  id: string;
  name: string;
  description: string;
  status: number;
  photo: string;
  parent: string;
  endingTime: number;
}

const _baseQuery: PhpQuery = {
  0: {
    op: "where",
  },
  yx0parent: {
    op: "qparam",
    v: "parent",
  },
  yx0parent1: {
    op: "e",
  },
  yx0parent2: {
    op: "qmark",
    t: "s",
    v: "aaa",
  },
  yz1: { op: "orderby" },
  yz2: { op: "qparam", v: "endingTime" },
  yz3: { op: "orderbyasc" },
};

const mngr = new _PagedResource<AuctionData | GroupAuctionData>(
  `${ENDPOINT_BASE}/auctionQuery.php`,
  20,
);
let mngrLastMode: AuctionMode | undefined;
let lastShowMode: AuctionLayoutShowMode | undefined;
let finished = false;

const _loadedItems: (AuctionData | GroupAuctionData)[] = [];

export default class AuctionsLayout extends Component<Props, State> {
  pageError: unknown | undefined;
  mode: AuctionMode = AuctionMode.auction;
  scrollPoller: NodeJS.Timer | undefined;

  searchRef: React.RefObject<HTMLInputElement> = React.createRef();
  searchHelper: SearchHelper = new SearchHelper(
    auctionQuery.opts,
    auctionQuery.queryMapping,
    auctionQuery.defaultMapping,
  );

  itemsRef = React.createRef<HTMLDivElement>();

  loadingPromise: Promise<(AuctionData | GroupAuctionData)[]> | undefined;

  constructor(props: Props) {
    super(props);

    if (!lastShowMode) {
      lastShowMode = AuctionLayoutShowMode.continuing;
    }

    this.state = {
      mode: lastShowMode,
      loadedItems: this.mode === props.auctionMode ? _loadedItems : [],
      searchQuery: this.setupSearchQuery(this.props.auctionMode),
    };
  }

  async setupMngrMode() {
    let mngrMode: AuctionMode;

    switch (this.props.auctionMode) {
      case AuctionMode.auction:
        mngr.ENDPOINT = `${ENDPOINT_BASE}/auctionQuery.php`;
        mngrMode = AuctionMode.auction;
        break;
      case AuctionMode.archive:
      case AuctionMode.groupAuction: {
        const mode = await this.determineFinalContainer(
          this.props.auctionMode === AuctionMode.archive
            ? Informations.auction_archive_id
            : Informations.auction_vitrine_id,
        );

        switch (mode) {
          case "auction":
            mngr.ENDPOINT = `${ENDPOINT_BASE}/auctionQuery.php`;
            mngrMode = AuctionMode.auction;
            break;
          case "group":
            mngr.ENDPOINT = `${ENDPOINT_BASE}/auctionContainerQuery.php`;
            mngrMode = AuctionMode.groupAuction;

            break;
        }
        break;
      }
    }

    mngrLastMode = this.props.auctionMode;
    return mngrMode;
  }

  setupSearchQuery(auctionMode: AuctionMode) {
    let searchQuery: PhpQuery;

    switch (auctionMode) {
      case AuctionMode.auction:
        searchQuery = {
          0: { op: "where" },
          yz1: { op: "orderby" },
          yz2: { op: "qparam", v: "`auctions`.`endingTime`" },
          yz3: { op: "orderbyasc" },
        };
        break;
      case AuctionMode.archive:
        searchQuery = {
          ..._baseQuery,
          yx0parent2: {
            op: "qmark",
            t: "s",
            v: Informations.auction_archive_id,
          },
        };
        break;
      case AuctionMode.groupAuction:
        searchQuery = {
          ..._baseQuery,
          yx0parent2: {
            op: "qmark",
            t: "s",
            v: Informations.auction_vitrine_id,
          },
        };
        break;
      default:
        throw new Error("unknown layout mode");
    }

    return searchQuery;
  }

  setupSearchHelperQueryMapping(mode: AuctionMode) {
    this.searchHelper.queryMapping =
      mode === AuctionMode.auction ? auctionQuery : groupAuctionQuery;
  }

  reloadAfterModeUpdate() {
    if (mngrLastMode !== this.props.auctionMode) {
      _loadedItems.length = 0;

      mngr.setWindowToStart();
      finished = false;

      this.setState({
        loadedItems: [],
        pageError: undefined,
      });
    }

    if (!this.scrollPoller) {
      console.log("assignin scroll poller");
      this.scrollPoller = setInterval(() => this.loadNextIfNecessary(), 100);
    }

    mngrLastMode = this.props.auctionMode;
  }

  componentDidMount(): void {
    this.reloadAfterModeUpdate();
  }

  componentDidUpdate(): void {
    this.reloadAfterModeUpdate();
  }

  componentWillUnmount(): void {
    if (this.scrollPoller) {
      clearInterval(this.scrollPoller);
      console.log("umount cleared scroll poller");
      this.scrollPoller = undefined;
    }

    mngrLastMode = this.props.auctionMode;
  }

  determineFinalContainer(id: string) {
    return new Promise<"auction" | "group">((resolve, reject) => {
      onValue(
        ref(FirebaseProvider.db, `auctionContainers/${id}/isFinalContainer`),
        (_v) => {
          const v: unknown = _v.val();

          if (v === null || v === true) {
            resolve("auction");
          }

          if (v === false) {
            resolve("group");
          }
        },
        reject,
        { onlyOnce: true },
      );
    });
  }

  setupFilteringButtons(searchQuery: PhpQuery, mngrMode: AuctionMode) {
    this.setupSearchHelperQueryMapping(mngrMode);

    const currTime = new Date().getTime();

    searchQuery = this.searchHelper.deleteFromQuery(searchQuery, [
      "bitişfrom",
      "bitişto",
      "durumfrom",
      "durumto",
    ]);

    if (this.props.auctionMode !== AuctionMode.archive) {
      switch (lastShowMode) {
        case AuctionLayoutShowMode.all:
          searchQuery = this.searchHelper.injectToQuery(searchQuery, {
            durumfrom: "0",
            durumto: "5",
          });
          break;
        case AuctionLayoutShowMode.almostDone:
          searchQuery = this.searchHelper.injectToQuery(searchQuery, {
            bitişto: (currTime + 3600000).toString(),
            bitişfrom: currTime.toString(),
          });
          break;
        case AuctionLayoutShowMode.expired:
          searchQuery = this.searchHelper.injectToQuery(
            searchQuery,
            this.props.auctionMode === AuctionMode.auction
              ? { durumfrom: "3", durumto: "3" }
              : { bitişto: currTime.toString() },
          );
          break;
        case AuctionLayoutShowMode.continuing:
        default:
          searchQuery = this.searchHelper.injectToQuery(
            searchQuery,
            this.props.auctionMode === AuctionMode.auction
              ? { durumfrom: "2", durumto: "2" }
              : { bitişfrom: currTime.toString() },
          );
          break;
      }
    }

    return searchQuery;
  }

  async loadNextPage(searchQuery?: PhpQuery, dontReturnOnLoad?: boolean) {
    if (this.state.pageError) {
      clearInterval(this.scrollPoller);
      console.log("err cleared scroll poller");
      this.scrollPoller = undefined;

      throw this.state.pageError;
    }

    try {
      if (searchQuery) {
        const mngrMode = await this.setupMngrMode();

        searchQuery = this.setupFilteringButtons(searchQuery, mngrMode);

        if (mngrMode !== AuctionMode.auction) {
          searchQuery = this.searchHelper.injectToQuery(searchQuery, {
            grupihale:
              this.props.auctionMode === AuctionMode.archive
                ? Informations.auction_archive_id
                : Informations.auction_vitrine_id,
          });
        }

        if ("yx0parent" in searchQuery) {
          searchQuery["yx0parent"].v =
            mngrMode === AuctionMode.auction ? "`auctions`.`parent`" : "parent";
        }

        if ("yz2" in searchQuery) {
          searchQuery["yz2"].v =
            mngrMode === AuctionMode.auction
              ? "`auctions`.`endingTime`"
              : "endingTime";
        }

        mngr.windowStart = this.state.loadedItems.length;
        mngr.windowEnd = this.state.loadedItems.length + 20;

        if (!finished) {
          if (this.loadingPromise) {
            if (!dontReturnOnLoad) return;
          }

          if (!this.loadingPromise) {
            this.loadingPromise = mngr.loadNextPage(
              this.searchHelper.sortQuery(
                searchQuery ?? this.state.searchQuery,
              ),
            );
          }

          this.loadingPromise
            .then((resp) => {
              if (resp.length < 20) {
                console.log(resp);
                finished = true;
              }

              _loadedItems.push(...resp);

              this.setState({
                loadedItems: _loadedItems,
              });

              this.loadingPromise = undefined;
            })
            .catch((e) => {
              this.loadingPromise = undefined;

              this.setState({
                pageError: e,
              });
              throw e;
            });
        }
      }
    } catch (e: unknown) {
      this.setState({ pageError: e });
      throw e;
    }
  }

  itemBuilder(itm: AuctionData | GroupAuctionData) {
    if ("`auctions`.`id`" in itm) {
      return (
        <Auction
          key={(itm as AuctionData)["`auctions`.`id`"]}
          auctionId={(itm as AuctionData)["`auctions`.`id`"]}
          name={(itm as AuctionData)["`auctions`.`name`"]}
          ownerName={(itm as AuctionData)["`users`.`fullname`"]}
        />
      );
    }

    switch (this.props.auctionMode) {
      case AuctionMode.groupAuction:
        return (
          <GroupAuction
            key={(itm as GroupAuctionData).id}
            photo={(itm as GroupAuctionData).photo}
            id={(itm as GroupAuctionData).id}
            title={(itm as GroupAuctionData).name}
          ></GroupAuction>
        );
      case AuctionMode.archive:
        return (
          <ArchiveGroup
            key={(itm as GroupAuctionData).id}
            id={(itm as GroupAuctionData).id}
            photo={(itm as GroupAuctionData).photo}
            title={(itm as GroupAuctionData).name}
          ></ArchiveGroup>
        );
      default:
        throw new Error("invalid auction type");
    }
  }

  buildNotFound() {
    if (!finished) {
      return <div>Yükleniyor...</div>;
    }

    if (this.props.auctionMode == AuctionMode.auction) {
      return (
        <div className="items">
          <span className="not-found">Açık arttırma bulunamadı</span>
        </div>
      );
    } else if (this.props.auctionMode == AuctionMode.groupAuction) {
      return (
        <div className="items">
          <span className="not-found">Grup ihale bulunamadı</span>
        </div>
      );
    } else if (this.props.auctionMode == AuctionMode.archive) {
      return (
        <div className="items">
          <span className="not-found">Arşiv bulunamadı</span>
        </div>
      );
    }
  }

  clearItems(dontsetState?: boolean) {
    _loadedItems.length = 0;
    finished = false;
    mngr.setWindowToStart();

    if (!dontsetState) {
      this.setState({
        loadedItems: [],
      });
    }
  }

  loadNextIfNecessary = () => {
    if (this.itemsRef.current) {
      const itemsRect = this.itemsRef.current?.getBoundingClientRect();

      if (itemsRect.bottom < window.innerHeight && !mngr.loading && !finished) {
        this.loadNextPage(this.state.searchQuery, false);
      }
    }

    if (finished) {
      clearInterval(this.scrollPoller);
      console.log("finisher cleared scroll poller");
      this.scrollPoller = undefined;
      return;
    }
  };

  render() {
    if (this.props.auctionMode !== mngrLastMode) {
      this.reloadAfterModeUpdate();
      return;
    }

    return (
      <div id="page">
        <TitlePhoto title={this.props.title}></TitlePhoto>
        <div className="auctions-background">
          <div className="options-area">
            <div
              className="filter-buttons"
              style={{ display: this.props.canFilter ? undefined : "none" }}
            >
              <button
                onClick={() => {
                  let q: PhpQuery | undefined;
                  this.setState(
                    (state) => {
                      q = { ...state.searchQuery };

                      q = this.searchHelper.deleteFromQuery(q, [
                        "bitişfrom",
                        "bitişto",
                        "durumfrom",
                        "durumto",
                      ]);

                      q = this.searchHelper.injectToQuery(q, {
                        durumfrom: "0",
                        durumto: "5",
                      });

                      lastShowMode = AuctionLayoutShowMode.all;

                      return {
                        mode: AuctionLayoutShowMode.all,
                        searchQuery: q,
                      };
                    },
                    () => {
                      this.clearItems();
                      this.loadNextPage(q);
                    },
                  );
                }}
                className={
                  this.state.mode === AuctionLayoutShowMode.all
                    ? "selected"
                    : ""
                }
              >
                <div>
                  <Icons.All className="Icon" size={24} />
                </div>
                Tümü
              </button>
              <button
                onClick={() => {
                  let q: PhpQuery | undefined;
                  this.setState(
                    (state) => {
                      const currTime = new Date().getTime();

                      q = { ...state.searchQuery };

                      q = this.searchHelper.deleteFromQuery(q, [
                        "bitişfrom",
                        "bitişto",
                        "durumfrom",
                        "durumto",
                      ]);

                      q = this.searchHelper.injectToQuery(
                        q,
                        this.props.auctionMode === AuctionMode.auction
                          ? { durumfrom: "2", durumto: "2" }
                          : { bitişfrom: currTime.toString() },
                      );

                      lastShowMode = AuctionLayoutShowMode.continuing;

                      return {
                        mode: AuctionLayoutShowMode.continuing,
                        searchQuery: q,
                      };
                    },
                    () => {
                      this.clearItems();
                      this.loadNextPage(q);
                    },
                  );
                }}
                className={
                  this.state.mode === AuctionLayoutShowMode.continuing
                    ? "selected"
                    : ""
                }
              >
                <div>
                  <Icons.Report className="Icon" size={24} />
                </div>
                Devam Edenler
              </button>
              <button
                onClick={() => {
                  let q: PhpQuery | undefined;
                  this.setState(
                    (state) => {
                      q = { ...state.searchQuery };

                      q = this.searchHelper.deleteFromQuery(q, [
                        "bitişfrom",
                        "bitişto",
                        "durumfrom",
                        "durumto",
                      ]);

                      const currTime = new Date().getTime();
                      q = this.searchHelper.injectToQuery(q, {
                        bitişto: (currTime + 3600000).toString(),
                        bitişfrom: currTime.toString(),
                      });

                      lastShowMode = AuctionLayoutShowMode.almostDone;

                      return {
                        mode: AuctionLayoutShowMode.almostDone,
                        searchQuery: q,
                      };
                    },
                    () => {
                      this.clearItems();
                      this.loadNextPage(q);
                    },
                  );
                }}
                className={
                  this.state.mode === AuctionLayoutShowMode.almostDone
                    ? "selected"
                    : ""
                }
              >
                <div>
                  <Icons.TimeCircle className="Icon" size={24} />
                </div>
                Bitmek Üzere
              </button>
              <button
                onClick={() => {
                  let q: PhpQuery | undefined;
                  this.setState(
                    (state) => {
                      const currTime = new Date().getTime();

                      q = { ...state.searchQuery };

                      q = this.searchHelper.deleteFromQuery(q, [
                        "bitişfrom",
                        "bitişto",
                        "durumfrom",
                        "durumto",
                      ]);

                      q = this.searchHelper.injectToQuery(
                        q,
                        this.props.auctionMode === AuctionMode.auction
                          ? { durumfrom: "3", durumto: "3" }
                          : { bitişto: currTime.toString() },
                      );

                      lastShowMode = AuctionLayoutShowMode.expired;

                      return {
                        mode: AuctionLayoutShowMode.expired,
                        searchQuery: q,
                      };
                    },
                    () => {
                      this.clearItems();
                      this.loadNextPage(q);
                    },
                  );
                }}
                className={
                  this.state.mode === AuctionLayoutShowMode.expired
                    ? "selected"
                    : ""
                }
              >
                <div>
                  <Icons.InSecure className="Icon" size={24} />
                </div>
                Süresi Dolanlar
              </button>
            </div>
            <div className="search">
              <div className="search-bar">
                <button
                  onClick={() => {
                    this.setupMngrMode().then((mode) => {
                      const searchQuery = this.setupFilteringButtons(
                        this.state.searchQuery,
                        mode,
                      );

                      const mapped = this.searchHelper.map(
                        this.searchRef.current!.value!,
                        true,
                      );
                      this.searchHelper.padAnds(mapped);
                      this.searchHelper.copyObj(searchQuery, mapped);

                      console.log(searchQuery);
                      console.log(mapped);
                      console.log(this.searchHelper.trimLastAnd(searchQuery));

                      this.clearItems();
                      this.loadNextPage(
                        this.searchHelper.trimLastAnd(searchQuery),
                      );
                    });
                  }}
                >
                  <Icons.Search
                    className="Icon"
                    size={16}
                    fill={"#17161A"}
                    style={{ overflow: "hidden" }}
                  />
                </button>
                <input type="text" placeholder={"Ara"} ref={this.searchRef} />
              </div>
            </div>
          </div>
          {!this.state.pageError ? (
            <div className="items" ref={this.itemsRef}>
              {_loadedItems.length
                ? _loadedItems.map((v) => this.itemBuilder(v))
                : this.buildNotFound()}
            </div>
          ) : (
            <div>Bir Hata Oluştu</div>
          )}

          {/* <button className="more">Daha Fazla Gör</button> */}
        </div>
      </div>
    );
  }
}
