import { FirebaseError } from "@firebase/app";
import {
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { onValue, ref, set } from "firebase/database";
import { httpsCallable } from "firebase/functions";
import {
  passwordMatcher,
  phoneNumberMatcher,
} from "../Pages/AuthPages/RegisterPages/RegisterPage";
import { loadingStore } from "../stores/LoadingStore";
import { FirebaseProvider } from "./firebaseProvider";
import { MFAProvider, PendingMFAError } from "./mfa";

export interface UserDataPersonalInfo {
  username: string;
  fullname: string;
  dateOfBirth: string;
  province: string;
  city: string;
  mailAddress: string | null;
  phoneNumber: string;
}

export class AuthHelpers {
  /**
   * Registers user to firebase.
   * @param {string} passwd password to set for the user.
   * @param {UserDataPersonalInfo} dat the user personal data.
   */
  static async register(passwd: string, dat: UserDataPersonalInfo) {
    if (!FirebaseProvider.auth.currentUser) {
      try {
        await createUserWithEmailAndPassword(
          FirebaseProvider.auth,
          dat.mailAddress ??
            `${Math.random().toString(36).slice(2)}@${Math.random()
              .toString(36)
              .slice(2)}-derby10arena.com`,
          passwd,
        ).then((v) => this.setRegisterPhone(v.user.uid, dat.phoneNumber));
      } catch (e) {
        if (
          typeof e === "object" &&
          e !== null &&
          "code" in e &&
          typeof e.code === "string"
        ) {
          switch (e.code) {
            case "auth/invalid-email":
              throw new InvalidNewEmail();
            case "auth/invalid-password":
              throw new InvalidPassword();
            case "auth/email-already-in-use":
              throw new MailAlreadyInUse();
            default:
              throw new RegistrationFailed();
          }
        }

        throw new RegistrationFailed();
      }
    } else {
      if (FirebaseProvider.auth.currentUser.phoneNumber) {
        throw new UserdataAlreadyCreated();
      }
    }

    localStorage.setItem("lastRegisterInfo", JSON.stringify(dat));

    try {
      await httpsCallable(
        FirebaseProvider.functions,
        "userDataPrecheck",
      )({
        ...dat,
        mailAddress:
          dat.mailAddress ??
          `${Math.random().toString(36).slice(2)}/${Math.random()
            .toString(36)
            .slice(2)}-derby10arena.com`,
      });
    } catch (e) {
      if (
        e instanceof FirebaseError &&
        "details" in e &&
        typeof e.details === "object" &&
        e.details !== null
      ) {
        if (e.code === "functions/already-exists") {
          if ("phoneNumber" in e.details && e.details?.phoneNumber) {
            throw new PhoneNumberTaken();
          }

          if ("username" in e.details && e.details?.username) {
            throw new UsernameTaken();
          }
        } else if (e.code === "functions/invalid-argument") {
          if ("usernameInvalid" in e.details && e.details.usernameInvalid) {
            throw new UsernameInvalid();
          }

          if ("dobInvalid" in e.details && e.details.dobInvalid) {
            throw new DobInvalid();
          }

          if ("mailInvalid" in e.details && e.details.mailInvalid) {
            throw new InvalidNewEmail();
          }

          if (
            "phoneNumberInvalid" in e.details &&
            e.details.phoneNumberInvalid
          ) {
            throw new PhoneNumberInvalid();
          }

          if ("provinceInvalid" in e.details && e.details.provinceInvalid) {
            throw new ProvinceInvalid();
          }

          if ("cityInvalid" in e.details && e.details.cityInvalid) {
            throw new CityInvalid();
          }
        }
      }

      throw e;
    }

    try {
      await MFAProvider.requestChannelledMFA(
        "Kayıt İçin Doğrulama",
        dat.phoneNumber,
        false,
        false,
      );
    } catch (e) {
      if (e instanceof FirebaseError) {
        if (e.code === "functions/already-exists") {
          throw new PendingMFAError();
        }
      }
    }

    loadingStore.dispatch({ type: "close" });
    await MFAProvider.mfaResolvePromise;
    loadingStore.dispatch({ type: "open" });

    try {
      await httpsCallable(
        FirebaseProvider.functions,
        "register",
      )({
        data: {
          ...dat,
          mailAddress:
            dat.mailAddress ??
            `${Math.random().toString(36).slice(2)}/${Math.random()
              .toString(36)
              .slice(2)}-derby10arena.com`,
        },
        deviceId: FirebaseProvider.deviceId,
      });
    } catch (e) {
      loadingStore.dispatch({ type: "close" });
      throw e;
    }

    localStorage.removeItem("lastRegisterInfo");

    await FirebaseProvider.auth.currentUser?.reload();
  }

  /**
   * Signs in the user.
   * @param {string} target email to sign in for
   * @param {string} passwd password of the user
   */
  static async login(target: string, passwd: string) {
    if (phoneNumberMatcher.test(target)) {
      try {
        target = (await this.getUserMailFromPhone(target)) ?? target;
      } catch (e) {
        if (
          typeof e === "object" &&
          e !== null &&
          "code" in e &&
          typeof e.code === "string"
        ) {
          if (e.code === "functions/not-found") {
            throw new UserNotFound();
          }

          throw e;
        }
      }
    }

    try {
      await signInWithEmailAndPassword(FirebaseProvider.auth, target, passwd);
    } catch (e) {
      if (
        typeof e === "object" &&
        e !== null &&
        "code" in e &&
        typeof e.code === "string"
      ) {
        switch (e.code) {
          case "auth/invalid-email":
            throw new InvalidEmail();
          case "auth/wrong-password":
            throw new WrongPassword();
          case "auth/user-not-found":
            throw new UserNotFound();
          case "auth/too-many-requests":
            throw new QuotaExceeded();
          default:
            throw e;
        }
      }

      throw new LoginFailed("Mail adresiniz ya da şifreniz yanlış.");
    }

    await MFAProvider.ensureMFA();
  }

  static async logout() {
    try {
      try {
        if (!FirebaseProvider.auth.currentUser?.phoneNumber) {
          await httpsCallable(
            FirebaseProvider.functions,
            "deleteOnUnregister",
          )();
        }
      } catch {
        // no op
      }

      await signOut(FirebaseProvider.auth);
    } catch (e) {
      throw new LogoutFailed("Çıkış başarısız.");
    }
  }

  static async changePassword(currPasswd: string, newPasswd: string) {
    if (!FirebaseProvider.auth.currentUser?.email) {
      throw new NotLoggedIn();
    }

    await MFAProvider.ensureMFA();
    await reauthenticateWithCredential(
      FirebaseProvider.auth.currentUser,
      EmailAuthProvider.credential(
        FirebaseProvider.auth.currentUser.email,
        currPasswd,
      ),
    );

    await httpsCallable(
      FirebaseProvider.functions,
      "changePassword",
    )({
      password: newPasswd,
      mfaToken: MFAProvider.mfaToken,
      deviceId: FirebaseProvider.deviceId,
    });
  }

  /**
   * @param {string} target the target to reset password for
   * Sends a password reset code to given target
   */
  static async sendPasswordResetCode(target: string | undefined) {
    await httpsCallable(
      FirebaseProvider.functions,
      "sendPasswordResetCode",
    )({
      target,
      deviceId: FirebaseProvider.deviceId,
    });

    MFAProvider.bootstrapMFAParamsAndShow(
      false,
      "Şifre Sıfırlama Kodu",
      target,
      false,
      true,
    );
  }

  static async queryResetTokenStatus(token: string | unknown) {
    await httpsCallable(
      FirebaseProvider.functions,
      "queryPasswordResetTokenStatus",
    )({
      token,
      deviceId: FirebaseProvider.deviceId,
    });
  }

  static async resetPassword(
    token: string | unknown,
    password: string,
    again: string,
  ) {
    if (!passwordMatcher.test(password)) {
      throw new InvalidPassword();
    }

    if (again !== password) {
      throw new PasswordsDifferent();
    }

    await httpsCallable(
      FirebaseProvider.functions,
      "usePasswordResetToken",
    )({
      token,
      deviceId: FirebaseProvider.deviceId,
      password,
    });
  }

  static async getUserMailFromPhone(phone: string) {
    return (
      await httpsCallable<string, string | null>(
        FirebaseProvider.functions,
        "getMailFromPhone",
      )(phone)
    ).data;
  }

  static getRegisterPhone(uid: string) {
    return new Promise<string | null>((resolve, reject) => {
      onValue(
        ref(FirebaseProvider.db, `/users/${uid}/registrationPhone`),
        (v) => {
          resolve(v.val());
        },
        (v) => {
          reject(v);
        },
        {
          onlyOnce: true,
        },
      );
    });
  }

  static async setRegisterPhone(uid: string, phone: string) {
    await set(
      ref(FirebaseProvider.db, `/users/${uid}/registrationPhone`),
      phone,
    );
  }
}

export class AuthError extends Error {}
export class RegistrationFailed extends AuthError {}
export class UsernameInvalid extends RegistrationFailed {}
export class InvalidNewEmail extends RegistrationFailed {}
export class InvalidPassword extends RegistrationFailed {}
export class DobInvalid extends RegistrationFailed {}
export class PhoneNumberInvalid extends RegistrationFailed {}
export class ProvinceInvalid extends RegistrationFailed {}
export class CityInvalid extends RegistrationFailed {}

export class MailAlreadyInUse extends RegistrationFailed {}
export class UsernameTaken extends RegistrationFailed {}
export class PhoneNumberTaken extends RegistrationFailed {}
export class UserdataAlreadyCreated extends RegistrationFailed {}

export class ResetPasswordFailed extends AuthError {}
export class PasswordsDifferent extends ResetPasswordFailed {}

export class PasswordChangeFailed extends AuthError {}
export class NotLoggedIn extends AuthError {}

export class LoginFailed extends Error {}
export class InvalidEmail extends LoginFailed {}
export class WrongPassword extends LoginFailed {}
export class UserNotFound extends LoginFailed {}
export class QuotaExceeded extends LoginFailed {}

export class LogoutFailed extends Error {}
