import { Component } from "react";
import { loadingStore } from "../../stores/LoadingStore";
import "./LoadingOverlay.css";

export default class LoadingOverlay extends Component<
  object,
  { isOpen: boolean }
> {
  unsub: (() => void) | undefined;
  constructor(props: object) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  componentDidMount(): void {
    this.unsub = loadingStore.subscribe(() => {
      this.setState(() => {
        return {
          isOpen: loadingStore.getState().isOpen,
        };
      });
    });
  }

  componentWillUnmount(): void {
    if (this.unsub) this.unsub();
  }

  render() {
    return (
      <div
        className="loading-background"
        style={{
          display: this.state.isOpen ? "flex" : "none",
        }}
      >
        <div className="loading-content">
          <div className="loading-lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    );
  }
}
