import { QueryMapping } from "./searchHelper";

export const auctionQuery: QueryMapping = {
  opts: {
    keywords: [
      "isim",
      "açıklama",
      "güvercin",
      "id",
      "grupihale",
      "sahip",
      "künyeNo",
    ],
    ranges: ["başlangıçfiyatı", "başlamazamanı", "bitiş"],
    alwaysArray: true,
  },
  queryMapping: {
    isim: {
      isim1: {
        op: "qparam",
        v: "`auctions`.`name`",
      },
      isim2: {
        op: "like",
      },
      isim3: {
        op: "qmark",
        t: "s",
        v: "",
      },
      isim4: {
        op: "and",
      },
    },
    künyeNo: {
      pigeonId: {
        op: "qparam",
        v: "`pigeons`.`tagNum`",
      },
      pigeonId1: {
        op: "e",
      },
      pigeonId2: {
        op: "qmark",
        t: "s",
        v: "",
      },
      pigeonId3: {
        op: "and",
      },
    },
    sahip: {
      pigeonId: {
        op: "qparam",
        v: "`users`.`fullname`",
      },
      pigeonId1: {
        op: "like",
      },
      pigeonId2: {
        op: "qmark",
        t: "s",
        v: "",
      },
      pigeonId3: {
        op: "and",
      },
    },
    açıklama: {
      zy1: {
        op: "match",
        v: "`auctions`.`description`",
      },
      zy2: {
        op: "against",
      },
      zy3: {
        op: "qmark",
        t: "s",
        v: "",
      },
      zy4: {
        op: "pharantesisClose",
      },
    },
    güvercin: {
      pigeon: {
        op: "qparam",
        v: "`auctions`.`pigeon`",
      },
      pigeon1: {
        op: "e",
      },
      pigeon2: {
        op: "qmark",
        t: "s",
        v: "",
      },
      pigeon3: {
        op: "and",
      },
    },
    id: {
      id: {
        op: "qparam",
        v: "`auctions`.`id`",
      },
      id1: {
        op: "e",
      },
      id2: {
        op: "qmark",
        t: "s",
        v: "",
      },
      id3: {
        op: "and",
      },
    },
    durumfrom: {
      status: {
        op: "qparam",
        v: "`auctions`.`status`",
      },
      status1: {
        op: "gt",
      },
      status2: {
        op: "qmark",
        t: "i",
        v: "",
      },
      status3: {
        op: "and",
      },
    },
    durumto: {
      status4: {
        op: "qparam",
        v: "`auctions`.`status`",
      },
      status5: {
        op: "lt",
      },
      status6: {
        op: "qmark",
        t: "i",
        v: "",
      },
      status7: {
        op: "and",
      },
    },
    bitişfrom: {
      endingTime: {
        op: "qparam",
        v: "`auctions`.`endingTime`",
      },
      endingTime1: {
        op: "gt",
      },
      endingTime2: {
        op: "qmark",
        t: "i",
        v: "",
      },
      endingTime3: {
        op: "and",
      },
    },
    bitişto: {
      endingTime4: {
        op: "qparam",
        v: "`auctions`.`endingTime`",
      },
      endingTime5: {
        op: "lt",
      },
      endingTime6: {
        op: "qmark",
        t: "i",
        v: "",
      },
      endingTime7: {
        op: "and",
      },
    },
    başlangıçfiyatıfrom: {
      startingBid: {
        op: "qparam",
        v: "`auctions`.`startingBid`",
      },
      startingBid1: {
        op: "gt",
      },
      startingBid2: {
        op: "qmark",
        t: "i",
        v: "",
      },
      startingBid3: {
        op: "and",
      },
    },
    başlangıçfiyatıto: {
      startingBid4: {
        op: "qparam",
        v: "`auctions`.`startingBid`",
      },
      startingBid2: {
        op: "lt",
      },
      startingBid3: {
        op: "qmark",
        t: "i",
        v: "",
      },
      startingBid5: {
        op: "and",
      },
    },
    grupihale: {
      yx0parent: {
        op: "qparam",
        v: "`auctions`.`parent`",
      },
      yx0parent1: {
        op: "e",
      },
      yx0parent2: {
        op: "qmark",
        t: "s",
        v: "",
      },
      yx0parent3: {
        op: "and",
      },
    },
    başlamazamanıfrom: {
      startedOn: {
        op: "qparam",
        v: "`auctions`.`startedOn`",
      },
      startedOn1: {
        op: "gt",
      },
      startedOn2: {
        op: "qmark",
        t: "i",
        v: "",
      },
      startedOn3: {
        op: "and",
      },
    },
    başlamazamanıto: {
      startedOn4: {
        op: "qparam",
        v: "`auctions`.`startedOn`",
      },
      startedOn5: {
        op: "lt",
      },
      startedOn6: {
        op: "qmark",
        t: "i",
        v: "",
      },
      startedOn7: {
        op: "and",
      },
    },
  },
  defaultMapping: {
    isim1: {
      op: "qparam",
      v: "`auctions`.`name`",
    },
    isim2: {
      op: "like",
    },
    isim3: {
      op: "qmark",
      t: "s",
      v: "",
    },
  },
};

export const userSearchQuery: QueryMapping = {
  defaultMapping: {
    isim1: {
      op: "qparam",
      v: "fullname",
    },
    isim2: {
      op: "like",
    },
    isim3: {
      op: "qmark",
      t: "s",
      v: "",
    },
    isim4: {
      op: "and",
    },
  },
  opts: {
    keywords: ["id", "eposta", "kadi", "isim", "tel", "il", "ilce", "aktif"],
  },
  queryMapping: {
    id: {
      id1: {
        op: "qparam",
        v: "id",
      },
      id2: {
        op: "like",
      },
      id3: {
        op: "qmark",
        t: "s",
        v: "",
      },
      id4: {
        op: "and",
      },
    },
    eposta: {
      eposta1: {
        op: "qparam",
        v: "mailAddress",
      },
      eposta2: {
        op: "like",
      },
      eposta3: {
        op: "qmark",
        t: "s",
        v: "",
      },
      eposta4: {
        op: "and",
      },
    },
    kadi: {
      kadi1: {
        op: "qparam",
        v: "username",
      },
      kadi2: {
        op: "like",
      },
      kadi3: {
        op: "qmark",
        t: "s",
        v: "",
      },
      kadi4: {
        op: "and",
      },
    },
    isim: {
      isim1: {
        op: "qparam",
        v: "fullname",
      },
      isim2: {
        op: "like",
      },
      isim3: {
        op: "qmark",
        t: "s",
        v: "",
      },
      isim4: {
        op: "and",
      },
    },
    tel: {
      tel1: {
        op: "qparam",
        v: "phoneNumber",
      },
      tel2: {
        op: "like",
      },
      tel3: {
        op: "qmark",
        t: "s",
        v: "",
      },
      tel4: {
        op: "and",
      },
    },
    il: {
      il1: {
        op: "qparam",
        v: "province",
      },
      il2: {
        op: "like",
      },
      il3: {
        op: "qmark",
        t: "s",
        v: "",
      },
      il4: {
        op: "and",
      },
    },
    ilce: {
      ilce1: {
        op: "qparam",
        v: "city",
      },
      ilce2: {
        op: "like",
      },
      ilce3: {
        op: "qmark",
        t: "s",
        v: "",
      },
      ilce4: {
        op: "and",
      },
    },
    aktif: {
      aktif1: {
        op: "qparam",
        v: "active",
      },
      aktif2: {
        op: "e",
      },
      aktif3: {
        op: "qmark",
        t: "i",
        v: "",
      },
      aktif4: {
        op: "and",
      },
    },
  },
};

export const pigeonSearchQuery: QueryMapping = {
  defaultMapping: {
    kunye1: {
      op: "qparam",
      v: "`pigeons`.`tagNum`",
    },
    kunye2: {
      op: "like",
    },
    kunye3: {
      op: "qmark",
      t: "s",
      v: "",
    },
    kunye4: {
      op: "and",
    },
  },
  opts: {
    keywords: ["isim", "id", "kunye", "sahip", "sahipkadi"],
    ranges: ["serino"],
    alwaysArray: true,
  },
  queryMapping: {
    isim: {
      name1: {
        op: "qparam",
        v: "`pigeons`.`name`",
      },
      name2: {
        op: "like",
      },
      name3: {
        op: "qmark",
        t: "s",
        v: "",
      },
      name4: {
        op: "and",
      },
    },
    id: {
      id1: {
        op: "qparam",
        v: "`pigeons`.`id`",
      },
      id2: {
        op: "like",
      },
      id3: {
        op: "qmark",
        t: "s",
        v: "",
      },
      id4: {
        op: "and",
      },
    },
    kunye: {
      kunye1: {
        op: "qparam",
        v: "`pigeons`.`tagNum`",
      },
      kunye2: {
        op: "like",
      },
      kunye3: {
        op: "qmark",
        t: "s",
        v: "",
      },
      kunye4: {
        op: "and",
      },
    },
    sahip: {
      sahip1: {
        op: "qparam",
        v: "`users`.`fullname`",
      },
      sahip2: {
        op: "like",
      },
      sahip3: {
        op: "qmark",
        t: "s",
        v: "",
      },
      sahip4: {
        op: "and",
      },
    },
    sahipkadi: {
      sahipkadi1: {
        op: "qparam",
        v: "`users`.`username`",
      },
      sahipkadi2: {
        op: "like",
      },
      sahipkadi3: {
        op: "qmark",
        t: "s",
        v: "",
      },
      sahipkadi4: {
        op: "and",
      },
    },
    serinofrom: {
      serinofrom1: {
        op: "qparam",
        v: "`pigeons`.`serial`",
      },
      serinofrom2: {
        op: "gt",
      },
      serinofrom3: {
        op: "qmark",
        t: "i",
        v: "",
      },
      serinofrom4: {
        op: "and",
      },
    },
    serinoto: {
      serinofrom1: {
        op: "qparam",
        v: "`pigeons`.`serial`",
      },
      serinofrom2: {
        op: "lt",
      },
      serinofrom3: {
        op: "qmark",
        t: "i",
        v: "",
      },
      serinofrom4: {
        op: "and",
      },
    },
  },
};

export const groupAuctionQuery: QueryMapping = {
  defaultMapping: {
    isim1: {
      op: "qparam",
      v: "name",
    },
    isim2: {
      op: "like",
    },
    isim3: {
      op: "qmark",
      t: "s",
      v: "",
    },
  },
  opts: {
    keywords: ["isim", "açıklama", "id", "grupihale"],
    ranges: ["bitiş"],
    alwaysArray: true,
  },
  queryMapping: {
    id: {
      id1: {
        op: "qparam",
        v: "id",
      },
      id2: {
        op: "like",
      },
      id3: {
        op: "qmark",
        t: "s",
        v: "",
      },
      id4: {
        op: "and",
      },
    },
    grupihale: {
      yx0parent: {
        op: "qparam",
        v: "parent",
      },
      yx0parent1: {
        op: "like",
      },
      yx0parent2: {
        op: "qmark",
        t: "s",
        v: "",
      },
      yx0parent3: {
        op: "and",
      },
    },
    bitişfrom: {
      endingTime: {
        op: "qparam",
        v: "endingTime",
      },
      endingTime1: {
        op: "gt",
      },
      endingTime2: {
        op: "qmark",
        t: "i",
        v: "",
      },
      endingTime3: {
        op: "and",
      },
    },
    bitişto: {
      endingTime4: {
        op: "qparam",
        v: "endingTime",
      },
      endingTime5: {
        op: "lt",
      },
      endingTime6: {
        op: "qmark",
        t: "i",
        v: "",
      },
      endingTime7: {
        op: "and",
      },
    },
    isim: {
      isim1: {
        op: "qparam",
        v: "name",
      },
      isim2: {
        op: "like",
      },
      isim3: {
        op: "qmark",
        t: "s",
        v: "",
      },
      isim4: {
        op: "and",
      },
    },
    açıklama: {
      zy1: {
        op: "match",
        v: "name",
      },
      zy2: {
        op: "against",
      },
      zy3: {
        op: "qmark",
        t: "s",
        v: "",
      },
      zy4: {
        op: "pharantesisClose",
      },
    },
  },
};
