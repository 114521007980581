import { NavLink } from "react-router-dom";

export default function RegisterSucces() {
  return (
    <div className="AuthPageHierarchy">
      <div className="AuthPageBackground">
        <div className="Title">Kayıt Başvurunuz Alındı</div>
        <div className="Text">
          Kayıt başvurunuz alındı. Yetkililer onayladıktan sonra size haber
          verilecek ve hesabınıza girişiniz aktifleştirilecektir.
        </div>
        <div className="Buttons">
          <NavLink to={"/"} className="Button" id="Red">
            Ana Sayfa
          </NavLink>
        </div>
      </div>
    </div>
  );
}
