import { Component } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { Informations } from "../../Components/Informations";
import Photo from "../../Components/Photo/Photo";
import TitlePhoto from "../../Components/Photo/TitlePhoto";
import SliderLayout from "../../Components/Sliders/SliderLayout";
import Icons from "../../Icons/svg-icon";

import "./InstitutionalLayout.css";

export default class InstitutionalLayout extends Component {
  render() {
    return (
      <div id="page">
        <TitlePhoto title="Kurumsal"></TitlePhoto>
        <div className="InstitutionalLayout-content">
          <div className="buttons">
            <NavLink
              to={"/kurumsal/hakkimizda"}
              replace
              className="button"
              id="Outlined"
            >
              <div>
                <Icons.Work className="Icon" size={20}></Icons.Work>
              </div>
              Hakkımızda
            </NavLink>
            <NavLink
              to={"/kurumsal/kullanim-kosullari"}
              replace
              className="button"
              id="Red"
            >
              <div>
                <Icons.Paper className="Icon" size={20}></Icons.Paper>
              </div>
              Kullanım Koşulları
            </NavLink>
            <NavLink
              to={"/kurumsal/gizlilik-politikasi"}
              replace
              className="button"
              id="Red"
            >
              <div>
                <Icons.Paper className="Icon" size={20}></Icons.Paper>
              </div>
              Gizlilik Politikası
            </NavLink>
            <NavLink
              to={"/kurumsal/cerez-politikasi"}
              replace
              className="button"
              id="Red"
            >
              <div>
                <Icons.Paper className="Icon" size={20}></Icons.Paper>
              </div>
              Çerez Politikası
            </NavLink>
          </div>
          <div className="content">
            <Outlet></Outlet>
          </div>
        </div>
        {Informations.sponsors.length ? (
          <SliderLayout
            itemPercents={25}
            name="Sponsorlarımız"
            maxScale={0.8}
            minScale={0.6}
            startMiddle={true}
            isAutomatic={true}
            justResponsive={false}
            hideSelectors={true}
            infinity={true} // not working!
            items={Informations.sponsors.map((val, index) => {
              return <Photo src={val.url} alt={val.alt} key={index} />;
            })}
          />
        ) : null}
      </div>
    );
  }
}
