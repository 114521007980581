import { Unsubscribe } from "firebase/auth";
import { onValue, ref, set } from "firebase/database";
import { Component, ReactNode } from "react";
import { FirebaseProvider } from "../../BackendHelpers/firebaseProvider";

interface st {
  sendSms: boolean;
}

export default class AuctionSettings extends Component<unknown, st> {
  state = {
    sendSms: true,
  };

  unsubs: Unsubscribe[] = [];

  componentDidMount(): void {
    FirebaseProvider.auth.onAuthStateChanged((n) => {
      this.unsubListeners();
      if (!n) return;

      this.unsubs.push(
        onValue(
          ref(
            FirebaseProvider.db,
            `/users/${n.uid}/settings/dontSendBidSuperseeded`,
          ),
          (snap) => {
            this.setState({ sendSms: !snap.val() });
          },
        ),
      );
    });
  }

  unsubListeners() {
    for (const unsub of this.unsubs) {
      unsub();
    }
    this.unsubs.length = 0;
  }

  componentWillUnmount(): void {
    this.unsubListeners();
  }

  updateSendSms(v: boolean) {
    set(
      ref(
        FirebaseProvider.db,
        `/users/${FirebaseProvider.auth.currentUser?.uid}/settings/dontSendBidSuperseeded`,
      ),
      !v,
    );
  }

  render(): ReactNode {
    return (
      <div className="AuthPageHierarchy" id="page">
        <div className="AuthPageBackground">
          <div className="Title">Açık Arttırma Ayarları</div>
          <div className="InputBars">
            <div className="InputBarsRow">
              <div>
                <label>Teklifim Geçilince SMS Gönder:</label>
                <input
                  type="checkbox"
                  onChange={(e) => this.updateSendSms(e.target.checked)}
                  checked={this.state.sendSms}
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
