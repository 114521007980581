import { Component } from "react";
import { feedback } from "../stores/feedbackStore";

export const ENDPOINT_BASE = "https://derby10arena.com/php";

export interface PhpQuery {
  [key: string]: {
    op:
      | "where"
      | "or"
      | "and"
      | "e"
      | "ne"
      | "gt"
      | "lt"
      | "like"
      | "orderby"
      | "orderbyasc"
      | "orderbydesc"
      | "comma"
      | "limit"
      | "offset"
      | "qmark"
      | "qparam"
      | "match"
      | "against"
      | "pharantesisClose";
    t?: "s" | "i" | "b";
    v?: string | number | boolean;
  };
}

export abstract class PagedResource<T> {
  loadedItems: JSX.Element[] = [];
  loading = false;
  loadFinished = false;
  nextPageId: number | string | undefined;

  ENDPOINT = "";
  ITEM_CNT_PER_PAGE = 1;

  isPageLoaded(pageId: number) {
    if (!this.loadedItems.length) return false;
    return this.loadedItems.length >= pageId * this.ITEM_CNT_PER_PAGE + 1;
  }

  async loadPage(pageId: number, caller?: Component, extras?: object) {
    if (this.loading) {
      return; // TODO: maybe throw
    }
    this.loading = true;

    if (this.isPageLoaded(pageId)) {
      this.loading = false;
      return;
    }

    if (this.loadFinished) {
      this.loading = false;
      return; // TODO: maybe throw
    }

    const res = await fetch(this.ENDPOINT, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: JSON.stringify({
        ...extras,
        p: this.nextPageId ?? 0,
        c: this.ITEM_CNT_PER_PAGE,
      }),
    });

    if (res.status !== 200) {
      feedback.push("Sayfa yüklenemedi.", "red", 3);
      this.loading = false;
      caller?.setState({});
      throw new Error();
    }

    let json: { p: number | string | undefined; r: T[] };
    try {
      json = await res.json();
    } catch (e) {
      feedback.push("Sayfa yüklenemedi.", "red", 3);
      this.loading = false;
      caller?.setState({});
      throw e;
    }

    this.nextPageId = json.p;
    json.r.forEach((e) => {
      this.loadedItems.push(this.itemBuilder(e));
    });

    this.loading = false;
    caller?.setState({});
    if (json.p === undefined) {
      this.loadFinished = true;
    }

    return json.r.length;
  }

  itemBuilder(e: T): JSX.Element {
    throw new Error("unimplemented");
  }

  getPageItems(state: { pageIndex: number }) {
    const output: JSX.Element[] = [];
    const max =
      (state.pageIndex + 1) * this.ITEM_CNT_PER_PAGE > this.loadedItems.length
        ? this.loadedItems.length
        : (state.pageIndex + 1) * this.ITEM_CNT_PER_PAGE;

    for (let i = state.pageIndex * this.ITEM_CNT_PER_PAGE; i < max; i++) {
      output.push(this.loadedItems[i]);
    }

    return output;
  }

  onPageChange = false;

  decrementPage(caller: Component | null) {
    if (this.onPageChange) return;
    this.onPageChange = true;

    if (this.loading) return;

    caller?.setState((prev: { pageIndex: number }) => {
      this.onPageChange = false;
      if (prev.pageIndex === 0) {
        return prev;
      }

      return {
        pageIndex: prev.pageIndex - 1,
      };
    });
  }

  incrementPage(
    caller: Component<unknown, { pageIndex: number }>,
    extras?: object,
    after?: () => void,
    onError?: (e: unknown) => void,
  ) {
    if (this.onPageChange) {
      if (after) after();
      return;
    }

    if (this.loading) {
      if (after) after();
      return;
    }

    this.onPageChange = true;

    if (this.isPageLoaded(caller.state.pageIndex + 1)) {
      caller.setState({
        pageIndex: caller.state.pageIndex + 1,
      });

      this.onPageChange = false;
      if (after) after();
      return;
    }

    if (this.loadFinished) {
      this.onPageChange = false;
      if (after) after();
      return;
    }

    this.loadPage(caller.state.pageIndex + 1, caller, extras)
      .then((res) => {
        if (res === 0) {
          caller?.setState({
            pageIndex: caller.state.pageIndex,
          });
        } else if (!res) {
          caller?.setState({
            pageIndex: caller.state.pageIndex + 1,
          });
        } else {
          caller?.setState({
            pageIndex: caller.state.pageIndex + 1,
          });
        }

        this.onPageChange = false;
        if (after) after();
      })
      .catch((e) => {
        this.onPageChange = false;
        if (after) after();
        if (onError) onError(e);
      });
  }

  clear(caller?: Component) {
    this.loading = false;
    this.loadedItems.length = 0;
    this.loadFinished = false;
    this.nextPageId = 0;
    caller?.setState({ pageIndex: 0 });
  }
}

export class _PagedResource<T> {
  loadingPromise: Promise<T[]> | null = null;
  loading = false;
  windowStart = 0;
  windowEnd: number;

  ENDPOINT: string;
  CNT: number;

  constructor(endpoint: string, cnt: number) {
    this.ENDPOINT = endpoint;
    this.CNT = cnt;
    this.windowEnd = cnt;
  }

  setWindowToStart() {
    this.windowStart = 0;
    this.windowEnd = this.CNT;
  }

  loadNextPage(extras?: PhpQuery, headerExtras?: HeadersInit): Promise<T[]> {
    if (!this.loading) {
      const promise = this.loadWindow(
        this.windowStart,
        this.windowEnd,
        extras,
        headerExtras,
      ).then((r) => {
        this.windowStart += this.CNT;
        this.windowEnd += this.CNT;

        return r;
      });
      this.loadingPromise = promise;
      return promise;
    } else {
      return this.loadingPromise!;
    }
  }

  loadPrevPage(extras?: PhpQuery, headerExtras?: HeadersInit): Promise<T[]> {
    const promise = this.loadWindow(
      this.windowStart - this.CNT,
      this.windowEnd - this.CNT,
      extras,
      headerExtras,
    ).then((r) => {
      this.windowStart -= this.CNT;
      this.windowEnd -= this.CNT;

      return r;
    });

    this.loadingPromise = promise;
    return promise;
  }

  async loadWindow(
    start: number,
    end: number,
    queryExtras?: PhpQuery,
    headerExtras?: HeadersInit,
  ): Promise<T[]> {
    if (this.loading) {
      throw new Error(); // TODO
    }

    this.loading = true;

    const paginationOp: PhpQuery = {
      zz0: {
        op: "limit",
        t: "i",
        v: end - start,
      },
      zz1: {
        op: "offset",
        t: "i",
        v: start,
      },
    };

    try {
      const resp = await fetch(this.ENDPOINT, {
        method: "POST",
        headers: {
          Accept: "application/json",
          ...headerExtras,
        },
        body: queryExtras
          ? JSON.stringify({ ...queryExtras, ...paginationOp } as PhpQuery)
          : JSON.stringify(paginationOp as PhpQuery),
      });
      try {
        const json = await resp.json();
        this.loading = false;
        return json as T[];
      } catch (e) {
        this.loading = false;
        throw e;
      }
    } catch (e1) {
      this.loading = false;
      throw e1;
    }
  }
}
