import { Buffer } from "buffer";
import { Component } from "react";
import { NavLink } from "react-router-dom";
import { fetchHtmlPage } from "../../BackendHelpers/htmlFetch";
import Informations from "../Informations";
import "./Contract.css";

export enum ContractType {
  tos = "Kullanım Koşulları",
  privacy = "Gizlilik Politikası",
  cookie = "Çerez Politikası",
}

interface Props {
  contractType: ContractType;
}

export default class Contract extends Component<Props, { val: string }> {
  constructor(props: Props) {
    super(props);
    this.state = {
      val: "",
    };
  }

  componentDidMount(): void {
    let page: string;

    switch (this.props.contractType) {
      case ContractType.tos:
        page = Informations.terms_html_loc;
        break;
      case ContractType.privacy:
        page = Informations.privacy_policy_html_loc;
        break;
      case ContractType.cookie:
        page = Informations.cookie_policy_html_loc;
        break;
      default:
        throw new Error("unknown contract type");
    }

    fetchHtmlPage(page).then(async (val) => {
      this.setState({
        val: Buffer.from(
          (await val?.getReader().read())?.value ?? "",
        ).toString(),
      });
    });
  }

  getContract() {
    return (
      <div>
        <h1>{this.props.contractType}</h1>
        <div dangerouslySetInnerHTML={{ __html: this.state.val }}></div>
      </div>
    );
  }

  render() {
    return (
      <div className="contract-area" id="page">
        {this.getContract()}
        <NavLink id="NavLink" to={"/iletisim"}>
          İletişim
        </NavLink>
      </div>
    );
  }
}
