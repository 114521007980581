import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./Reporters/reportWebVitals";

import "./fonts/rubik.css";
import "./index.css";

import { BrowserRouter } from "react-router-dom";
import { FirebaseProvider } from "./BackendHelpers/firebaseProvider";
import { ErrorHandler } from "./Components/ErrorHandler";
import { Informations } from "./Components/Informations";
import { mngr as GalleryManager } from "./Pages/Gallery/Gallery";
import { feedback } from "./stores/feedbackStore";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

// #region startup loading/init

// firebase
try {
  FirebaseProvider.initializeFirebase();
} catch (e) {
  console.error(e);
}
// feedback
feedback.initalize();

const appRef: React.RefObject<ErrorHandler> = React.createRef();

Promise.allSettled([
  new Promise<void>((resolve) => {
    GalleryManager.loadPage(0)
      .then(() => resolve())
      .catch(resolve);
  }),
  new Promise<void>((resolve) => {
    Informations.pullInfos()
      .then(() => resolve())
      .catch(() => resolve());
  }),
]).then(() => {
  appRef.current?.setState({
    key: 1,
  });
});

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ErrorHandler ref={appRef}>
        <App></App>
      </ErrorHandler>
    </BrowserRouter>
  </React.StrictMode>,
);

// #endregion

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
