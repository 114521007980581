import Icons from "../../Icons/svg-icon";
import "./MainTitle.css";

export default function MainTitle(props: { title: string; inside: string }) {
  if (window.innerWidth < 900) {
    return (
      <div className="MainTitle">
        <div>
          <Icons.MultipleUser size={24}></Icons.MultipleUser>
          <li>{props.title}</li>
        </div>
        <span>{props.inside}</span>
      </div>
    );
  } else {
    return (
      <div className="MainTitle">
        <Icons.MultipleUser></Icons.MultipleUser>
        <li>{props.title}</li>
        <span>{props.inside}</span>
      </div>
    );
  }
}
