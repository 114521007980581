import { Component } from "react";
import { photoStore } from "../../stores/photoStore";

export class PhotoOverlay extends Component<
  unknown,
  {
    photo: string | null;
    open: boolean;
  }
> {
  storeSub: (() => void) | undefined;

  constructor(props: unknown) {
    super(props);
    this.state = {
      photo: null,
      open: false,
    };
  }

  componentDidMount() {
    this.storeSub = photoStore.subscribe(() => {
      const state = photoStore.getState();

      this.setState({
        photo: state.photo,
        open: state.isOpen,
      });
    });
  }

  componentWillUnmount() {
    if (this.storeSub) this.storeSub();
  }

  render() {
    return this.state.open ? (
      <div
        style={{
          position: "fixed",
          zIndex: 100000,
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(0,0,0,0.6)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={() => {
          photoStore.dispatch({ type: { action: "close" } });
        }}
      >
        {" "}
        {this.state.photo != null ? (
          <img
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
            }}
            src={this.state.photo}
          ></img>
        ) : (
          <span
            style={{
              color: "white",
              fontFamily: "Rubik-Medium",
              fontSize: "30px",
            }}
          >
            Resim Yüklenemedi
          </span>
        )}
      </div>
    ) : null;
  }
}
