import { Component } from "react";
import "./AboutUs.css";

import { Buffer } from "buffer";
import { NavLink } from "react-router-dom";
import { fetchHtmlPage } from "../../BackendHelpers/htmlFetch";
import { feedback } from "../../stores/feedbackStore";
import Informations from "../Informations";

interface Props {
  buttonText: string;
  buttonPath: string;
}

export default class AboutUs extends Component<Props, { val: string }> {
  constructor(props: Props) {
    super(props);
    this.state = {
      val: "",
    };
  }

  componentDidMount() {
    fetchHtmlPage(Informations.about_us_html_loc).then(async (val) => {
      if (!val) {
        feedback.push("Hakkımızda yüklenemedi.", "red", 3);
        return;
      }

      this.setState({
        val: Buffer.from(
          (await val?.getReader().read())?.value ?? "",
        ).toString(),
      });
    });
  }

  render() {
    return (
      <div className="AboutUs-background" id="page">
        <img src={Informations.institute_image} alt="" />
        <div className="inf-area">
          <li className="title">Hakkımızda</li>
          <li
            className="paragraph"
            dangerouslySetInnerHTML={{ __html: this.state.val }}
          />
          <NavLink id="NavLink" to={this.props.buttonPath}>
            {this.props.buttonText}
          </NavLink>
        </div>
      </div>
    );
  }
}
