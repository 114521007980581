import { configureStore } from "@reduxjs/toolkit";

export const photoStore = configureStore<{
  isOpen: boolean;
  photo: string | null;
}>({
  reducer: (state, action) => {
    if (action.type.action == "open") {
      return { isOpen: true, photo: action.type.photo };
    } else {
      return { isOpen: false, photo: null };
    }
  },
});
