import React, { Component } from "react";
import { Informations } from "../Informations";
import "./ImageSlider.css";

interface ImageSliderState {
  WindowSize: number;
  SlideIndex: number;
  isPressed: boolean;
  isHover: boolean;
  startLoc: number;
  lastTouch: number;
}

export default class ImageSlider extends Component<unknown, ImageSliderState> {
  interval: NodeJS.Timer | undefined;
  resizerFunc = this.resizer;

  constructor(props: unknown) {
    super(props);
    this.state = {
      WindowSize: window.innerWidth,
      SlideIndex: 0,
      isPressed: false,
      isHover: false,
      startLoc: window.innerWidth / 2,
      lastTouch: 0,
    };
  }

  componentDidMount(): void {
    this.resizerFunc = () => {
      this.resizer();
    };
    window.addEventListener("resize", this.resizerFunc);
    this.interval = setInterval(() => {
      if (new Date().getTime() - this.state.lastTouch < 3000) {
        return;
      }
      if (this.state.isHover) return;

      const maxlen =
        (window.innerWidth > 600
          ? Informations.slider_pics.full.length
          : Informations.slider_pics.mobile.length) - 1;
      this.setState((state: ImageSliderState) => {
        return {
          SlideIndex: state.SlideIndex < maxlen ? state.SlideIndex + 1 : 0,
        };
      });
    }, 3000);
  }
  componentWillUnmount(): void {
    clearInterval(this.interval);
    this.interval = undefined;
    window.removeEventListener("resize", this.resizerFunc);
  }

  resizer() {
    this.setState({ WindowSize: window.innerWidth });
  }

  getSliderImagePos() {
    return (this.state.SlideIndex * this.state.WindowSize).toString() + "px";
  }

  sliderImages() {
    return (
      window.innerWidth > 600
        ? Informations.slider_pics.full
        : Informations.slider_pics.mobile
    ).map((each, index) => (
      <div
        key={index}
        style={{
          width: this.state.WindowSize,
          aspectRatio: window.innerWidth > 600 ? "3.49" : "2.4",
          pointerEvents: "none",
          userSelect: "none",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          style={{
            width: "100%",
            objectFit: "cover",
            pointerEvents: "none",
            userSelect: "none",
          }}
          alt=""
          src={each}
        />
      </div>
    ));
  }

  sliderButtons() {
    return (
      window.innerWidth > 600
        ? Informations.slider_pics.full
        : Informations.slider_pics.mobile
    ).map((each, index) => (
      <button
        key={index}
        className="SlideButton"
        style={{
          backgroundColor:
            this.state.SlideIndex === index
              ? "white"
              : "rgba(255, 255, 255, 0.4)",
        }}
        onClick={() => this.setState({ SlideIndex: index })} // WARNING!
      />
    ));
  }

  // Mouse Controller
  onMouseMove(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (!this.state.isPressed) {
      return;
    }
    this.controlSwipe(event.clientX);
  }
  onMouseEnter() {
    this.setState({ isHover: true });
  }
  onMouseLeave() {
    this.setState({ isHover: false });
    this.setState({ isPressed: false });
  }
  onMouseDown(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    this.setState({ startLoc: event.clientX });
    this.setState({ isHover: true });
    this.setState({ isPressed: true });
  }
  onMouseUp() {
    this.setState({ isPressed: false });
  }

  // Touch Controller
  onTouchMove(e: React.TouchEvent<HTMLDivElement>) {
    if (!this.state.isPressed) return;
    this.controlSwipe(e.changedTouches[0].clientX);
  }
  onTouchEnd() {
    this.setState({ lastTouch: new Date().getTime() });
    this.setState({ isHover: false });
    this.setState({ isPressed: false });
  }
  onTouchStart(e: React.TouchEvent<HTMLDivElement>) {
    this.setState({ startLoc: e.changedTouches[0].clientX });
    this.setState({ isHover: true });
    this.setState({ isPressed: true });
  }

  controlSwipe(clientX = 0) {
    const diff = clientX - this.state.startLoc;
    if (diff / this.state.WindowSize > 0.1) {
      this.setState({ isPressed: false });
      const maxlen =
        (this.state.WindowSize > 600
          ? Informations.slider_pics.full.length
          : Informations.slider_pics.mobile.length) - 1;
      this.setState({
        SlideIndex:
          this.state.SlideIndex > 0 ? this.state.SlideIndex - 1 : maxlen,
      });
    } else if (diff / this.state.WindowSize < -0.1) {
      this.setState({ isPressed: false });
      const maxlen =
        (this.state.WindowSize > 600
          ? Informations.slider_pics.full.length
          : Informations.slider_pics.mobile.length) - 1;
      this.setState({
        SlideIndex:
          this.state.SlideIndex < maxlen ? this.state.SlideIndex + 1 : 0,
      });
    }
  }

  render() {
    return (
      <div
        className="slider-view-box"
        id="slider-view-box"
        onMouseMove={(event) => this.onMouseMove(event)}
        onMouseEnter={() => {
          this.onMouseEnter();
        }}
        onMouseLeave={() => {
          this.onMouseLeave();
        }}
        onMouseDown={(event) => {
          this.onMouseDown(event);
        }}
        onMouseUp={() => {
          this.onMouseUp();
        }}
        onTouchEnd={() => {
          this.onTouchEnd();
        }}
        onTouchCancel={() => {
          this.onTouchEnd();
        }}
        onTouchMove={(e) => {
          this.onTouchMove(e);
        }}
        onTouchStart={(e) => {
          this.onTouchStart(e);
        }}
      >
        <div
          className="slider-content-box"
          style={{ right: this.getSliderImagePos(), pointerEvents: "none" }}
        >
          {this.sliderImages()}
        </div>
        <div
          className="selectors"
          style={{
            opacity: this.state.isPressed ? 0 : 1,
          }}
        >
          {this.sliderButtons()}
        </div>
      </div>
    );
  }
}
