import React, { Component } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { FirebaseProvider } from "../BackendHelpers/firebaseProvider";

interface State {
  isAuthenticated: boolean;
}
interface Props {
  isRequired: boolean;
  children?: React.ReactNode;
}

export default class AuthProtector extends Component<Props, State> {
  authListener: (() => void) | null;

  constructor(props: Props) {
    super(props);
    this.state = {
      isAuthenticated: false,
    };
    this.authListener = null;
  }

  componentDidMount(): void {
    this.authListener = FirebaseProvider.auth.onAuthStateChanged(() => {
      this.setState({
        isAuthenticated: this.props.isRequired
          ? FirebaseProvider.auth.currentUser?.phoneNumber == null
          : FirebaseProvider.auth.currentUser?.phoneNumber != null,
      });
    });
  }

  componentWillUnmount(): void {
    if (this.authListener) this.authListener();
  }

  render() {
    if (this.state.isAuthenticated) return <Navigate to="/" replace />;
    else return this.props.children ?? <Outlet></Outlet>;
  }
}
