import { Component, createRef } from "react";
import { MFAProvider } from "../../BackendHelpers/mfa";
import Icons from "../../Icons/svg-icon";
import { verificationStore } from "../../stores/verificationOverlayStore";

interface OTPCredential extends Credential {
  code?: string;
}

export default class VerificationPage extends Component<
  unknown,
  { shown: boolean }
> {
  mfaVal: string | null = null;
  unsub: (() => void) | undefined;

  constructor(props: unknown) {
    super(props);
    this.state = {
      shown: false,
    };
  }

  answerInput = createRef<HTMLDivElement>();
  answerInfo = createRef<HTMLDivElement>();

  mfaCodeInputRef = createRef<HTMLInputElement>();

  listenWebOTP() {
    navigator.credentials
      .get({
        // @ts-expect-error otp is not fully supported
        otp: { transport: ["sms"] },
      })
      .then((otp: OTPCredential | null) => {
        if (!otp || !otp.code) {
          return;
        }

        this.mfaVal = otp.code;
        if (this.mfaCodeInputRef.current) {
          this.mfaCodeInputRef.current.value = otp.code;
        }

        this.sendMFAAnswer();
      })
      .catch((e) => {
        console.log("failed to use web otp");
        throw e;
      });
  }

  async sendMFAAnswer() {
    if (!this.mfaVal) {
      return;
    }

    this.clearInputErrors();

    await MFAProvider.resolveChannelledMFA(this.mfaVal)
      .then(() => {
        this.closeDialog();
      })
      .catch(() => {
        this.showRejectError();
      });
  }

  componentDidMount() {
    this.listenWebOTP();
    this.unsub = verificationStore.subscribe(() => {
      this.setState(() => {
        return { shown: verificationStore.getState().overlayOpen };
      });
    });
  }

  componentWillUnmount() {
    if (this.unsub) this.unsub();
  }

  onMfaCodeChange(e: string) {
    this.mfaVal = e;
  }

  clearInputErrors() {
    if (this.answerInfo.current && this.answerInput.current) {
      this.answerInfo.current.innerHTML = "";
      this.answerInfo.current.style.color = "inherit";
      this.answerInput.current.style.boxShadow = "inherit";
    }
  }

  closeDialog() {
    this.clearInputErrors();
    verificationStore.dispatch({ type: "close" });
  }

  showRejectError() {
    if (this.answerInfo.current && this.answerInput.current) {
      this.answerInfo.current.innerHTML = "Cevap kabul edilmedi.";
      this.answerInfo.current.style.color = "red";
      this.answerInput.current.style.boxShadow = "0px 0px 5px rgb(255 0 0)";
    }
  }

  render() {
    return this.state.shown ? (
      <div
        style={{
          position: "fixed",
          width: "100%",
          height: "100%",
          background: "rgba(0,0,0,0.3)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          top: 0,
          verticalAlign: "center",
          zIndex: 10000,
        }}
      >
        <div
          className="AuthPageHierarchy"
          id="page"
          style={{
            position: "absolute",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div className="AuthPageBackground">
            <div className="Title">{MFAProvider.mfaTitle}</div>
            <div className="InputBars">
              <div className="InputBar">
                <div>{`${MFAProvider.mfaTarget} adresine gönderilen kodu giriniz.`}</div>
                <div>
                  <div className="Input" ref={this.answerInput}>
                    <Icons.Password
                      className="Icon"
                      size={25}
                      fill="rgba(0, 0, 0, 0.2)"
                    ></Icons.Password>
                    <input
                      id="mfaCodeInput"
                      ref={this.mfaCodeInputRef}
                      type={"number"}
                      onChange={(e) => this.onMfaCodeChange(e.target.value)}
                      placeholder="Kod"
                    />
                  </div>
                  <div className="info" ref={this.answerInfo}></div>
                </div>
              </div>
            </div>
            <div className="Buttons">
              <button
                className="Button"
                id="Red"
                onClick={() => {
                  this.sendMFAAnswer();
                }}
              >
                Doğrula
              </button>
              <ReSendButton parent={this}></ReSendButton>
              <button
                className="Button"
                id="Outlined"
                onClick={() => {
                  this.closeDialog();
                  MFAProvider.rejectPendingMFA();
                }}
              >
                Önceki Sayfaya Dön
              </button>
            </div>
          </div>
        </div>
      </div>
    ) : null;
  }
}

interface ReSendButtonState {
  timer: number;
}

class ReSendButton extends Component<
  { parent: VerificationPage },
  ReSendButtonState
> {
  currentTimer: NodeJS.Timer | null = null;

  constructor(props: { parent: VerificationPage }) {
    super(props);
    this.state = {
      timer: 180,
    };
  }

  componentDidMount(): void {
    if (this.currentTimer != null) return;
    this.startTimer();
  }

  componentWillUnmount(): void {
    if (this.currentTimer != null) {
      clearInterval(this.currentTimer);
      this.currentTimer = null;
    }
  }

  startTimer() {
    this.currentTimer = setInterval(() => {
      this.setState({ timer: this.state.timer - 1 });
      if (this.state.timer <= 1) {
        if (this.currentTimer != null) {
          clearInterval(this.currentTimer);
          this.currentTimer = null;
        }
      }
    }, 1000);
  }

  resetTimer() {
    if (this.currentTimer != null) return;
    this.setState({ timer: 180 });
    this.startTimer();
  }

  getTime() {
    return (
      Math.floor(this.state.timer / 60)
        .toString()
        .padStart(2, "0") +
      ":" +
      (this.state.timer % 60).toFixed(0).toString().padStart(2, "0")
    );
  }

  render() {
    return (
      <button
        className="Button"
        id="Outlined"
        disabled={this.state.timer != 0}
        onClick={() => {
          this.resetTimer();
          MFAProvider.requestChannelledMFA(
            MFAProvider.mfaTitle ?? "Tekrar Doğrulama",
            MFAProvider.mfaTarget,
            true,
            false,
          );
          this.props.parent.clearInputErrors();
        }}
      >
        Tekrar Gönder ({this.getTime()})
      </button>
    );
  }
}
