import { Component } from "react";
import { feedbackStore } from "../../stores/feedbackStore";
import "./FeedBackOverlay.css";

interface State {
  message: string;
  messageColor: string;
  count: number;
}

export default class FeedBackOverlay extends Component<object, State> {
  unsub: (() => void) | undefined;
  constructor(props: object) {
    super(props);

    this.state = {
      count: 0,
      message: "",
      messageColor: "transparent",
    };
  }

  componentDidMount(): void {
    this.unsub = feedbackStore.subscribe(() => {
      this.setState(() => {
        return {
          count: feedbackStore.getState().count,
          message: feedbackStore.getState().message,
          messageColor: feedbackStore.getState().messageColor,
        };
      });
    });
  }

  componentWillUnmount(): void {
    if (this.unsub) this.unsub();
  }

  render() {
    return (
      <div
        className="feedback-background"
        style={{
          top: this.state.count > 0 ? "25px" : "-120%",
          color: this.state.messageColor,
          boxShadow: "1px 0px 10px " + this.state.messageColor,
        }}
      >
        {this.state.message}
      </div>
    );
  }
}
