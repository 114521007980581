import { Component } from "react";
import {
  ENDPOINT_BASE,
  PagedResource,
} from "../../BackendHelpers/pagedResource";
import { GalleryPhoto } from "../../Components/Photo/Photo";
import TitlePhoto from "../../Components/Photo/TitlePhoto";
import Icons from "../../Icons/svg-icon";
import "./Gallery.css";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

interface State {
  pageIndex: number;
}

export class GalleryManager extends PagedResource<string> {
  ENDPOINT = `${ENDPOINT_BASE}/galleryQuery.php`;
  ITEM_CNT_PER_PAGE = 10;

  itemBuilder(e: string) {
    return (
      <GalleryPhoto
        maxWidth={240}
        src={e}
        key={this.loadedItems.length}
      ></GalleryPhoto>
    );
  }
}

export const mngr = new GalleryManager();

export default class Gallery extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      pageIndex: 0,
    };
  }

  render() {
    const elements = mngr.getPageItems(this.state);

    return (
      <div className="Gallery-background" id="page">
        <TitlePhoto title="Galeri"></TitlePhoto>
        <div className="safe-area">
          <li className="page-number">
            Sayfa {this.state.pageIndex + 1}
            <div>
              <button onClick={() => mngr.decrementPage(this)}>
                <Icons.ArrowLeftCircle
                  size={24}
                  fill={"#6A696C"}
                ></Icons.ArrowLeftCircle>
              </button>
              <button onClick={() => mngr.incrementPage(this)}>
                <Icons.ArrowRightCircle
                  size={24}
                  fill={"#6A696C"}
                ></Icons.ArrowRightCircle>
              </button>
            </div>
          </li>
          {!elements.length ? (
            <div style={{ marginBottom: "25px" }}>
              <span className="not-found">Galeride fotoğraf bulunamadı</span>
            </div>
          ) : (
            <div className="gallery">{elements}</div>
          )}
        </div>
      </div>
    );
  }
}
