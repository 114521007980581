import "./App.css";

import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { ContactBar } from "./Components/ContactBar/ContactBar";
import Footer from "./Components/Footer/Footer";
import Navbar from "./Components/Navbar/Navbar";

import { useEffect } from "react";
import AboutUs from "./Components/AboutUs/AboutUs";
import Contract, { ContractType } from "./Components/Contract/Contract";
import MainPage from "./Pages//MainPage/MainPage";
import AuctionPage from "./Pages/AuctionsPage/AuctionPage";
import AuctionsLayout, {
  AuctionMode,
} from "./Pages/AuctionsPage/AuctionsLayout";
import GroupAuctionPage from "./Pages/AuctionsPage/GroupAuctionPage";
import ForgotPassword from "./Pages/AuthPages/LoginPages/ForgotPassword";
import LoginPage from "./Pages/AuthPages/LoginPages/LoginPage";
import RefreshPassword from "./Pages/AuthPages/LoginPages/RefreshPassword";
import { RegisterPage } from "./Pages/AuthPages/RegisterPages/RegisterPage";
import RegisterSucces from "./Pages/AuthPages/RegisterPages/RegisterSucces";
import VerificationPage from "./Pages/AuthPages/VerificationPage";
import Contact from "./Pages/Contact/Contact";
import Error404 from "./Pages/ErrorPages/404/Error404";
import Error500 from "./Pages/ErrorPages/500/Error500";
import Gallery from "./Pages/Gallery/Gallery";
import InstitutionalLayout from "./Pages/InstitutionalLayout/InstitutionalLayout";
import MyAccountPageLayout from "./Pages/MyAccountPages/MyAccountPageLayout";
import MyAuctions from "./Pages/MyAccountPages/MyAuctions";
import NewsPage from "./Pages/Newscast/NewsPage";
import Newscast from "./Pages/Newscast/Newscast";
import UsersPage from "./Pages/Users/UsersPage";

import { FirebaseProvider } from "./BackendHelpers/firebaseProvider";
import { AuctionStatus } from "./Components/Auction/MyAuction";
import AuthProtector from "./Components/AuthProtector";
import UrgentNotification from "./Components/Navbar/UrgentNotification";
import FeedBackOverlay from "./Components/Overlays/FeedBackOverlay";
import LoadingOverlay from "./Components/Overlays/LoadingOverlay";
import AreYouSureOverlay from "./Components/Overlays/areYouSureOverlay";
import { PhotoOverlay } from "./Pages/Gallery/PhotoOverlay";
import AuctionSettings from "./Pages/MyAccountPages/AuctionSettings";
import MyInformationsPage from "./Pages/MyAccountPages/MyInformationsPage";
import RefreshPasswordPage from "./Pages/MyAccountPages/RefreshPasswordPage";

let authListenerInitialized = false;

export default function App() {
  const loc = useLocation();
  const nav = useNavigate();

  if (!authListenerInitialized) {
    FirebaseProvider.auth.onAuthStateChanged((usr) => {
      if (usr && !usr.phoneNumber) {
        nav("/kayit-ol", { replace: true });
      }
    });

    authListenerInitialized = true;
  }

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth", // Optional if you want to skip the scrolling animation
    });

    if (
      FirebaseProvider.auth.currentUser &&
      !FirebaseProvider.auth.currentUser.phoneNumber &&
      loc.pathname !== "/kayit-ol"
    ) {
      nav("/kayit-ol");
    }
  }, [loc.pathname]);

  return (
    <div className="App">
      <ContactBar></ContactBar>
      <Navbar></Navbar>
      <UrgentNotification></UrgentNotification>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="*" element={<Error404 />} />
        <Route path="/giris-yap" element={<AuthProtector isRequired={false} />}>
          <Route index={true} element={<LoginPage />} />
          <Route path="sifremi-unuttum">
            <Route index={true} element={<ForgotPassword />} />
          </Route>
        </Route>
        <Route
          path="/sifre-yenile"
          element={<RefreshPassword></RefreshPassword>}
        ></Route>
        <Route path="/kayit-ol" element={<AuthProtector isRequired={false} />}>
          <Route index={true} element={<RegisterPage />} />
          <Route
            path="basarili"
            element={<RegisterSucces></RegisterSucces>}
          ></Route>
        </Route>
        <Route path="/iletisim" element={<Contact />} />
        <Route path="/galeri" element={<Gallery />} />
        <Route path="/kurumsal" element={<InstitutionalLayout />}>
          <Route
            index={true}
            element={<Navigate to={"/kurumsal/hakkimizda"} />}
          />
          <Route
            path="hakkimizda"
            element={<AboutUs buttonPath="/iletisim" buttonText="İletişim" />}
          ></Route>
          <Route
            path="kullanim-kosullari"
            element={<Contract key="kulkos" contractType={ContractType.tos} />}
          ></Route>
          <Route
            path="gizlilik-politikasi"
            element={
              <Contract key="privo" contractType={ContractType.privacy} />
            }
          ></Route>
          <Route
            path="cerez-politikasi"
            element={
              <Contract key="cerezza" contractType={ContractType.cookie} />
            }
          ></Route>
        </Route>
        <Route path="/haberler">
          <Route index={true} element={<Newscast />}></Route>
          <Route path=":id" element={<NewsPage />} />
        </Route>
        <Route path="/500" element={<Error500 />} />
        <Route
          path="/katilimcilar"
          element={
            <UsersPage title="Katılımcılar" key={"others"} self={false} />
          }
        />
        <Route
          path="/guvercinlerim"
          element={
            <AuthProtector isRequired={true}>
              <UsersPage title="Güvercinlerim" key={"self"} self={true} />
            </AuthProtector>
          }
        />
        <Route
          path="/acik-arttirmalar"
          element={
            <AuctionsLayout
              key="acik-arttirma"
              auctionMode={AuctionMode.auction}
              title="Açık Arttırmalar"
              canFilter={true}
            />
          }
        />
        <Route path="/acik-arttirmalar/:id" element={<AuctionPage />} />
        <Route path="/grup-ihaleler/:id" element={<GroupAuctionPage />} />
        <Route
          path="/arsiv/:id"
          element={<GroupAuctionPage archival={true} />}
        />
        {/* TODO */}
        <Route
          path="/grup-ihaleler"
          element={
            <AuctionsLayout
              key="grup-ihale"
              auctionMode={AuctionMode.groupAuction}
              title="Grup İhaleler"
              canFilter={true}
            />
          }
        />
        <Route
          path="/arsiv"
          element={
            <AuctionsLayout
              key="arsiv"
              auctionMode={AuctionMode.archive}
              title="Arşiv"
              canFilter={false}
            />
          }
        />
        <Route
          path="/hesabim"
          element={
            <AuthProtector isRequired={true}>
              <MyAccountPageLayout />
            </AuthProtector>
          }
        >
          <Route
            index={true}
            element={
              <MyAuctions status={AuctionStatus.allAuctions}></MyAuctions>
            }
          ></Route>
          <Route
            path="teklif-verilen-acik-arttirmalarim"
            element={
              <MyAuctions status={AuctionStatus.allAuctions}></MyAuctions>
            }
          ></Route>
          <Route
            path="devam-eden-acik-arttirmalarim"
            element={
              <MyAuctions status={AuctionStatus.ongoingAuctions}></MyAuctions>
            }
          ></Route>
          <Route
            path="kazanan-arttirmalarim"
            element={
              <MyAuctions status={AuctionStatus.winnerAuctions}></MyAuctions>
            }
          ></Route>
          <Route
            path="kaybeden-arttirmalarim"
            element={
              <MyAuctions status={AuctionStatus.loserAuctions}></MyAuctions>
            }
          ></Route>
          <Route
            path="acik-arttirma-ayarlari"
            element={<AuctionSettings></AuctionSettings>}
          ></Route>
          <Route
            path="bilgilerim"
            element={<MyInformationsPage></MyInformationsPage>}
          ></Route>
          <Route
            path="sifre-yenile"
            element={<RefreshPasswordPage></RefreshPasswordPage>}
          ></Route>
        </Route>
      </Routes>
      <VerificationPage />
      <AreYouSureOverlay />
      <LoadingOverlay />
      <FeedBackOverlay />
      <PhotoOverlay></PhotoOverlay>
      <Footer></Footer>
    </div>
  );
}
