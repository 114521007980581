import { Component } from "react";
import Icons from "../../Icons/svg-icon";
import { TableFields, UserData } from "./Table";
import "./Table.css";

interface Props {
  feature: TableFields;
  item: UserData;
}

interface State {
  isOpen: boolean;
}

export default class TableElement extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  getOthers(userData: UserData) {
    if (!this.state.isOpen) return;
    return (
      <div className="sub-div">
        <tr
          className="sub-tr"
          style={{
            display: this.props.feature == TableFields.city ? "none" : "flex",
          }}
        >
          Şehir:<strong className="strong">{userData.city}</strong>
        </tr>
        <tr
          className="sub-tr"
          style={{
            display:
              this.props.feature == TableFields.country ? "none" : "flex",
          }}
        >
          Ülke:<strong className="strong">{userData.country}</strong>
        </tr>
        <tr
          className="sub-tr"
          style={{
            display:
              this.props.feature == TableFields.imprintNumber ? "none" : "flex",
          }}
        >
          Künye No:<strong className="strong">{userData.imprintNumber}</strong>
        </tr>
        <tr
          className="sub-tr"
          style={{
            display:
              this.props.feature == TableFields.nameSurname ? "none" : "flex",
          }}
        >
          İsim Soyisim:
          <strong className="strong">{userData.nameSurname}</strong>
        </tr>
        <tr
          className="sub-tr"
          style={{
            display:
              this.props.feature == TableFields.serialNumber ? "none" : "flex",
          }}
        >
          Seri No:<strong className="strong">{userData.serialNumber}</strong>
        </tr>
        <tr
          className="sub-tr"
          style={{
            display: this.props.feature == TableFields.state ? "none" : "flex",
          }}
        >
          Durum:
          <strong className="strong">
            {userData.state ? "aktif" : "pasif"}
          </strong>
        </tr>
      </div>
    );
  }

  getFeatureName(_feature: TableFields) {
    switch (_feature) {
      case TableFields.city:
        return "Şehir";
      case TableFields.country:
        return "Ülke";
      case TableFields.imprintNumber:
        return "Künye No";
      case TableFields.nameSurname:
        return "İsim Soyisim";
      case TableFields.serialNumber:
        return "Sıra No";
      case TableFields.state:
        return "Durum";
    }
  }

  getFeature(userData: UserData) {
    switch (this.props.feature) {
      case TableFields.city:
        return userData.city;
      case TableFields.country:
        return userData.country;
      case TableFields.imprintNumber:
        return userData.imprintNumber;
      case TableFields.nameSurname:
        return userData.nameSurname;
      case TableFields.serialNumber:
        return userData.serialNumber;
      case TableFields.state:
        return userData.state ? "aktif" : "pasif";
    }
  }

  render() {
    return (
      <div className="special-div">
        <tr
          className="special"
          onClick={() =>
            this.setState({
              isOpen: !this.state.isOpen,
            })
          }
        >
          <td style={{ paddingLeft: "15px" }}>
            {this.getFeatureName(this.props.feature)}:
            <strong
              className="strong"
              style={{
                wordBreak: "break-all",
              }}
            >
              {this.getFeature(this.props.item)}
            </strong>
            <div>
              <Icons.Expand
                size={20}
                fill={"#17161A"}
                style={{
                  transform: this.state.isOpen
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                  transitionDuration: "150ms",
                }}
              ></Icons.Expand>
            </div>
          </td>
        </tr>
        {this.getOthers(this.props.item)}
      </div>
    );
  }
}
